import { useEffect, useState } from "react";
import { useClubs } from "src/features/club/ClubProvider";
import {
  format,
  sub,
  subWeeks,
  startOfWeek,
  endOfWeek,
  isSameWeek,
  differenceInWeeks,
} from "date-fns";
import { query, orderBy, getCountFromServer, where } from "firebase/firestore";

import ReportsBarChart from "./ReportsBarChart";
import { getClubBookingsCollection } from "src/features/club/collections";
import { getUserPermissionsCollectionGroup } from "src/features/user/collections";
import {
  getAircraftCollection,
  getAircraftSquawksCollection,
} from "src/features/aircraft/collections";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";

function ClubAnalytics() {
  const { selectedClubId, selectedLocationId } = useClubs();

  const [weeklyBillableHours, setWeeklyBillableHours] = useState([]);
  const [weeklyBillableHoursLabels, setWeeklyBillableHoursLabels] = useState(
    []
  );
  const [weeklyBillableHoursDifference, setWeeklyBillableHoursDifference] =
    useState(0);

  const [numberOfBookings, setNumberOfBookings] = useState(0);
  const [numberOfBookingsPercentage, setNumberOfBookingsPercentage] =
    useState(0);
  const [numberOfCancellations, setNumberOfCancellations] = useState(0);
  const [numberOfCancellationsPercentage, setNumberOfCancellationsPercentage] =
    useState(0);
  const [numberOfMembers, setNumberOfMembers] = useState(0);
  const [numberOfMembersPercentage, setNumberOfMembersPercentage] = useState(0);
  const [numberOfSquawks, setNumberOfSquawks] = useState(0);
  const [numberOfSquawksPrevious, setNumberOfSquawksPrevious] = useState(0);
  const [numberOfSquawksPercentage, setNumberOfSquawksPercentage] = useState(0);

  const chart = {
    labels: weeklyBillableHoursLabels,
    datasets: {
      label: "hours",
      data: weeklyBillableHours,
    },
  };
  const items = [
    {
      icon: {
        color: "info",
        component: "event",
      },
      label: "Bookings",
      progress: {
        content: numberOfBookings.toString(),
        percentage: numberOfBookingsPercentage,
      },
    },
    {
      icon: {
        color: "error",
        component: "close",
      },
      label: "Cancellations",
      progress: {
        content: numberOfCancellations.toString(),
        percentage: numberOfCancellationsPercentage,
      },
    },
    {
      icon: {
        color: "success",
        component: "person",
      },
      label: "Members",
      progress: {
        content: numberOfMembers.toString(),
        percentage: numberOfMembersPercentage,
      },
    },
    {
      icon: {
        color: "warning",
        component: "build",
      },
      label: "Squawks",
      progress: {
        content: numberOfSquawks.toString(),
        percentage: numberOfSquawksPercentage,
      },
    },
  ];

  const locationBookingsCollectionRef = query(
    getClubBookingsCollection(selectedClubId, selectedLocationId),
    where("cancelled", "==", false),
    where("extendedProps.type.value", "in", [
      "reservation",
      "checkout",
      "checkride",
      "introFlight",
    ]),
    where("completedAt", ">=", startOfWeek(sub(new Date(), { weeks: 12 }))),
    orderBy("completedAt", "asc")
  );
  const { data: allBookings, isDataLoaded: bookingsLoaded } =
    useRealtimeCollectionData(locationBookingsCollectionRef, true);

  const locationAircraftCollectionRef = query(
    getAircraftCollection(selectedClubId),
    where("locations", "array-contains", selectedLocationId),
    where("disabled", "==", false),
    where("deleted", "==", false)
  );
  const { data: allAircraft, isDataLoaded: aircraftLoaded } =
    useRealtimeCollectionData(locationAircraftCollectionRef, true);

  let squawksCount = 0;
  let squawksCountPrevious = 0;

  const getSquawksForAircraft = async (aircraft) => {
    const recentlyAddedSquawksRef = query(
      getAircraftSquawksCollection(selectedClubId, aircraft.id),
      where("resolved", "==", false),
      where("date", ">=", startOfWeek(new Date()))
    );
    const recentlyAddedSquawksPreviousRef = query(
      getAircraftSquawksCollection(selectedClubId, aircraft.id),
      where("resolved", "==", false),
      where("date", ">=", startOfWeek(subWeeks(new Date(), 1)))
    );

    const recentlyAddedSquawksCount = await getCountFromServer(
      recentlyAddedSquawksRef
    );
    squawksCount += recentlyAddedSquawksCount.data().count;
    setNumberOfSquawks(squawksCount);

    const recentlyAddedSquawksCountPrevious = await getCountFromServer(
      recentlyAddedSquawksPreviousRef
    );
    squawksCountPrevious +=
      recentlyAddedSquawksCountPrevious.data().count -
      recentlyAddedSquawksCount.data().count;
    setNumberOfSquawksPrevious(squawksCountPrevious);
  };

  useEffect(() => {
    if (allAircraft && aircraftLoaded) {
      allAircraft.forEach((aircraft) => {
        getSquawksForAircraft(aircraft);
      });
    }
  }, [allAircraft, aircraftLoaded]);

  useEffect(() => {
    if (numberOfSquawks && numberOfSquawksPrevious) {
      const percentage = (numberOfSquawks / numberOfSquawksPrevious) * 100;
      setNumberOfSquawksPercentage(Math.min(percentage, 100));
    }
  }, [numberOfSquawks, numberOfSquawksPrevious]);

  const locationCancelledBookingsCollectionRef = query(
    getClubBookingsCollection(selectedClubId, selectedLocationId),
    where("cancelled", "==", true),
    where("extendedProps.type.value", "in", [
      "reservation",
      "checkout",
      "checkride",
      "introFlight",
    ]),
    where("cancelledAt", ">=", startOfWeek(new Date()))
  );
  const locationCancelledBookingsPreviousCollectionRef = query(
    getClubBookingsCollection(selectedClubId, selectedLocationId),
    where("cancelled", "==", true),
    where("extendedProps.type.value", "in", [
      "reservation",
      "checkout",
      "checkride",
      "introFlight",
    ]),
    where("cancelledAt", ">=", startOfWeek(subWeeks(new Date(), 1)))
  );
  const getCancelledBookingCount = async () => {
    const cancelledBookingCount = await getCountFromServer(
      locationCancelledBookingsCollectionRef
    );
    setNumberOfCancellations(cancelledBookingCount.data().count);

    const cancelledBookingCountPrevious = await getCountFromServer(
      locationCancelledBookingsPreviousCollectionRef
    );
    const previousCancelledBookingCount =
      cancelledBookingCountPrevious.data().count -
      cancelledBookingCount.data().count;
    setNumberOfCancellationsPercentage(
      Math.min(
        (cancelledBookingCount.data().count / previousCancelledBookingCount) *
          100,
        100
      )
    );
  };

  const recentlyAddedMemberCollectionRef = query(
    getUserPermissionsCollectionGroup(),
    where("locationId", "==", selectedLocationId),
    where("addedAt", ">=", startOfWeek(new Date()))
  );
  const recentlyAddedMemberPreviousCollectionRef = query(
    getUserPermissionsCollectionGroup(),
    where("locationId", "==", selectedLocationId),
    where("addedAt", ">=", startOfWeek(subWeeks(new Date(), 1)))
  );
  const getRecentlyAddedMemberCount = async () => {
    const recentlyAddedMemberCount = await getCountFromServer(
      recentlyAddedMemberCollectionRef
    );
    setNumberOfMembers(recentlyAddedMemberCount.data().count);

    const recentlyAddedMemberCountPrevious = await getCountFromServer(
      recentlyAddedMemberPreviousCollectionRef
    );
    const previousAddedMemberCount =
      recentlyAddedMemberCountPrevious.data().count -
      recentlyAddedMemberCount.data().count;
    setNumberOfMembersPercentage(
      Math.min(
        (recentlyAddedMemberCount.data().count / previousAddedMemberCount) *
          100,
        100
      )
    );
  };

  useEffect(() => {
    getCancelledBookingCount();
    getRecentlyAddedMemberCount();
  }, []);

  useEffect(() => {
    const labels = [];
    let i = 0;
    while (i < 12) {
      labels.push(
        `${format(startOfWeek(subWeeks(new Date(), i)), "MMM d")}-${format(
          endOfWeek(subWeeks(new Date(), i)),
          "d"
        )}`
      );
      i += 1;
    }
    setWeeklyBillableHoursLabels(labels.reverse());
  }, []);

  useEffect(() => {
    if (allBookings && bookingsLoaded) {
      const hoursByWeek = [];
      let i = 0;
      while (i < 12) {
        hoursByWeek.push(0);
        i += 1;
      }

      let bookingCount = 0;
      let bookingCountLastWeek = 0;
      allBookings.map((booking) => {
        if (!booking?.flightData?.totalHobbsHours) return;
        const bookingCheckInDate = booking.completedAt.toDate();
        const bookingStartDate = booking.start.toDate();
        hoursByWeek[
          Math.round(
            differenceInWeeks(
              startOfWeek(bookingCheckInDate),
              startOfWeek(new Date())
            )
          ) * -1
        ] += booking?.flightData?.totalHobbsHours || 0;

        if (isSameWeek(bookingStartDate, new Date())) {
          bookingCount += 1;
        }
        if (isSameWeek(bookingStartDate, subWeeks(new Date(), 1))) {
          bookingCountLastWeek += 1;
        }
      });
      setNumberOfBookings(bookingCount);
      setNumberOfBookingsPercentage(
        Math.min((bookingCount / bookingCountLastWeek) * 100, 100)
      );
      setWeeklyBillableHours(hoursByWeek.reverse());
      const growth =
        hoursByWeek && hoursByWeek.length > 1
          ? ((hoursByWeek[hoursByWeek.length - 1] -
              hoursByWeek[hoursByWeek.length - 2]) /
              hoursByWeek[hoursByWeek.length - 2]) *
            100
          : 0;
      setWeeklyBillableHoursDifference(growth === Infinity ? 999 : growth);
    }
  }, [allBookings, bookingsLoaded]);

  return (
    <ReportsBarChart
      title="Billable Aircraft Hours (Last 12 Weeks)"
      description={
        <>
          (
          <strong>
            {weeklyBillableHoursDifference >= 0 ? "+" : ""}
            {weeklyBillableHoursDifference
              ? Math.round(weeklyBillableHoursDifference)
              : 0}
            %
          </strong>
          ) than last week
        </>
      }
      chart={chart}
      items={items}
      color="info"
    />
  );
}

export default ClubAnalytics;
