// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-flatpickr components
// import Flatpickr from 'react-flatpickr';
import DatePicker from "react-datepicker";

// react-flatpickr styles
// import 'flatpickr/dist/flatpickr.css';
import "react-datepicker/dist/react-datepicker.css";

// Soft UI Dashboard PRO React components
import SoftInput from "src/components/SoftInput";
import { forwardRef } from "react";

import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import SoftDatePickerContainerRoot from "./SoftDatePickerContainerRoot";

const CustomInput = forwardRef(({ input, value, onClick, error }, ref) => (
  <SoftInput
    icon={{
      component: <CalendarMonthOutlinedIcon />,
      direction: "left",
    }}
    {...input}
    type="text"
    onFocus={onClick}
    value={value}
    inputRef={ref}
    error={error}
  />
));
function SoftDatePicker({ error, ...rest }) {
  return (
    <SoftDatePickerContainerRoot>
      <DatePicker {...rest} customInput={<CustomInput error={error} />} />
    </SoftDatePickerContainerRoot>
  );
}

// Typechecking props for the SoftDatePicker
SoftDatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
  value: PropTypes.any,
  onClick: PropTypes.func,
  error: PropTypes.bool,
};

CustomInput.propTypes = {
  value: PropTypes.any,
  onClick: PropTypes.func,
  input: PropTypes.any,
  error: PropTypes.bool,
};

export default SoftDatePicker;
