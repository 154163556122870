// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

function NameCell({ children, checked, onChange }) {
  return (
    <SoftBox display="flex" alignItems="center">
      <Checkbox checked={checked} onChange={onChange} />
      <SoftBox ml={1}>
        <SoftTypography variant="caption" fontWeight="medium" color="text">
          {children}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

// Setting default value for the props of IdCell
NameCell.defaultProps = {
  checked: false,
};

// Typechecking props for the IdCell
NameCell.propTypes = {
  children: PropTypes.node.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default NameCell;
