import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import { InfoOutlined } from "@mui/icons-material";
import { useState } from "react";
import { CancelOutlined } from "@mui/icons-material";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";
import parse from "html-react-parser";

import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

const StageInfo = ({ stage }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleInfoClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleInfoClick} size="small" sx={{ p: "0px" }}>
        <InfoOutlined fontSize="small" color="text" />
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Card
          sx={{
            width: "260px",
            mt: "-0.7rem",
            mr: "-0.7rem",
            backgroundColor: "grey.200",
          }}
        >
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={2}
          >
            <SoftTypography variant="h6">Stage Description</SoftTypography>
            <IconButton
              onClick={handleClosePopover}
              aria-label="Close"
              sx={{ p: 0 }}
            >
              <CancelOutlined color="text" fontSize="small" />
            </IconButton>
          </SoftBox>
          {stage.id && (
            <SoftBox px={2}>
              <SoftTypography
                variant="h6"
                fontSize="small"
                textTransform="uppercase"
              >
                Objective
              </SoftTypography>
              <SoftTypography component="div" fontSize="small" mb={2}>
                {parse(stage.objective)}
              </SoftTypography>
              <SoftTypography
                variant="h6"
                fontSize="small"
                textTransform="uppercase"
              >
                Completion Standards
              </SoftTypography>
              <SoftTypography component="div" fontSize="small" mb={2}>
                {parse(stage.completionStandards)}
              </SoftTypography>
            </SoftBox>
          )}
        </Card>
      </Popover>
    </>
  );
};

StageInfo.propTypes = {
  stage: PropTypes.object.isRequired,
};

export default StageInfo;
