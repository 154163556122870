export const standardDocumentTypes = [
  {
    id: "photoId",
    name: "Photo ID",
  },
  {
    id: "certificate",
    name: "Pilot Certificate (FAA)",
  },
  {
    id: "medical",
    name: "Medical Certificate / BasicMed (FAA)",
  },
  {
    id: "tsaCitizenshipVerification",
    name: "TSA Citizenship Verification",
  },
];
