import { useState, useEffect } from "react";

// react-router components
import { useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftSelect from "src/components/SoftSelect";
import SoftAlert from "src/components/SoftAlert";
import SoftBadge from "src/components/SoftBadge";

// Soft UI Dashboard PRO React example components
import Breadcrumbs from "src/containers/Breadcrumbs";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "src/containers/DashboardNavbar/styles";

// Soft UI Dashboard PRO React context
import { useSoftUIController, setMiniSidenav } from "src/context";

// Images
import { useUser } from "src/features/user/UserProvider";
import NotificationTray from "src/containers/NotificationTray";
import { useClubs } from "src/features/club/ClubProvider";
import { useNotifications } from "src/features/notifications/NotificationsProvider";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";

function DashboardNavbar({
  absolute = false,
  light = false,
  isMini = false,
  pageTitle,
}) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, fixedNavbar } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const navigate = useNavigate();
  const { unreadNotificationsCount } = useNotifications();

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobileSize = width < 768;

  const { signOut } = useUser();
  const {
    userClubs,
    selectedClubId,
    setSelectedClubId,
    selectedLocationId,
    selectedClub,
    setSelectedLocationId,
    selectedLocation,
    clubLocations,
  } = useClubs();

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  const handleClubSelect = (club) => {
    setSelectedClubId(club.value);
  };

  const handleLocationSelect = (location) => {
    setSelectedLocationId(location.value);
  };

  return (
    <>
      <AppBar
        position={absolute ? "absolute" : navbarType}
        color="inherit"
        sx={(theme) =>
          navbar(theme, {
            absolute,
            light,
          })
        }
      >
        <Toolbar sx={(theme) => navbarContainer(theme)}>
          <SoftBox
            color="inherit"
            mb={{
              xs: 1,
              md: 0,
            }}
            sx={(theme) =>
              navbarRow(theme, {
                isMini,
              })
            }
          >
            <IconButton
              size="medium"
              color="inherit"
              sx={navbarMobileMenu}
              onClick={handleMiniSidenav}
            >
              <Icon className={light ? "text-white" : "text-dark"}>
                {miniSidenav ? "menu" : "menu_open"}
              </Icon>
            </IconButton>
            <Icon
              fontSize="medium"
              sx={navbarDesktopMenu}
              onClick={handleMiniSidenav}
            >
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
            <Breadcrumbs
              icon="home"
              title={pageTitle ?? route[route.length - 1]}
              route={route}
              light={light}
            />
          </SoftBox>
          {isMini ? null : (
            <SoftBox
              sx={(theme) =>
                navbarRow(theme, {
                  isMini,
                })
              }
            >
              {!isMobileSize && userClubs && userClubs.length > 1 && (
                <SoftBox pr={2} className="ClubWrapper">
                  <SoftBox
                    className="SelectLabel"
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Club
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    className="ClubSelect"
                    onChange={handleClubSelect}
                    defaultValue={{
                      value: selectedClubId,
                      label: selectedClub?.name || "Back Office",
                    }}
                    options={userClubs.map((club) => ({
                      value: club.id,
                      label: club.data().name,
                    }))}
                  />
                </SoftBox>
              )}
              {!isMobileSize && clubLocations && clubLocations.length > 1 && (
                <SoftBox pr={2} className="LocationWrapper">
                  <SoftBox
                    className="SelectLabel"
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Location
                    </SoftTypography>
                  </SoftBox>
                  <SoftSelect
                    className="ClubSelect"
                    onChange={handleLocationSelect}
                    value={{
                      value: selectedLocationId,
                      label: selectedLocation?.icao,
                    }}
                    options={clubLocations.map((location) => ({
                      value: location.id,
                      label: location.icao,
                    }))}
                  />
                </SoftBox>
              )}
              <SoftBox color={light ? "white" : "inherit"}>
                <IconButton
                  sx={navbarIconButton}
                  size="small"
                  onClick={async () => {
                    await signOut();
                  }}
                >
                  <Icon
                    sx={({ palette: { dark, white } }) => ({
                      color: light ? white.main : dark.main,
                    })}
                  >
                    logout
                  </Icon>
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    color={light ? "white" : "dark"}
                  >
                    Sign Out
                  </SoftTypography>
                </IconButton>
                {/* <IconButton
                  size="small"
                  color="inherit"
                  sx={navbarIconButton}
                  onClick={() => navigate('/settings/account-settings')}
                >
                  <Icon>settings</Icon>
                </IconButton> */}
                <IconButton
                  size="small"
                  color="inherit"
                  sx={navbarIconButton}
                  aria-controls="notification-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleOpenMenu}
                >
                  <SoftBadge
                    badgeContent={unreadNotificationsCount}
                    color="error"
                    container
                    sx={{
                      position: "absolute",
                      top: "-7px",
                      left: "-5px",
                      "& .MuiBadge-badge": { padding: "4px 2px" },
                    }}
                  />
                  <Icon
                    className={
                      light
                        ? "text-white notification-icon"
                        : "text-dark notification-icon"
                    }
                  >
                    notifications
                  </Icon>
                </IconButton>
                <NotificationTray
                  handleCloseMenu={handleCloseMenu}
                  openMenu={openMenu}
                />
              </SoftBox>
            </SoftBox>
          )}
        </Toolbar>
      </AppBar>
      {route &&
        route[0] !== "club-setup" &&
        selectedClub &&
        selectedClub.name !== "Back Office" &&
        selectedClub.setupComplete !== true && (
          <WithPermissions
            permissions={[systemPermissions.VIEW_BILLING_SETTINGS]}
          >
            <SoftAlert
              color="error"
              mt={2}
              onClick={() => navigate("/club-setup")}
              sx={{
                cursor: "pointer",
                zIndex: 1000,
              }}
            >
              Mayday! PreFlight checklist failed... You must complete the setup
              of your club to start receiving payments. Click here to finish!
            </SoftAlert>
          </WithPermissions>
        )}
    </>
  );
}

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  pageTitle: PropTypes.string,
};

export default DashboardNavbar;
