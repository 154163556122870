import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-select components
import Select, { GroupBase, Props, StylesConfig  } from "react-select";

// Soft UI Dashboard PRO React base styles
import colors from "src/assets/theme/base/colors";

// Custom styles for SoftSelect
import styles from "src/components/SoftSelect/styles";

type softSelectType = {
  error?: boolean;
  success?: boolean;
  borderless?: boolean;
  ref?: any;
  size?: "small" | "medium" | "large";
  options: Array<{ label: string; value: string | number }>;
  label?: string;
  customStyles?: StylesConfig 
};

// function CustomSelect<
// Option,
// IsMulti extends boolean = false,
// Group extends GroupBase<Option> = GroupBase<Option>
// >(props: Props<Option, IsMulti, Group>) {
// return (
//   <Select {...props} theme={(theme) => ({ ...theme, borderRadius: 0 })} />
// );
// }

const SoftSelect = forwardRef<
  HTMLDivElement,
  Props<any, boolean, GroupBase<any>> & softSelectType
>(({ size = "medium", borderless, error, customStyles = {}, ...rest }, ref) => {
  const { light } = colors;

  return (
    <Select
      {...rest}
      ref={ref as any}
      // menuPortalTarget={document.body}
      styles={styles(size, borderless, error, customStyles) as any}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary25: light.main,
          primary: light.main,
        },
      })}
    />
  );
});

// Setting default values for the props of SoftSelect
SoftSelect.defaultProps = {
  size: "medium",
  error: false,
  success: false,
};

// Typechecking props for the SoftSelect
const sizePropType = PropTypes.oneOf([
  "small",
  "medium",
  "large",
]) as PropTypes.Validator<"small" | "medium" | "large">;
const booleanPropType = PropTypes.bool as PropTypes.Validator<boolean>;
SoftSelect.propTypes = {
  size: sizePropType,
  error: booleanPropType,
  success: booleanPropType,
};

export default SoftSelect;
