// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import { useEffect, useState, createRef } from "react";

// @fullcalendar components
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";

// Custom styles for Calendar
import CalendarRoot from "../CalendarRoot";

import "react-datepicker/dist/react-datepicker.css";
import {
  getClassName,
  prepareEventForCalendar,
} from "src/components/Calendar/uiHelper";

function Calendar({ events, listView, initialView = "dayGridMonth", ...rest }) {
  const calendarRef = createRef();
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobileSize = width <= 600;

  const preparedEvents = events
    ? events.flatMap((event) => prepareEventForCalendar(event))
    : [];

  const plugins = [dayGridPlugin, timeGridPlugin, interactionPlugin];
  let headerRight = "dayGridMonth,timeGridWeek,timeGridDay";
  if (listView) {
    plugins.push(listPlugin);
    headerRight = headerRight.concat(",listWeek");
  }

  return (
    <SoftBox
      sx={{
        height: "100%",
      }}
    >
      <CalendarRoot p={2}>
        {isMobileSize ? (
          <SoftBox
            sx={{
              "& .fc .fc-toolbar.fc-header-toolbar": {
                flexDirection: "column",
                marginBottom: 2,
                ".fc-toolbar-chunk:nth-child(2)": {
                  marginBottom: 2,
                },
              },
              "& .fc .fc-resource .fc-icon": {
                display: "none",
              },
            }}
          >
            <FullCalendar
              ref={calendarRef}
              plugins={plugins}
              headerToolbar={{
                left: "",
                center: "prev,title,next",
                right: `today ${headerRight}`,
              }}
              longPressDelay={250}
              titleFormat={{
                year: "numeric",
                month: "short",
                day: "numeric",
                weekday: "short",
              }}
              height="auto"
              schedulerLicenseKey={
                import.meta.env.VITE_FULL_CALENDAR_LICENSE_KEY
              }
              initialView={initialView}
              events={preparedEvents}
              nowIndicator
              eventClassNames={(ev) => {
                return [
                  getClassName(
                    {
                      ...ev.event.extendedProps,
                      className: ev.event.classNames[0].replace("event-", ""),
                    },
                    ev.view.type === "listWeek" ? "list" : undefined
                  ),
                ];
              }}
              {...rest}
            />
          </SoftBox>
        ) : (
          <FullCalendar
            ref={calendarRef}
            plugins={plugins}
            headerToolbar={{
              left: "today",
              center: "prev,title,next",
              right: headerRight,
            }}
            titleFormat={{
              year: "numeric",
              month: "short",
              day: "numeric",
              weekday: "short",
            }}
            height="auto"
            schedulerLicenseKey={import.meta.env.VITE_FULL_CALENDAR_LICENSE_KEY}
            initialView={initialView}
            events={preparedEvents}
            nowIndicator
            eventClassNames={(ev) => {
              return [
                getClassName(
                  {
                    ...ev.event.extendedProps,
                    className: ev.event.classNames[0].replace("event-", ""),
                  },
                  ev.view.type === "listWeek" ? "list" : undefined
                ),
              ];
            }}
            {...rest}
          />
        )}
      </CalendarRoot>
      {/*     <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SoftBox>
          <BookingModal
            selection={selection}
            handleClose={handleClose}
            resources={resourceData}
            pilots={pilots}
          />
        </SoftBox>
      </Modal>
      <Modal
        open={showBookingErrorModal}
        onClose={handleCloseBookingErrorModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SoftBox>
          <BookingErrorModal
            booking={errorBooking}
            permissionErrors={permissionErrors}
            handleClose={handleCloseBookingErrorModal}
          />
        </SoftBox>
      </Modal> */}
    </SoftBox>
  );
}

// Typechecking props for the Calendar
Calendar.propTypes = {
  header: PropTypes.shape({
    title: PropTypes.string,
    date: PropTypes.string,
  }),
  resourceData: PropTypes.array,
  pilots: PropTypes.array,
};

export default Calendar;
