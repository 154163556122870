import { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useClubs } from "src/features/club/ClubProvider";

// @mui material components
import Card from "@mui/material/Card";
import { DescriptionOutlined, PeopleOutlined } from "@mui/icons-material";
import Modal from "@mui/material/Modal";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import LessonTable from "./components/LessonTable";
import SoftSlider from "src/components/SoftSlider";
import { AddStageModal } from "src/modals/AddStageModal";

import useRealtimeDocumentData from "src/features/firebase/firestore/useRealtimeDocumentData";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import {
  getClubCoursesCollection,
  getCourseStagesCollection,
  getCourseLessonsCollection,
  getLessonTasksCollection,
} from "src/features/lms/collections";
import { getUserCoursesCollectionGroup } from "src/features/user/collections";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import Swal from "sweetalert2";

// Data
import { doc, query, orderBy, getDocs, where } from "firebase/firestore";
import IconAvatar from "src/components/IconAvatar";
import {
  CourseHeader,
  StageSliderCard,
  StageHeader,
  StageFooter,
} from "../../components";
import StageMenu from "./components/StageMenu";
import { CircularProgress } from "@mui/material";

function CourseDetails() {
  const navigate = useNavigate();
  const { selectedClubId } = useClubs();
  const { courseId } = useParams();
  const { deleteData } = entityCrudUtils();
  const [isLoaded, setIsLoaded] = useState(false);
  const [courseStructure, setCourseStructure] = useState([]);
  const [selectedStageIndex, setSelectedStageIndex] = useState(0);
  const [selectedStage, setSelectedStage] = useState(0);
  const [numEnrolled, setNumEnrolled] = useState(0);
  const [openAddStageModal, setOpenAddStageModal] = useState(false);

  const showAddStageModal = (stage = null) => {
    setSelectedStage(stage);
    setOpenAddStageModal(true);
  };

  const closeAddStageModal = (index) => {
    index && setSelectedStageIndex(index);
    setOpenAddStageModal(false);
  };

  const { data: course } = useRealtimeDocumentData(
    doc(getClubCoursesCollection(selectedClubId), courseId)
  );

  const { data: allStages } = useRealtimeCollectionData(
    query(
      getCourseStagesCollection(selectedClubId, courseId),
      orderBy("index", "asc")
    ),
    false
  );

  const getNumEnrolledInCourse = async () => {
    const allEnrolled = await getDocs(query(getUserCoursesCollectionGroup()));
    const enrolledCourseIds = allEnrolled.docs.map(
      (doc) => doc.data().courseId
    );
    setNumEnrolled(enrolledCourseIds.filter((c) => c === courseId).length);
  };

  const totalLessons = useMemo(() => {
    if (courseStructure.length) {
      return courseStructure.reduce((a, b) => a + b.lessons.length, 0);
    }

    return 0;
  }, [courseStructure]);

  const deleteStage = (stageId) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You will not be able to recover this stage!",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          deleteData({
            entity: getCourseStagesCollection(selectedClubId, courseId),
            pathSegmentsArr: [stageId],
          });
        }
      });
  };

  const getCourseStructure = async () => {
    setIsLoaded(false);
    const fullCourse = await Promise.all(
      allStages.map(async (stage) => {
        const lessons = await getDocs(
          query(
            getCourseLessonsCollection(selectedClubId, courseId, stage.id),
            where("deleted", "==", false),
            orderBy("index", "asc")
          )
        );
        const lessonsMapped = await Promise.all(
          lessons.docs.map(async (lesson) => {
            const tasks = await getDocs(
              query(
                getLessonTasksCollection(
                  selectedClubId,
                  courseId,
                  stage.id,
                  lesson.id
                ),
                where("deleted", "==", false),
                orderBy("index", "asc")
              )
            );
            return {
              ...lesson.data(),
              id: lesson.id,
              tasks: tasks.docs.map((task) => ({
                ...task.data(),
                id: task.id,
              })),
            };
          })
        );
        const stageHours =
          Math.round(
            lessonsMapped.reduce(
              (a, b) => a + b.flightTime + b.simTime + b.groundTime,
              0
            ) * 10
          ) / 10;
        return {
          ...stage,
          stageHours,
          lessons: lessonsMapped,
        };
      })
    );
    setCourseStructure(fullCourse);
    setIsLoaded(true);
  };

  useEffect(() => {
    if (!allStages) return;
    getCourseStructure();
    getNumEnrolledInCourse();
  }, [allStages]);

  return (
    <DashboardLayout>
      <DashboardNavbar pageTitle={course?.title} />
      <SoftBox py={3}>
        <Card
          sx={{
            overflowX: "visible",
            overflowY: "scroll",
          }}
        >
          {course && (
            <SoftBox p={3}>
              <CourseHeader course={course} onBackClick={() => navigate(-1)}>
                <SoftBox display="flex" alignItems="center" gap="6px">
                  <IconAvatar>
                    <DescriptionOutlined
                      fontSize="extra-small"
                      color="secondary"
                    />
                  </IconAvatar>
                  <SoftTypography variant="h5">
                    {totalLessons} {totalLessons === 1 ? "Lesson" : "Lessons"}
                  </SoftTypography>
                </SoftBox>
                <SoftBox display="flex" alignItems="center" gap="6px">
                  <IconAvatar>
                    <PeopleOutlined fontSize="extra-small" color="secondary" />
                  </IconAvatar>
                  <SoftTypography variant="h5">
                    {numEnrolled} Enrolled
                  </SoftTypography>
                </SoftBox>
              </CourseHeader>

              <SoftSlider>
                {courseStructure?.map((stage) => (
                  <StageSliderCard
                    backgroundColor={
                      selectedStageIndex === stage.index
                        ? "primary.background"
                        : "grey.100"
                    }
                    key={stage.id}
                    onClick={() => setSelectedStageIndex(stage.index)}
                  >
                    <StageHeader index={stage.index}>
                      <StageMenu
                        onEdit={() => showAddStageModal(stage)}
                        onDelete={() => deleteStage(stage.id)}
                      />
                    </StageHeader>
                    <StageFooter stage={stage} />
                  </StageSliderCard>
                ))}
                <StageSliderCard onAddStage={showAddStageModal} />
              </SoftSlider>
              <SoftBox mt={4} mb={2}>
                {isLoaded ? (
                  courseStructure[selectedStageIndex]?.id ? (
                    <LessonTable
                      stage={courseStructure[selectedStageIndex]}
                      refreshData={getCourseStructure}
                    />
                  ) : (
                    <SoftBox sx={{ p: 2, mt: 3 }}>
                      <SoftTypography
                        variant="h4"
                        sx={{ mb: 1 }}
                        color="primary"
                      >
                        This course is looking a bit empty...
                      </SoftTypography>
                      <SoftTypography fontSize="medium" fontWeight="medium">
                        Try adding your first stage!
                      </SoftTypography>
                    </SoftBox>
                  )
                ) : (
                  <SoftBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="8rem"
                    width="100%"
                  >
                    <CircularProgress />
                  </SoftBox>
                )}
              </SoftBox>

              <Modal
                open={openAddStageModal}
                onClose={() => closeAddStageModal()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                  backdropFilter: "blur(2px)",
                }}
              >
                <SoftBox>
                  <AddStageModal
                    stageCount={courseStructure?.length || 0}
                    courseId={courseId}
                    handleClose={closeAddStageModal}
                    stageData={selectedStage}
                  />
                </SoftBox>
              </Modal>
            </SoftBox>
          )}
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default CourseDetails;
