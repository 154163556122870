import SoftBox from "src/components/SoftBox";
import SoftAvatar from "src/components/SoftAvatar";
import SoftTypography from "src/components/SoftTypography";
import { intlFormatDistance } from "date-fns";

function CommentCard({ name, date, comment, image }) {
  return (
    <SoftBox display="flex">
      <SoftBox display="flex" flexShrink={0} alignItems="flex-start">
        <SoftAvatar
          src={image}
          alt={name}
          shadow="xs"
          bgColor="light"
          size="sm"
        />
      </SoftBox>
      <SoftBox flexGrow={1} ml={2}>
        <SoftBox display="inline-flex" alignItems="center">
          <SoftTypography
            variant="h6"
            fontWeight="medium"
            textTransform="capitalize"
            lineHeight="1rem"
          >
            {name}
          </SoftTypography>

          <SoftTypography
            variant="overline"
            fontWeight="light"
            opacity={0.6}
            ml={2}
          >
            {intlFormatDistance(
              date instanceof Date ? date : date.toDate(),
              new Date()
            )}
          </SoftTypography>
        </SoftBox>
        <SoftBox mt={0.2} mb={1} lineHeight={0.75}>
          <SoftTypography variant="body2" style={{ fontSize: "14px" }}>
            {comment}
          </SoftTypography>
        </SoftBox>

        {/* <SoftBox display="flex" flexWrap="wrap" alignItems="center"> */}
        {/*   <SoftBox display="flex" alignItems="center" mr={1}> */}
        {/*     <SoftTypography component="a" href="#" variant="body2" color="text"> */}
        {/*       <Icon>thumb_up</Icon>&nbsp; */}
        {/*     </SoftTypography> */}
        {/*     <SoftTypography variant="button" fontWeight="regular" color="text"> */}
        {/*       likes */}
        {/*     </SoftTypography> */}
        {/*   </SoftBox> */}
        {/*   <SoftBox display="flex" alignItems="center"> */}
        {/*     <SoftTypography component="a" href="#" variant="body2" color="text"> */}
        {/*       <Icon>share</Icon>&nbsp; */}
        {/*     </SoftTypography> */}
        {/*     <SoftTypography variant="button" fontWeight="regular" color="text"> */}
        {/*       shares */}
        {/*     </SoftTypography> */}
        {/*   </SoftBox> */}
        {/* </SoftBox> */}
      </SoftBox>
    </SoftBox>
  );
}

export default CommentCard;
