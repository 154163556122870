import { useEffect, useState } from "react";
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import SoftBox from "src/components/SoftBox";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import SoftTypography from "src/components/SoftTypography";
import Stack from "@mui/material/Stack";
import SoftButton from "src/components/SoftButton";
import Footer from "src/components/Footer";
import { EnrollNewStudentModal } from "src/modals/EnrollNewStudentModal";
import Classroom from "../classroom";
import EnrollmentTable from "./components/EnrollmentTable";
import { useClubs } from "src/features/club/ClubProvider";
import { useLms } from "src/features/lms/LmsProvider";
import { Skeleton } from "@mui/material";
import { usePermissions } from "src/hooks/usePermissions";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";

function ManageStudents() {
  const { isInstructor } = usePermissions();
  const { selectedLocationId } = useClubs();
  const { getClubEnrollmentsData } = useLms();
  const [openAddNewStudentModal, setOpenAddNewStudentModal] = useState(false);
  const [enrollments, setEnrollments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedEnrollment, setSelectedEnrollment] = useState(null);

  const getEnrollmentData = async () => {
    setIsLoading(true);
    const enrollmentData = await getClubEnrollmentsData(selectedLocationId);
    setEnrollments(enrollmentData);
    setIsLoading(false);
  };

  const handleOpenAddNewStudentModal = () => {
    setOpenAddNewStudentModal(true);
  };

  const handleCloseAddNewStudentModal = (refresh) => {
    setOpenAddNewStudentModal(false);
    setSelectedEnrollment(null);

    if (refresh) {
      getEnrollmentData();
    }
  };

  const handleEditEnrollment = (enrollmentId) => {
    const enrollment = enrollments.find((e) => e.id === enrollmentId);
    if (!enrollment) {
      console.error("Error editing enrollment ", enrollmentId);
      return;
    }
    setSelectedEnrollment(enrollment);
    setOpenAddNewStudentModal(true);
  };

  useEffect(() => {
    getEnrollmentData();
  }, []);

  if (isInstructor()) {
    return <Classroom />;
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Enrolled Students
              </SoftTypography>
              <SoftTypography
                variant="button"
                fontWeight="regular"
                color="text"
              >
                Manage all members here.
              </SoftTypography>
            </SoftBox>
            <WithPermissions permissions={systemPermissions.ENROLL_STUDENTS}>
              <Stack spacing={1} direction="row">
                <SoftButton
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={() => handleOpenAddNewStudentModal()}
                >
                  + enroll student
                </SoftButton>
              </Stack>
            </WithPermissions>
          </SoftBox>
          {isLoading ? (
            <Skeleton height="30vh" variant="rectangular" sx={{ mb: 3 }} />
          ) : (
            <EnrollmentTable
              enrollments={enrollments}
              refreshEnrollmentData={getEnrollmentData}
              onEditEnrollment={handleEditEnrollment}
            />
          )}
        </Card>
      </SoftBox>
      <Footer />
      <Modal
        open={openAddNewStudentModal}
        onClose={handleCloseAddNewStudentModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <EnrollNewStudentModal
            handleClose={handleCloseAddNewStudentModal}
            enrollmentData={selectedEnrollment}
          />
        </SoftBox>
      </Modal>
    </DashboardLayout>
  );
}

export default ManageStudents;
