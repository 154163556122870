import PropTypes from "prop-types";
import { Card } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";
import { TimelineProvider } from "src/components/Timeline/context";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import { AddEventModal } from "src/modals/AddEventModal";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";

function TimelineList({ title, dark, children }) {
  const [openAddEventModal, setOpenAddEventModal] = useState(false);

  const handleAddEventModalClose = () => {
    setOpenAddEventModal(false);
  };

  return (
    <>
      <TimelineProvider value={dark}>
        <Card>
          <SoftBox bgColor={dark ? "dark" : "white"} variant="gradient">
            <SoftBox pt={3} px={3}>
              <SoftTypography
                variant="h6"
                fontWeight="medium"
                color={dark ? "white" : "dark"}
              >
                {title}
              </SoftTypography>
              <WithPermissions permissions={systemPermissions.CREATE_EVENT}>
                <SoftBox display="flex" justifyContent="flex-end">
                  <SoftButton
                    variant="text"
                    color="info"
                    onClick={() => {
                      setOpenAddEventModal(true);
                    }}
                  >
                    + Add New
                  </SoftButton>
                </SoftBox>
              </WithPermissions>
            </SoftBox>
            <SoftBox p={2}>{children}</SoftBox>
          </SoftBox>
        </Card>
      </TimelineProvider>
      <Modal
        open={openAddEventModal}
        onClose={handleAddEventModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddEventModal handleClose={handleAddEventModalClose} />
        </SoftBox>
      </Modal>
    </>
  );
}

// Setting default values for the props of TimelineList
TimelineList.defaultProps = {
  dark: false,
};

// Typechecking props for the TimelineList
TimelineList.propTypes = {
  title: PropTypes.string.isRequired,
  dark: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default TimelineList;
