import {
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { fetchWeatherApi } from "openmeteo";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { useClubs } from "../club/ClubProvider";
import { startOfDay } from "date-fns";
import { AuthLoadingPage } from "src/components/AuthLoadingPage";

const WeatherContext = createContext(null);
WeatherContext.displayName = "WeatherContext";

function WeatherProvider({ children }: { children: React.ReactNode }) {
  const { selectedLocation }: { selectedLocation: any } = useClubs();
  const [weatherForecast, setWeatherForecast]: SetStateAction<any> =
    useState(null);
  const [dailyData, setDailyData]: SetStateAction<any> = useState(null);
  const [weatherTimeout, setWeatherTimeout]: SetStateAction<any> =
    useState(false);

  useEffect(() => {
    setTimeout(() => {
      setWeatherTimeout(true);
    }, 5000);
  }, []);

  const fetchWeather = async () => {
    const avwxUrl = `https://avwx.rest/api/station/${selectedLocation?.icao}`;

    const stationResponse = await fetch(avwxUrl, {
      method: "GET",
      headers: {
        Authorization: `BEARER ${import.meta.env.VITE_AVWX_API_KEY}`,
        "Content-type": "application/json; charset=UTF-8",
      },
    });
    const data = await stationResponse.json();
    if (data.error) {
      console.error(data.error);
      return;
    }

    const params = {
      latitude: data.latitude,
      longitude: data.longitude,
      hourly: [
        "temperature_2m",
        "relative_humidity_2m",
        "dew_point_2m",
        "precipitation_probability",
        "precipitation",
        "weather_code",
        "pressure_msl",
        "cloud_cover",
        "visibility",
        "wind_speed_10m",
        "wind_direction_10m",
        "wind_gusts_10m",
      ],
      daily: ["sunrise", "sunset"],
      wind_speed_unit: "kn",
      forecast_days: 16,
      timezone: "auto",
    };
    const url = "https://api.open-meteo.com/v1/forecast";
    const responses = await fetchWeatherApi(url, params);

    // Helper function to form time ranges
    const range = (start: number, stop: number, step: number) =>
      Array.from({ length: (stop - start) / step }, (_, i) => start + i * step);
    const response = responses[0];
    const utcOffsetSeconds = response.utcOffsetSeconds();

    const hourly = response.hourly()!;
    const daily = response.daily()!;

    // Note: The order of weather variables in the URL query and the indices below need to match!
    const time = range(
      Number(daily.time()),
      Number(daily.timeEnd()),
      daily.interval()
    );
    const weatherData = {
      hourly: {
        time: range(
          Number(hourly.time()),
          Number(hourly.timeEnd()),
          hourly.interval()
        ).map((t) => new Date((t + utcOffsetSeconds) * 1000)),
        temperature2m: hourly.variables(0)!.valuesArray()!,
        humidity2m: hourly.variables(1)!.valuesArray()!,
        dewPoint2m: hourly.variables(2)!.valuesArray()!,
        precipitationProbability: hourly.variables(3)!.valuesArray()!,
        precipitation: hourly.variables(4)!.valuesArray()!,
        weatherCode: hourly.variables(5)!.valuesArray()!,
        pressure: hourly.variables(6)!.valuesArray()!,
        cloudCover: hourly.variables(7)!.valuesArray()!,
        visibility: hourly.variables(8)!.valuesArray()!,
        windSpeed10m: hourly.variables(9)!.valuesArray()!,
        windDirection10m: hourly.variables(10)!.valuesArray()!,
        windGusts10m: hourly.variables(11)!.valuesArray()!,
      },
      daily: {
        time: time?.map((t) => new Date((t + utcOffsetSeconds) * 1000)),
        sunrise: time?.map(
          (t, i) => new Date(Number(daily.variables(0)!.valuesInt64(i)) * 1000)
        ),
        sunset: time.map(
          (t, i) => new Date(Number(daily.variables(1)!.valuesInt64(i)) * 1000)
        ),
      },
    };

    // `weatherData` now contains a simple structure with arrays for datetime and weather data
    const hourlyData = new Map();
    for (let i = 0; i < weatherData.hourly.time.length; i++) {
      hourlyData.set(weatherData.hourly.time[i].toISOString(), {
        temperature2m: weatherData.hourly.temperature2m[i],
        humidity2m: weatherData.hourly.humidity2m[i],
        dewPoint2m: weatherData.hourly.dewPoint2m[i],
        precipitationProbability:
          weatherData.hourly.precipitationProbability[i],
        precipitation: weatherData.hourly.precipitation[i],
        weatherCode: weatherData.hourly.weatherCode[i],
        pressure: weatherData.hourly.pressure[i],
        cloudCover: weatherData.hourly.cloudCover[i],
        visibility: weatherData.hourly.visibility[i],
        windSpeed10m: weatherData.hourly.windSpeed10m[i],
        windDirection10m: weatherData.hourly.windDirection10m[i],
        windGusts10m: weatherData.hourly.windGusts10m[i],
      });
    }
    setWeatherForecast(hourlyData);

    const dailyDataMap = new Map();
    for (let i = 0; i < weatherData.daily.time.length; i++) {
      dailyDataMap.set(startOfDay(weatherData.daily.sunrise[i]).toISOString(), {
        sunrise: weatherData.daily.sunrise[i],
        sunset: weatherData.daily.sunset[i],
      });
    }
    setDailyData(dailyDataMap);
  };

  useEffect(() => {
    if (selectedLocation) {
      fetchWeather();
    }
  }, [selectedLocation]);

  const value: any = {
    weatherForecast,
    dailyData,
  };

  if ((!weatherForecast || !dailyData) && !weatherTimeout) {
    return <AuthLoadingPage text="Please wait while we load your data." />;
  }

  return (
    <WeatherContext.Provider value={value}>{children}</WeatherContext.Provider>
  );
}

function useWeather() {
  const context = useContext(WeatherContext);

  if (!context) {
    throw new Error(
      "useWeather should be used inside the WeatherContextProvider."
    );
  }

  return context;
}

WeatherProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { WeatherProvider, useWeather };
