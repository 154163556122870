import PropTypes from "prop-types";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

function CourseCardTitle({ children, color }) {
  return (
    <SoftBox
      sx={{
        borderLeft: 4,
        borderColor: `${color}.main`,
        width: "100%",
        px: 2,
        mb: 2,
        minHeight: "2.5em",
      }}
      display="flex"
      alignItems="center"
    >
      <SoftTypography fontSize="medium" fontWeight="medium">
        {children}
      </SoftTypography>
    </SoftBox>
  );
}

CourseCardTitle.defaultProps = {
  color: "primary",
};

CourseCardTitle.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf(["primary", "success", "secondary"]),
};

export default CourseCardTitle;
