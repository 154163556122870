import { useEffect, useState } from "react";
import { useClubs } from "src/features/club/ClubProvider";
import {
  differenceInHours,
  endOfToday,
  endOfYesterday,
  isAfter,
  isBefore,
  isToday,
  isYesterday,
  startOfToday,
  startOfYesterday,
} from "date-fns";
import { query, getCountFromServer, where } from "firebase/firestore";

import MiniStatisticsCard from "src/containers/Cards/StatisticsCards/MiniStatisticsCard";
import { getAircraftCollection } from "src/features/aircraft/collections";
import { getClubBookingsCollection } from "src/features/club/collections";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";

function AircraftUtilization() {
  const { selectedClubId, selectedLocationId } = useClubs();
  const [todaysUtilization, setTodaysUtilization] = useState(0);
  const [yesterdaysUtilization, setYesterdaysUtilization] = useState(0);
  const [numberOfAircraft, setNumberOfAircraft] = useState(0);
  const [dodGrowth, setDodGrowth] = useState(0);

  const clubsAircraftCountRef = query(
    getAircraftCollection(selectedClubId),
    where("locations", "array-contains", selectedLocationId),
    where("disabled", "==", false),
    where("deleted", "==", false)
  );

  const getAircraftCount = async () => {
    const aircraftCount = await getCountFromServer(clubsAircraftCountRef);
    setNumberOfAircraft(aircraftCount.data().count);
  };
  useEffect(() => {
    getAircraftCount();
  }, []);

  const locationBookingsCollectionRef = query(
    getClubBookingsCollection(selectedClubId, selectedLocationId),
    where("cancelled", "==", false),
    where("confirmed", "==", true),
    where("extendedProps.type.value", "in", [
      "reservation",
      "checkout",
      "checkride",
      "introFlight",
    ]),
    where("end", ">=", startOfYesterday())
  );
  const { data: allBookings, isDataLoaded: bookingsLoaded } =
    useRealtimeCollectionData(locationBookingsCollectionRef, true);

  useEffect(() => {
    if (allBookings && bookingsLoaded && numberOfAircraft > 0) {
      const filteredBookings = allBookings.filter((booking) => {
        const bookingStartDate = booking.start.toDate();
        const bookingEndDate = booking.end.toDate();
        return (
          isBefore(bookingStartDate, endOfToday()) &&
          isAfter(bookingEndDate, startOfToday())
        );
      });

      const yesterdaysFilteredBookings = allBookings.filter((booking) => {
        const bookingStartDate = booking.start.toDate();
        const bookingEndDate = booking.end.toDate();
        return (
          isBefore(bookingStartDate, endOfYesterday()) &&
          isAfter(bookingEndDate, startOfYesterday())
        );
      });

      let utilization = 0;
      filteredBookings.forEach((element) => {
        const startDate = isToday(element.start.toDate())
          ? element.start.toDate()
          : startOfToday();
        const endDate = isToday(element.end.toDate())
          ? element.end.toDate()
          : endOfToday();
        utilization += differenceInHours(endDate, startDate);
      });
      setTodaysUtilization((utilization / (24 * numberOfAircraft)) * 100);

      let utilizationYesterday = 0;
      yesterdaysFilteredBookings.forEach((element) => {
        const startDate = isYesterday(element.start.toDate())
          ? element.start.toDate()
          : startOfYesterday();
        const endDate = isYesterday(element.end.toDate())
          ? element.end.toDate()
          : endOfYesterday();
        utilizationYesterday += differenceInHours(endDate, startDate);
      });
      setYesterdaysUtilization(
        (utilizationYesterday / (24 * numberOfAircraft)) * 100
      );
    }
  }, [allBookings, bookingsLoaded, numberOfAircraft]);

  useEffect(() => {
    const growth =
      ((todaysUtilization - yesterdaysUtilization) / yesterdaysUtilization) *
      100;
    setDodGrowth(growth === Infinity ? 999 : growth);
  }, [todaysUtilization, yesterdaysUtilization]);

  return (
    <SoftBox mb={3}>
      <MiniStatisticsCard
        title={{
          text: "Today's Utilization",
          fontWeight: "bold",
        }}
        count={`${Math.round(todaysUtilization)}%`}
        percentage={{
          color: dodGrowth >= 0 ? "success" : "error",
          text: `${dodGrowth >= 0 ? "+" : ""}${
            dodGrowth ? Math.round(dodGrowth) : 0
          }%`,
        }}
        icon={{
          color: "info",
          component: "flight",
        }}
      />
    </SoftBox>
  );
}

export default AircraftUtilization;
