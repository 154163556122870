import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { Mention, MentionsInput } from "react-mentions";

const style = {
  control: {
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    letterSpacing: "0.00938em",
    boxSizing: "border-box",
    position: "relative",
    cursor: "text",
    WebkitAlignItems: "center",
    WebkitBoxAlign: "center",
    msFlexAlign: "center",
    alignItems: "center",
    display: "grid",
    placeItems: "center",
    width: "100%",
    height: "auto",
    padding: "0.5rem 0.75rem",
    fontSize: "0.875rem",
    fontWeight: "400",
    lineHeight: 1.4,
    color: "#495057",
    backgroundColor: "#ffffff",
    WebkitBackgroundClip: "padding-box !important",
    backgroundClip: "padding-box",
    border: "0.0625rem solid #d2d6da",
    WebkitAppearance: "none !important",
    MozAppearance: "none !important",
    msAppearance: "none !important",
    appearance: "none !important",
    borderRadius: "0.5rem",
    WebkitTransition:
      "box-shadow 150ms ease,border-color 150ms ease,padding 150ms ease !important",
    transition:
      "box-shadow 150ms ease,border-color 150ms ease,padding 150ms ease",
    pointerEvents: "auto",
  },

  input: {
    WebkitTextSizeAdjust: "100%",
    wordWrap: "break-word",
    pointerEvents: "auto",
    font: "inherit",
    letterSpacing: "inherit",
    color: "currentColor",
    border: 0,
    boxSizing: "content-box",
    background: "none",
    margin: 0,
    WebkitTapHighlightColor: "transparent",
    display: "block",
    minWidth: 0,
    animationName: "mui-auto-fill-cancel",
    animationDuration: "10ms",
    height: "1.375rem",
    padding: 0,
    outline: "none",
    width: "100%",
    position: "relative",
  },

  highlighter: {
    position: "absolute",
  },

  suggestions: {
    top: "25px",
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 14,
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
};

const QuickbooksClassInput = forwardRef(
  (
    {
      value,
      onChange,
      onBlur,
      onAdd,
      placeholder,
      trigger,
      markup,
      displayTransform,
      data,
      renderSuggestion,
    },
    ref
  ) => {
    return (
      <MentionsInput
        ref={ref}
        singleLine
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        className="QboClassInput"
        style={style}
      >
        <Mention
          trigger={trigger}
          markup={markup}
          displayTransform={displayTransform}
          data={data}
          onAdd={onAdd}
          renderSuggestion={renderSuggestion}
        />
      </MentionsInput>
    );
  }
);

// Setting default values for the props of SoftInput
QuickbooksClassInput.defaultProps = {
  placeholder: "Insert variables by typing '{'",
  data: [
    {
      id: "aircraft.tail_number",
      display: "Aircraft: Tail Number",
    },
    {
      id: "location.icao",
      display: "Location: ICAO",
    },
    {
      id: "location.name",
      display: "Location: Name",
    },
  ],
  trigger: "{",
  markup: "{__id__}",
  displayTransform: (id) => `{${id}}`,
  renderSuggestion: (suggestion, search, highlightedDisplay) => (
    <div className="user">{highlightedDisplay}</div>
  ),
};

// Typechecking props for the SoftInput
QuickbooksClassInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func,
  placeholder: PropTypes.string,
  trigger: PropTypes.string,
  markup: PropTypes.string,
  displayTransform: PropTypes.func,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
    })
  ).isRequired,
  renderSuggestion: PropTypes.func,
};

QuickbooksClassInput.displayName = "QuickbooksClassInput";

export default QuickbooksClassInput;
