import { useEffect, useState } from "react";
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftSelectPaymentMethod from "src/components/SoftSelectPaymentMethod";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

// Soft UI Dashboard PRO React base styles
import borders from "src/assets/theme/base/borders";
import colors from "src/assets/theme/base/colors";

// Images
import masterCardLogo from "src/assets/images/logos/mastercard.png";
import visaCardLogo from "src/assets/images/logos/visa.png";

import { getFunctions, httpsCallable } from "firebase/functions";
import { calculateFees, formatCurrency } from "src/features/utils";

function PaymentDetails({ paymentRequest }) {
  const { borderWidth, borderColor } = borders;
  const { light } = colors;

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();

  const [processingPayment, setProcessingPayment] = useState(false);
  const [totalPayment, setTotalPayment] = useState(0);

  const onSelect = (event) => {
    setSelectedPaymentMethod(event.value);
  };

  const showAwaitingPayment = () => {};

  useEffect(() => {
    const fees = calculateFees(
      (paymentRequest?.totalCost || 0) -
        (paymentRequest?.payments?.reduce(
          (acc, payment) => acc + (payment?.amount ?? 0) / 100,
          0
        ) ?? 0),
      0,
      0,
      paymentRequest?.clubPaysFees || false,
      true,
      selectedPaymentMethod
    );
    setTotalPayment(fees.chargeToPilot);
  }, [selectedPaymentMethod]);

  const renderPayments = () => (
    <>
      <SoftTypography variant="h6" fontWeight="medium">
        Payment details
      </SoftTypography>
      {paymentRequest?.payments?.map((payment) =>
        payment?.charges?.data?.map((charge) => {
          if (charge?.payment_method_details?.type === "us_bank_account") {
            return (
              <SoftBox
                border={`${borderWidth[1]} solid ${borderColor}`}
                borderRadius="lg"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                key={charge.id}
                p={3}
                mt={2}
              >
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  alignItems="start"
                >
                  <SoftTypography variant="h6" fontWeight="medium">
                    {charge?.payment_method_details?.us_bank_account?.bank_name}
                  </SoftTypography>
                  <SoftTypography variant="caption" fontWeight="medium">
                    Ending:{" "}
                    {charge?.payment_method_details?.us_bank_account?.last4}
                  </SoftTypography>
                </SoftBox>
                <SoftBox ml="auto" lineHeight={0}>
                  <Tooltip
                    title="We do not store account details, just the last 4 digits"
                    placement="bottom"
                  >
                    <SoftButton
                      variant="outlined"
                      color="secondary"
                      size="small"
                      iconOnly
                      circular
                    >
                      <Icon
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        priority_high
                      </Icon>
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            );
          }
          if (
            charge?.payment_method_details?.type.includes("Account") ||
            charge?.payment_method_details?.type === "Cash" ||
            charge?.payment_method_details?.type === "Check"
          ) {
            return (
              <SoftBox
                border={`${borderWidth[1]} solid ${borderColor}`}
                borderRadius="lg"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                key={charge.id}
                p={3}
                mt={2}
              >
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  alignItems="start"
                >
                  <SoftTypography
                    variant="caption"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    {charge?.payment_method_details?.type} Payment:{" "}
                    {formatCurrency((charge?.amount || 0) / 100)}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            );
          }
          if (charge?.payment_method_details?.type === "Refund") {
            return (
              <SoftBox
                border={`${borderWidth[1]} solid ${borderColor}`}
                borderRadius="lg"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                key={charge.id}
                p={3}
                mt={2}
              >
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  alignItems="start"
                >
                  <SoftTypography
                    variant="caption"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    Account Refunded:{" "}
                    {formatCurrency((charge?.amount || 0) / 100)}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            );
          }
          if (
            charge?.payment_method_details?.type === "Marked As Paid" ||
            charge?.payment_method_details?.type === "ALC Disbursement"
          ) {
            return (
              <SoftBox
                border={`${borderWidth[1]} solid ${borderColor}`}
                borderRadius="lg"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                key={charge.id}
                p={3}
                mt={2}
              >
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  alignItems="start"
                >
                  <SoftTypography
                    variant="caption"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    {charge?.payment_method_details?.type}:{" "}
                    {formatCurrency(charge.amount / 100)}
                  </SoftTypography>
                  <SoftTypography
                    variant="caption"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    By: {charge?.updatedBy?.displayName}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            );
          }
          if (charge?.payment_method_details?.card_present) {
            charge.payment_method_details.card =
              charge.payment_method_details.card_present;
          }
          return (
            <SoftBox
              border={`${borderWidth[1]} solid ${borderColor}`}
              borderRadius="lg"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              key={charge.id}
              p={2}
              mt={2}
            >
              <SoftBox
                component="img"
                src={
                  charge?.payment_method_details?.card?.brand === "visa"
                    ? visaCardLogo
                    : masterCardLogo
                }
                alt={charge?.payment_method_details?.card?.brand}
                width="10%"
                mr={2}
              />
              <SoftBox display="flex" flexDirection="column" alignItems="start">
                <SoftTypography
                  display="block"
                  style={{ marginBottom: "5px" }}
                  variant="caption"
                  fontWeight="medium"
                >
                  Card Payment: {formatCurrency(charge.amount / 100)}
                </SoftTypography>
                <SoftTypography variant="h6" fontWeight="medium">
                  ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;
                  {charge?.payment_method_details?.card?.last4}
                </SoftTypography>
              </SoftBox>
              <SoftBox ml="auto" lineHeight={0}>
                <Tooltip
                  title="We do not store card details, just the last 4 digits"
                  placement="bottom"
                >
                  <SoftButton
                    variant="outlined"
                    color="secondary"
                    size="small"
                    iconOnly
                    circular
                  >
                    <Icon
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      priority_high
                    </Icon>
                  </SoftButton>
                </Tooltip>
              </SoftBox>
            </SoftBox>
          );
        })
      )}
    </>
  );

  const takePayment = async () => {
    setProcessingPayment(true);
    const functions = getFunctions();
    const payOutstandingTransaction = httpsCallable(
      functions,
      "payOutstandingTransaction"
    );

    payOutstandingTransaction({
      clubId: paymentRequest.club.id,
      locationId: paymentRequest.location.id,
      paymentMethod: selectedPaymentMethod,
      clubBillingId: paymentRequest.id,
    })
      .then(() => {
        if (selectedPaymentMethod.includes("tmr_")) {
          showAwaitingPayment();
        } else {
          setProcessingPayment(false);
        }
      })
      .catch((error) => {
        console.error("payOutstandingTransaction ERROR", error);
        setProcessingPayment(false);
      });
  };

  const renderCapturePayment = () => (
    <SoftBox border={`${borderWidth[1]} solid ${light.main}`} mb={3}>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={1}
        px={2}
        bgColor="light"
      >
        <SoftTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          Select Payment Method:
        </SoftTypography>
      </SoftBox>
      <SoftBox p={2}>
        <SoftBox display="flex" flexDirection="column" p={0} m={0}>
          <SoftBox pt={1} pr={2}>
            <SoftSelectPaymentMethod
              // enableNonRev
              forUser={paymentRequest.pilot}
              onChange={onSelect}
              isDisabled={processingPayment}
            />
          </SoftBox>
        </SoftBox>
      </SoftBox>
      {selectedPaymentMethod && (
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={2}
        >
          <SoftTypography
            display="block"
            style={{ marginBottom: "5px" }}
            variant="caption"
            fontWeight="medium"
          >
            Total: {formatCurrency(totalPayment)}
          </SoftTypography>
          <SoftButton
            variant="contained"
            disabled={processingPayment}
            color="primary"
            onClick={() => takePayment()}
          >
            {processingPayment ? <i>Processing payment</i> : "Take Payment"}
          </SoftButton>
        </SoftBox>
      )}
    </SoftBox>
  );

  return (paymentRequest?.payments?.reduce(
    (acc, payment) => acc + (payment?.amount ?? 0) / 100,
    0
  ) ?? 0) <
    Math.round((paymentRequest?.totalCost || 0) * 100) / 100
    ? [renderCapturePayment(), renderPayments()]
    : renderPayments();
}

PaymentDetails.defaultProps = {
  paymentRequest: PropTypes.object.isRequired,
};

export default PaymentDetails;
