import { Info } from "@mui/icons-material";
import { Checkbox, Stack, Grid, Tooltip } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import { IPermission } from "src/interfaces/roles/permissions/permission.interface";
import { systemPermissionsType } from "src/interfaces/roles/role.interface";

interface IUserRoleEntry {
  permission: IPermission;
  parentKey?: string;
  isPermissionSelected: (key: systemPermissionsType) => boolean;
  onChange?: (isChecked: boolean, key: systemPermissionsType) => void;
}
export const UserRoleEntry: React.FC<IUserRoleEntry> = ({
  permission,
  parentKey = "",
  isPermissionSelected,
  onChange,
}) => {
  return (
    <Stack direction="column" gap="12px">
      <SoftTypography variant="h6" color="primary">
        {permission.label}{" "}
        {permission.tooltipGroup && (
          <Tooltip title={permission.tooltipGroup} placement="right">
            <Info color="action" />
          </Tooltip>
        )}
      </SoftTypography>
      <Grid container gap="16px" wrap="wrap">
        {Object.entries(permission.permissions).map(
          ([key, values]: [string, any]) => (
            <Grid item xs={12} md={4} key={key} sx={{ display: "contents" }}>
              <SoftBox
                display="flex"
                alignItems="center"
                sx={{ padding: "5px 0" }}
                key={`${parentKey}-${key}`}
              >
                <Checkbox
                  value={key}
                  checked={isPermissionSelected(
                    key as unknown as systemPermissionsType
                  )}
                  onChange={(e) => {
                    onChange?.(
                      e.target.checked,
                      key as unknown as systemPermissionsType
                    );
                  }}
                />

                <SoftTypography variant="button">{values.label}</SoftTypography>
                {values.tooltip && (
                  <Tooltip title={values.tooltip}>
                    <Info color="action" sx={{ marginLeft: "4px" }} />
                  </Tooltip>
                )}
              </SoftBox>
            </Grid>
          )
        )}
      </Grid>
    </Stack>
  );
};
