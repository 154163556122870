/* eslint-disable no-undef */
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React base styles
import typography from "src/assets/theme/base/typography";

function Footer({
  links = [
    {
      href: "https://preflightfbo.com/",
      name: "Website",
    },
    {
      href: "https://preflightfbo.com/license",
      name: "License",
    },
  ],
}) {
  const { size } = typography;

  const renderLinks = () =>
    links.map((link) => (
      <SoftBox key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <SoftTypography variant="button" fontWeight="regular" color="text">
            {link.name}
          </SoftTypography>
        </Link>
      </SoftBox>
    ));

  return (
    <SoftBox
      width="100%"
      display="flex"
      flexDirection={{
        xs: "column",
        lg: "row",
      }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        &copy; {new Date().getFullYear()}, PreFlight LLC.
      </SoftBox>
      <SoftBox
        component="ul"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        <SoftBox component="li" px={2} lineHeight={1}>
          <SoftTypography
            variant="button"
            fontWeight="regular"
            color="secondary"
          >
            v{APP_VERSION}
          </SoftTypography>
        </SoftBox>
        {renderLinks()}
      </SoftBox>
    </SoftBox>
  );
}

// Typechecking props for the Footer
Footer.propTypes = {
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
