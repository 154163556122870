import { useEffect, useState, useMemo } from "react";
import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import SoftSelect from "src/components/SoftSelect";
import SoftDatePicker from "src/components/SoftDatePicker";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { useUser } from "src/features/user/UserProvider";
import { useClubs } from "src/features/club/ClubProvider";
import { useLms } from "src/features/lms/LmsProvider";
import AddNewCard from "src/modals/AddNewCard";

import { addDoc, doc, updateDoc } from "firebase/firestore";
import {
  isWithinInstructorWorkingHours,
  isOverlappingAnotherBooking,
  getOverlappingBookings,
} from "src/pages/book-flight/utils";
import { getClubBookingsCollection } from "src/features/club/collections";
import { useUserPermissions } from "src/features/user-permissions/UserPermissionsProvider";
import { ArrowBack } from "@mui/icons-material";
import SoftInput from "src/components/SoftInput";
import { differenceInHours, isBefore } from "date-fns";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { usePermissions } from "src/hooks/usePermissions";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";

const schema = yup
  .object({
    reservationType: yup.object(),
    startDate: yup.date(),
    endDate: yup.date(),
  })
  .required();

const GetTimeBeforeApprovalInHours = (timeBeforeApproval, timeType) => {
  switch (timeType) {
    case "hours":
      return timeBeforeApproval;
    case "days":
    default:
      return timeBeforeApproval * 24;
  }
};

const TimeOffApprovalNeeded = (
  timeOffApprovalEnabled,
  timeBeforeApproval,
  timeType,
  bookingStartDate,
  bookingEndDate
) => {
  if (!timeOffApprovalEnabled) return false;
  if (timeBeforeApproval === undefined) return false;
  if (timeBeforeApproval === 0) return true;

  const bookingHours = differenceInHours(bookingEndDate, bookingStartDate);
  const hoursBeforeApproval = GetTimeBeforeApprovalInHours(
    timeBeforeApproval,
    timeType
  );

  return bookingHours > hoursBeforeApproval;
};

const GetReservationTypes = (userPermissions, selectedClub, oldBookingData) => {
  const { hasAccess, isStaff } = usePermissions();
  if (oldBookingData?.extendedProps?.type?.value === "meetingRoom")
    return [
      {
        value: "meetingRoom",
        label: "Meeting Room",
      },
    ];

  const reservationTypes = [
    {
      value: "reservation",
      label: "Reservation",
    },
    {
      value: "checkride",
      label: "Checkride",
    },
    {
      value: "checkout",
      label: "Aircraft Checkout",
    },
  ];

  if (
    selectedClub?.id === "WIiWPjziBp5WbB27y0Y2" ||
    selectedClub?.id === "2mx7hN4R1majiwtYHUzv" ||
    selectedClub?.id === "v1Xj2DtMWGnCJkp"
  ) {
    reservationTypes.push({
      value: "pilotRefresher",
      label: "Pilot Refresher",
    });
  }

  if (isStaff()) {
    reservationTypes.push(
      {
        value: "unavailable",
        label: "Time Off",
      },
      {
        value: "requestOnly",
        label: "Request Only",
      }
    );
  }
  if (hasAccess(systemPermissions.CREATE_INTRO_FLIGHT)) {
    reservationTypes.push({
      value: "introFlight",
      label: "Intro Flight",
    });
  }
  if (hasAccess(systemPermissions.CREATE_MX_BOOKING)) {
    reservationTypes.push({
      value: "maintenance",
      label: "Maintenance",
    });
  }

  return reservationTypes;
};

export function BookingModal({
  handleClose,
  selection,
  isEditReservation,
  oldBookingData,
  resources,
  ...rest
}) {
  const { user, userId } = useUser();
  const { selectedClub, selectedClubId, selectedLocationId, instructors } =
    useClubs();
  const { getUserEnrollmentsData } = useLms();
  const { preferences } = selectedClub;
  const {
    userPermissions,
    canCreateBooking,
    canEditBooking,
    canOverrideBooking,
    isUserInAircraftMembershipLevel,
  } = useUserPermissions();
  const [savedBooking, setSavedBooking] = useState();
  const [permissionErrors, setPermissionErrors] = useState([]);
  const [lessons, setLessons] = useState(null);
  const [displayAddCard, setDisplayAddCard] = useState(false);
  const [addCardToUserId, setAddCardToUserId] = useState(null);
  const { hasAccess } = usePermissions();
  const today = useMemo(() => new Date(), []);

  const reservationTypes = rest.reservationType
    ? [rest.reservationType]
    : GetReservationTypes(userPermissions, selectedClub, oldBookingData);

  const getPreGroundTimes = () => {
    const clubMinimum = selectedClub.preferences?.minPreGround || 0;
    const groundTimes = [];
    let startTime = 120;
    while (startTime > clubMinimum) {
      groundTimes.push({
        value: startTime,
        label: `${startTime} mins`,
      });
      startTime -= 30;
    }
    if (clubMinimum < 15) {
      groundTimes.push({
        value: 15,
        label: "15 mins",
      });
    }
    groundTimes.push({
      value: clubMinimum,
      label: clubMinimum === 0 ? "None" : `${clubMinimum} mins`,
    });
    return groundTimes;
  };

  const getPostGroundTimes = () => {
    const clubMinimum = selectedClub.preferences?.minPostGround || 0;
    const groundTimes = [];
    let startTime = 120;
    while (startTime > clubMinimum) {
      groundTimes.push({
        value: startTime,
        label: `${startTime} mins`,
      });
      startTime -= 30;
    }
    if (clubMinimum < 15) {
      groundTimes.push({
        value: 15,
        label: "15 mins",
      });
    }
    groundTimes.push({
      value: clubMinimum,
      label: clubMinimum === 0 ? "None" : `${clubMinimum} mins`,
    });
    return groundTimes;
  };

  const getFormDefaultValues = () => {
    if (!isEditReservation) {
      const startDate = selection.start || selection.date;
      const endDate = selection.end || selection.date;

      let selectedAircraft = {
        value: "",
        label: "Ground",
      };

      let selectedInstructor = {
        value: "",
        label: "Solo",
      };

      let selectedPilot = {
        value: "",
        label: "None",
      };

      let selectedMeetingRoom = {
        value: "",
        label: "None",
      };

      let preGround = {
        value: 0,
        label: "None",
      };

      let postGround = {
        value: 0,
        label: "None",
      };

      if (selectedClub.preferences?.minPreGround) {
        preGround = {
          value: selectedClub.preferences.minPreGround,
          label: `${selectedClub.preferences.minPreGround} mins`,
        };
      }

      if (selectedClub.preferences?.minPostGround) {
        postGround = {
          value: selectedClub.preferences.minPostGround,
          label: `${selectedClub.preferences.minPostGround} mins`,
        };
      }

      if (
        selection.resource?.extendedProps?.type === "Aircraft" ||
        selection.resource?.extendedProps?.type === "Simulators"
      ) {
        selectedAircraft = {
          value: selection.resource.id,
          label: selection.resource.title,
        };
      }

      if (selection.resource?.extendedProps?.type === "Instructors") {
        selectedInstructor = {
          value: selection.resource.id,
          label: selection.resource.title,
        };
      } else if (instructors.some((instructor) => instructor.id === userId)) {
        selectedInstructor = {
          value: userId,
          label: user?.displayName || "None",
        };
      }

      if (
        selection.resource?.extendedProps?.type === "Instructors" &&
        selection.resource.id !== userId
      ) {
        selectedPilot = {
          value: userId || "",
          label: user?.displayName || "None",
        };
      } else if (!instructors.some((instructor) => instructor.id === userId)) {
        selectedPilot = {
          value: userId || "",
          label: user?.displayName || "None",
        };
      }

      if (rest.reservationType?.value === "meetingRoom") {
        if (selection.resource) {
          selectedMeetingRoom = {
            value: selection.resource.value,
            label: selection.resource.label,
          };
        } else
          selectedMeetingRoom = {
            value: resources[0].id,
            label: resources[0].title,
          };
      }

      const reservationType = rest.reservationType
        ? rest.reservationType
        : {
            label: "Reservation",
            value: "reservation",
          };

      return {
        aircraft: selectedAircraft,
        instructor: selectedInstructor,
        meetingRoom: selectedMeetingRoom,
        preGround,
        postGround,
        endDate,
        startDate,
        pilot: selectedPilot,
        pilot2: {
          value: "",
          label: "None",
        },
        member: {
          label: "None",
          value: "",
        },
        reservationType,
      };
    }

    return {
      aircraft: oldBookingData.extendedProps.aircraft,
      instructor: oldBookingData.extendedProps.instructor,
      meetingRoom: oldBookingData.extendedProps.meetingRoom,
      preGround: {
        label:
          oldBookingData.preGround === 0 ? "None" : oldBookingData.preGround,
        value: oldBookingData.preGround,
      },
      postGround: {
        label:
          oldBookingData.postGround === 0 ? "None" : oldBookingData.postGround,
        value: oldBookingData.postGround,
      },
      endDate: oldBookingData.end?.toDate(),
      startDate: oldBookingData.start?.toDate(),
      pilot: oldBookingData.extendedProps.pilot,
      pilot2: oldBookingData.extendedProps.pilot2 || {
        value: "",
        label: "None",
      },
      member: oldBookingData.extendedProps.member,
      reservationType: oldBookingData.extendedProps.type,
      notes: oldBookingData.notes,
    };
  };

  const {
    handleSubmit,
    watch,
    formState: { errors },
    setError,
    clearErrors,
    control,
  } = useForm({
    defaultValues: getFormDefaultValues(),
    resolver: yupResolver(schema),
  });

  const [formOptions, setFormOptions] = useState({});

  useEffect(() => {
    let pilots = [];
    let pilots2 = [];
    if (!hasAccess(systemPermissions.CREATE_BOOKING_FOR_OTHERS)) {
      pilots.push({
        value: userId ?? "",
        label: user?.displayName || "None",
      });
    } else {
      pilots = rest?.pilots?.map((pilot) => ({
        value: pilot.id ?? "",
        label: pilot?.displayName || "None",
      }));
    }

    if (hasAccess(systemPermissions.CREATE_BOOKING_WITH_PILOT2)) {
      pilots2 = rest?.pilots?.map((pilot) => ({
        value: pilot?.id ?? "",
        label: pilot?.displayName || "None",
      }));
    }

    console.log("Resoruces:", resources);

    const instructors = [
      {
        value: "",
        label: "Solo",
      },
      ...resources
        .filter((resource) => resource.type === "Instructors")
        .map((instructor) => ({
          value: instructor.id,
          label: instructor.title,
        })),
    ];

    const aircrafts = [
      {
        value: "",
        label: "Ground",
      },
      ...resources
        .filter(
          (resource) =>
            resource.type === "Aircraft" || resource.type === "Simulators"
        )
        .map((aircraft) => ({
          value: aircraft.id,
          label: aircraft.title,
        })),
    ];

    const meetingRooms = [
      ...resources
        .filter((resource) => resource.type === "MeetingRooms")
        .map((meetingRoom) => ({
          value: meetingRoom.id,
          label: meetingRoom.title,
        })),
    ];

    setFormOptions({
      pilots,
      pilots2,
      instructors,
      aircrafts,
      meetingRooms,
    });
  }, [resources]);

  const reservationType = watch("reservationType")?.value;
  const instructor = watch("instructor");
  const pilot = watch("pilot");
  const pilot2 = watch("pilot2");
  const startDate = watch("startDate");

  const getLessons = async () => {
    if (userId === pilot?.value || userId === instructor?.value) {
      const enrollments = await getUserEnrollmentsData(pilot?.value);
      let stageLessons = [];
      const enrolledCourses = enrollments.flatMap(
        (enrollment) => enrollment.enrolledCourses
      );
      enrolledCourses.forEach((course) => {
        const currentStageLessons = course.stages
          ?.find((item) => item.index === course.currentStageIndex)
          ?.lessons?.map((lesson) => ({
            ...lesson,
            courseName: course.title,
            stageIndex: course.currentStageIndex,
          }));
        currentStageLessons && stageLessons.push(...currentStageLessons);
      });
      setLessons(stageLessons);
    }
  };

  useEffect(() => {
    clearErrors();
  }, [reservationType]);

  useEffect(() => {
    if (
      reservationType === "reservation" &&
      instructor?.value !== "" &&
      pilot?.value !== ""
    ) {
      getLessons();
    } else {
      setLessons(null);
    }
  }, [reservationType, instructor, pilot]);

  const saveBooking = async (bookingData, isWithinWorkinghours) => {
    let hasErrors = false;

    if (
      (bookingData?.extendedProps?.type?.value === "requestOnly" ||
        bookingData?.extendedProps?.type?.value === "unavailable") &&
      bookingData?.extendedProps?.instructor?.value === ""
    ) {
      setError("instructor", { message: "Please select an instructor" });
      return false;
    }

    if (
      bookingData?.extendedProps?.type?.value === "maintenance" &&
      bookingData?.extendedProps?.aircraft?.value === ""
    ) {
      setError("aircraft", { message: "Please select an aircraft" });
      return false;
    }

    if (
      !hasAccess(systemPermissions.ALLOW_BOOKING_PAST) &&
      isBefore(bookingData?.start, today)
    ) {
      setError("startDate", {
        message: "The depart date cannot be before today",
      });
      return false;
    }

    if (isBefore(bookingData?.end, bookingData?.start)) {
      setError("endDate", {
        message: "The return date cannot be before depart date",
      });
      return false;
    }

    setSavedBooking(bookingData);
    if (!hasAccess(systemPermissions.ALLOW_OVERLAPPING_BOOKINGS)) {
      const isOverlapping = await isOverlappingAnotherBooking(
        bookingData,
        selectedClub.preferences
      );
      if (isOverlapping) {
        hasErrors = true;
        setPermissionErrors(
          permissionErrors.concat(["Overlapping another booking"])
        );
      }
    }

    if (bookingData?.extendedProps?.aircraft?.value !== "") {
      const checkUserInAircraftMembershipLevel =
        await isUserInAircraftMembershipLevel(bookingData);
      if (!checkUserInAircraftMembershipLevel.allowed) {
        hasErrors = true;
        setPermissionErrors(
          permissionErrors.concat([
            `You must have a ${checkUserInAircraftMembershipLevel.reason} membership to book this aircraft`,
          ])
        );
      }
    }

    const permissionCheck = await canCreateBooking(bookingData);
    if (!permissionCheck.allowed) {
      hasErrors = true;
      setPermissionErrors(permissionErrors.concat(permissionCheck.reasons));
    }

    if (
      !isWithinWorkinghours &&
      !preferences?.allowBookingNonWorkingHours &&
      userId !== bookingData?.extendedProps?.instructor?.value
    ) {
      setPermissionErrors(
        permissionErrors.concat(
          "Booking is outside of instructors working hours"
        )
      );
      hasErrors = true;
    }

    if (hasErrors) {
      return false;
    }

    if (!isEditReservation) {
      await addDoc(
        getClubBookingsCollection(selectedClubId, selectedLocationId),
        bookingData
      );
    } else if (canEditBooking(bookingData)) {
      await updateDoc(
        doc(
          getClubBookingsCollection(selectedClubId, selectedLocationId),
          oldBookingData.id
        ),
        bookingData,
        {
          merge: true,
        }
      );
    }
    return true;
  };

  const overrideSaveBooking = async (bookingData) => {
    if (!isEditReservation) {
      await addDoc(
        getClubBookingsCollection(selectedClubId, selectedLocationId),
        bookingData
      );
    } else {
      await updateDoc(
        doc(
          getClubBookingsCollection(selectedClubId, selectedLocationId),
          oldBookingData.id
        ),
        bookingData,
        {
          merge: true,
        }
      );
    }
    handleClose();
  };

  const onSubmit = async (data) => {
    const resourceIds = [];
    if (data?.instructor?.value && data.instructor.value !== "")
      resourceIds.push(data.instructor.value);
    if (data?.aircraft?.value && data.aircraft.value !== "")
      resourceIds.push(data.aircraft.value);

    let bookingData = {
      createdAt: new Date(),
      createdBy: userId,
      ...oldBookingData,
      start: data.startDate,
      end: data.endDate,
      confirmed: oldBookingData?.confirmed || true,
      dispatched: oldBookingData?.dispatched || false,
      completed: oldBookingData?.completed || false,
      cancelled: oldBookingData?.cancelled || false,
      notes: data.notes || oldBookingData?.notes || "",
      updatedAt: new Date(),
    };

    switch (data.reservationType.value) {
      case "unavailable":
        bookingData = {
          ...bookingData,
          resourceIds: [data.instructor.value],
          extendedProps: {
            type: data.reservationType,
            instructor: data.instructor,
            clubId: selectedClubId,
            locationId: selectedLocationId,
          },
        };
        break;

      case "requestOnly":
        bookingData = {
          ...bookingData,
          resourceIds: [data.instructor.value],
          extendedProps: {
            type: data.reservationType,
            instructor: data.instructor,
            clubId: selectedClubId,
            locationId: selectedLocationId,
          },
        };
        break;

      case "maintenance":
        bookingData = {
          ...bookingData,
          resourceIds: [data.aircraft.value],
          extendedProps: {
            type: data.reservationType,
            clubId: selectedClubId,
            aircraft: data.aircraft,
            locationId: selectedLocationId,
          },
        };
        break;

      case "meetingRoom":
        bookingData = {
          ...bookingData,
          resourceIds: [data.meetingRoom.value],
          extendedProps: {
            type: data.reservationType,
            clubId: selectedClubId,
            meetingRoom: data.meetingRoom,
            member: data.member,
            locationId: selectedLocationId,
          },
        };
        break;

      default:
        bookingData = {
          ...bookingData,
          resourceIds,
          extendedProps: {
            type: data.reservationType,
            pilot: data.pilot,
            aircraft: data.aircraft,
            clubId: selectedClubId,
            locationId: selectedLocationId,
          },
        };
        if (data.lesson?.value && data.lesson?.value !== "") {
          bookingData.extendedProps.lesson = data.lesson;
        }
        if (data.instructor.value && data.instructor.value !== "") {
          bookingData.extendedProps.instructor = data.instructor;
          bookingData.preGround = data.preGround.value;
          bookingData.postGround = data.postGround.value;
          bookingData.pilot2 = { label: "None", value: "" };
        } else if (data.pilot2?.value && data.pilot2?.value !== "") {
          bookingData.extendedProps.pilot2 = data.pilot2;
          bookingData.extendedProps.instructor = { label: "None", value: "" };
        } else {
          bookingData.extendedProps.pilot2 = { label: "None", value: "" };
          bookingData.extendedProps.instructor = { label: "None", value: "" };
        }
        break;
    }

    const isWithinWorking = await isWithinInstructorWorkingHours(bookingData);
    const overlappingBookings = await getOverlappingBookings(bookingData);
    if (
      (bookingData.extendedProps?.type?.value === "reservation" ||
        bookingData.extendedProps?.type?.value === "pilotRefresher" ||
        bookingData.extendedProps?.type?.value === "checkout" ||
        bookingData.extendedProps?.type?.value === "checkride") &&
      (!isWithinWorking || overlappingBookings.requestOnly.length > 0) &&
      userId !== bookingData?.extendedProps?.instructor?.value
    ) {
      bookingData.confirmed = false;
    }

    if (
      bookingData.extendedProps.type.value === "unavailable" &&
      TimeOffApprovalNeeded(
        preferences?.timeOffApproval?.enabled,
        preferences?.timeOffApproval?.timeBeforeApproval,
        preferences?.timeOffApproval?.timeType,
        bookingData.start,
        bookingData.end
      )
    ) {
      bookingData.confirmed = false;
    }

    const success = await saveBooking(
      bookingData,
      isWithinWorking && !overlappingBookings.unavailable.length
    );
    if (success) {
      handleClose();
    }
  };

  const showAddNewCard = () => {
    setDisplayAddCard(true);
  };
  const handleCloseAddNewCard = (value) => {
    setDisplayAddCard(false);
    if (value.success) {
      overrideSaveBooking(savedBooking);
    }
  };

  if (displayAddCard) {
    return (
      <AddNewCard
        userId={addCardToUserId || pilot.value}
        handleClose={handleCloseAddNewCard}
      />
    );
  }

  const renderPermissionErrors = permissionErrors.map((item, key) => {
    const itemKey = `element-${key}`;
    return (
      <SoftBox
        key={itemKey}
        component="li"
        color="text"
        fontSize="1.25rem"
        lineHeight={1.25}
      >
        <SoftTypography
          variant="button"
          color="error"
          fontWeight="regular"
          verticalAlign="middle"
        >
          {item}
        </SoftTypography>
        {item.includes("No card on file") && (
          <SoftButton
            variant="text"
            color="dark"
            onClick={() => {
              if (item.includes(pilot.label)) setAddCardToUserId(pilot.value);
              else if (item.includes(pilot2.label))
                setAddCardToUserId(pilot2.value);
              showAddNewCard();
            }}
          >
            <Icon
              sx={{
                fontWeight: "bold",
              }}
            >
              add
            </Icon>
            &nbsp;add new card
          </SoftButton>
        )}
      </SoftBox>
    );
  });

  if (permissionErrors && permissionErrors.length > 0) {
    return (
      <ModalContainer handleClose={handleClose}>
        <SoftBox
          p={2}
          component="form"
          role="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <SoftBox>
            <SoftTypography variant="h5" fontWeight="bold" mt={5}>
              Checklist Failed
            </SoftTypography>
            <SoftBox mt={1.625}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">
                    You are not allowed to create this reservation for the
                    following reasons:
                  </SoftTypography>
                  <SoftBox mt={1.625}>
                    <SoftBox p={2}>
                      <SoftBox
                        component="ul"
                        m={0}
                        pl={3.25}
                        mb={{
                          xs: 8,
                          sm: 0,
                        }}
                      >
                        {renderPermissionErrors}
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                {canOverrideBooking(savedBooking) ? (
                  <>
                    <Grid item xs={6}>
                      <SoftBox mt={4} mb={1}>
                        <SoftButton
                          onClick={() => setPermissionErrors([])}
                          color="dark"
                          fullWidth
                          variant="outlined"
                        >
                          <ArrowBack
                            fontSize="small"
                            lineHeight={2}
                            marginRight={2}
                          />
                          &nbsp;&nbsp;&nbsp;Return to Booking
                        </SoftButton>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={6}>
                      <SoftBox mt={4} mb={1}>
                        <SoftButton
                          variant="gradient"
                          color="error"
                          fullWidth
                          onClick={() => overrideSaveBooking(savedBooking)}
                        >
                          Override & Book
                        </SoftButton>
                      </SoftBox>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    <SoftBox
                      mt={1}
                      display="flex"
                      alignItems="center"
                      flexDirection="row-reverse"
                    >
                      <SoftButton
                        variant="outlined"
                        color="dark"
                        onClick={handleClose}
                      >
                        Close
                      </SoftButton>
                    </SoftBox>
                  </Grid>
                )}
              </Grid>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </ModalContainer>
    );
  }

  const getPeople = (people, filteredBy) => {
    const filteredByIds = filteredBy
      .filter((v) => !!v.value)
      .map((v) => v.value);
    return (people ?? []).filter((p) => !filteredByIds.includes(p.value));
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            {isEditReservation ? "Update Reservation" : "New Reservation"}
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Reservation Type
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="reservationType"
                  render={({ field: { onChange, value, ref } }) => (
                    <SoftSelect
                      options={reservationTypes}
                      onChange={onChange}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SoftBox
                        mb={1}
                        ml={0.5}
                        mt={3}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          {reservationType === "reservation" ||
                          reservationType === "pilotRefresher" ||
                          reservationType === "checkout" ||
                          reservationType === "checkride"
                            ? "Depart"
                            : "Start"}
                        </SoftTypography>
                      </SoftBox>
                      <Controller
                        control={control}
                        name="startDate"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <SoftDatePicker
                            showTimeSelect
                            timeIntervals={
                              selectedClub.preferences?.calendar
                                ?.slotDuration ?? 15
                            }
                            dateFormat="Pp"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                          />
                        )}
                      />
                      {errors?.startDate?.message && (
                        <SoftTypography fontSize={12} color="error">
                          {errors.startDate.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={6}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SoftBox
                        mb={1}
                        ml={0.5}
                        mt={3}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                        >
                          {reservationType === "reservation" ||
                          reservationType === "pilotRefresher" ||
                          reservationType === "checkout" ||
                          reservationType === "checkride"
                            ? "Return"
                            : "End"}
                        </SoftTypography>
                      </SoftBox>
                      <Controller
                        control={control}
                        name="endDate"
                        render={({ field: { onChange, onBlur, value } }) => (
                          <SoftDatePicker
                            showTimeSelect
                            timeIntervals={
                              selectedClub.preferences?.calendar
                                ?.slotDuration ?? 15
                            }
                            dateFormat="Pp"
                            minDate={startDate}
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                          />
                        )}
                      />
                    </SoftBox>
                    {errors?.endDate?.message && (
                      <SoftTypography fontSize={12} color="error">
                        {errors.endDate.message}
                      </SoftTypography>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={3}>
                  {(reservationType === "reservation" ||
                    reservationType === "introFlight" ||
                    reservationType === "pilotRefresher" ||
                    reservationType === "checkout" ||
                    reservationType === "checkride") && (
                    <Grid item xs={12} sm={6}>
                      <SoftBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          Member 1
                        </SoftTypography>
                      </SoftBox>
                      <Controller
                        control={control}
                        name="pilot"
                        render={({ field: { onChange, value, ref } }) => (
                          <SoftSelect
                            options={getPeople(formOptions.pilots, [
                              instructor,
                              pilot2,
                            ])}
                            onChange={onChange}
                            inputRef={ref}
                            value={value}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {reservationType !== "maintenance" &&
                    reservationType !== "meetingRoom" && (
                      <WithPermissions
                        permissions={systemPermissions.BOOK_INSTRUCTOR}
                      >
                        <Grid item xs={12} sm={6}>
                          <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display="inline-block"
                          >
                            <SoftTypography
                              component="label"
                              variant="caption"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              Instructor
                            </SoftTypography>
                          </SoftBox>
                          <Controller
                            control={control}
                            name="instructor"
                            render={({ field: { onChange, value, ref } }) => (
                              <SoftSelect
                                options={getPeople(formOptions.instructors, [
                                  pilot,
                                  pilot2,
                                ])}
                                onChange={onChange}
                                inputRef={ref}
                                value={value}
                              />
                            )}
                          />
                          {errors?.instructor?.message && (
                            <SoftTypography
                              marginTop={1}
                              fontSize={12}
                              color="error"
                            >
                              {errors.instructor.message}
                            </SoftTypography>
                          )}
                        </Grid>
                      </WithPermissions>
                    )}
                  {reservationType === "meetingRoom" && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <SoftBox
                          mb={1}
                          ml={0.5}
                          lineHeight={0}
                          display="inline-block"
                        >
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            Meeting Room
                          </SoftTypography>
                        </SoftBox>
                        <Controller
                          control={control}
                          name="meetingRoom"
                          render={({ field: { onChange, value, ref } }) => (
                            <SoftSelect
                              options={formOptions.meetingRooms}
                              onChange={onChange}
                              inputRef={ref}
                              value={value}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftBox
                          mb={1}
                          ml={0.5}
                          lineHeight={0}
                          display="inline-block"
                        >
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            Member
                          </SoftTypography>
                        </SoftBox>
                        <Controller
                          control={control}
                          name="member"
                          render={({ field: { onChange, value, ref } }) => (
                            <SoftSelect
                              options={getPeople(formOptions.pilots, [
                                instructor,
                                pilot2,
                              ])}
                              onChange={onChange}
                              inputRef={ref}
                              value={value}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
              {instructor?.value &&
              instructor?.value !== "" &&
              (reservationType === "reservation" ||
                reservationType === "pilotRefresher" ||
                reservationType === "checkout" ||
                reservationType === "checkride") ? (
                <>
                  {reservationType === "reservation" && lessons?.length >= 1 ? (
                    <Grid item xs={12} sm={6}>
                      <SoftBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          Lesson
                        </SoftTypography>
                      </SoftBox>
                      <Controller
                        control={control}
                        name="lesson"
                        render={({ field: { onChange, value, ref } }) => (
                          <SoftSelect
                            options={[
                              { label: "None", value: "" },
                              ...lessons.map((lesson) => ({
                                value: lesson.id,
                                label: `${lesson.courseName} - ${
                                  lesson.stageIndex + 1
                                }.${lesson.index + 1}`,
                                lessonId: lesson.id,
                                stageId: lesson.stageId,
                                courseId: lesson.courseId,
                                graded: lesson.grade?.lessonGrade === "S",
                              })),
                            ]}
                            onChange={onChange}
                            inputRef={ref}
                            value={value}
                          />
                        )}
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={6} />
                  )}
                  <Grid item xs={6}>
                    <Grid container spacing={3} pl={1}>
                      <Grid item xs={12} sm={6}>
                        <SoftBox
                          mb={1}
                          ml={0.5}
                          lineHeight={0}
                          display="inline-block"
                        >
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            Pre-Ground
                          </SoftTypography>
                        </SoftBox>
                        <Controller
                          control={control}
                          name="preGround"
                          render={({ field: { onChange, value, ref } }) => (
                            <SoftSelect
                              options={getPreGroundTimes()}
                              onChange={onChange}
                              inputRef={ref}
                              value={value}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftBox
                          mb={1}
                          ml={0.5}
                          lineHeight={0}
                          display="inline-block"
                        >
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            Post-Ground
                          </SoftTypography>
                        </SoftBox>
                        <Controller
                          control={control}
                          name="postGround"
                          render={({ field: { onChange, value, ref } }) => (
                            <SoftSelect
                              options={getPostGroundTimes()}
                              onChange={onChange}
                              inputRef={ref}
                              value={value}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} sm={6}>
                  {reservationType === "reservation" && (
                    <WithPermissions
                      permissions={systemPermissions.CREATE_BOOKING_WITH_PILOT2}
                    >
                      <>
                        <SoftBox
                          mb={1}
                          ml={0.5}
                          lineHeight={0}
                          display="inline-block"
                        >
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            Member 2
                          </SoftTypography>
                        </SoftBox>
                        <Controller
                          control={control}
                          name="pilot2"
                          render={({ field: { onChange, value, ref } }) => (
                            <SoftSelect
                              options={[
                                { label: "None", value: "" },
                                ...getPeople(formOptions.pilots2 || [], [
                                  pilot,
                                  instructor,
                                ]),
                              ]}
                              onChange={onChange}
                              inputRef={ref}
                              value={value}
                            />
                          )}
                        />
                      </>
                    </WithPermissions>
                  )}
                </Grid>
              )}
              {(reservationType === "maintenance" ||
                reservationType === "reservation" ||
                reservationType === "pilotRefresher" ||
                reservationType === "checkout" ||
                reservationType === "checkride") && (
                <Grid item xs={12}>
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Aircraft
                    </SoftTypography>
                  </SoftBox>
                  <Controller
                    control={control}
                    name="aircraft"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftSelect
                        options={formOptions.aircrafts}
                        onChange={onChange}
                        inputRef={ref}
                        value={value}
                      />
                    )}
                  />
                  {errors?.aircraft?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.aircraft.message}
                    </SoftTypography>
                  )}
                </Grid>
              )}
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Notes
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="notes"
                  render={({ field: { onChange, value, ref } }) => (
                    <SoftInput
                      inputRef={ref}
                      value={value}
                      placeholder="Write your notes"
                      multiline
                      rows={4}
                      onChange={onChange}
                      width="100%"
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    {isEditReservation ? "Update Booking" : "Create Booking"}
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

BookingModal.defaultProps = {
  resources: [],
  pilots: [],
};

BookingModal.propTypes = {
  handleClose: PropTypes.func,
  selection: PropTypes.object,
  isEditReservation: PropTypes.bool,
  oldBookingData: PropTypes.object,
  resources: PropTypes.array,
};
