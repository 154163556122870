import { useState } from "react";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";

import { useClubs } from "src/features/club/ClubProvider";

import Card from "@mui/material/Card";

import { loadConnectAndInitialize } from "@stripe/connect-js";
import {
  ConnectPayments,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { getFunctions, httpsCallable } from "firebase/functions";

function StripePaymentsReport() {
  const { selectedClubId } = useClubs();
  const functions = getFunctions();

  const [stripeConnectInstance] = useState(() => {
    const fetchClientSecret = async () => {
      const getStripeAccountSession = httpsCallable(
        functions,
        "getStripeAccountSession"
      );
      const stripeAccountSession = await getStripeAccountSession({
        clubId: selectedClubId,
      });

      console.log(
        "stripeConnectInstance",
        stripeAccountSession?.data?.client_secret
      );

      if (stripeAccountSession?.data) {
        // document.querySelector("#error").setAttribute("hidden", "");
        return stripeAccountSession?.data?.client_secret;
      } else {
        // Handle errors on the client side here
        // console.error("An error occurred");
        // document.querySelector("#error").removeAttribute("hidden");
        return undefined;
      }
    };

    return loadConnectAndInitialize({
      // This is a placeholder - it should be replaced with your publishable API key.
      // Sign in to see your own test API key embedded in code samples.
      // Don’t submit any personally identifiable information in requests made with this key.
      publishableKey: import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY,
      fetchClientSecret: fetchClientSecret,
    });
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Card>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
              <ConnectPayments />
            </ConnectComponentsProvider>
          </SoftBox>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default StripePaymentsReport;
