import { useState } from "react";
import { PropTypes } from "prop-types";
import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

import TimelineList from "src/components/Timeline/TimelineList";
import TimelineItem from "src/components/Timeline/TimelineItem";

import { useUser } from "src/features/user/UserProvider";
import useRealtimeDocumentData from "src/features/firebase/firestore/useRealtimeDocumentData";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { doc, collection, updateDoc } from "firebase/firestore";
import { getAircraftSquawksCollection } from "src/features/aircraft/collections";
import { format } from "date-fns";
import { AddSquawkLogEntryModal } from "../AddSquawkLogEntryModal";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { usePermissions } from "src/hooks/usePermissions";

function SquawkInfo({ handleClose, selectedSquawk }) {
  const { userId, user } = useUser();
  const [showLogEntry, setShowLogEntry] = useState(false);
  const { hasAccess } = usePermissions();

  const squawkRef = doc(
    getAircraftSquawksCollection(
      selectedSquawk.clubId,
      selectedSquawk.aircraftId
    ),
    selectedSquawk.id
  );
  const { data: squawk } = useRealtimeDocumentData(squawkRef);
  const { data: squawkLogs } = useRealtimeCollectionData(
    collection(squawkRef, "logs")
  );

  const activityLog = squawkLogs
    .sort((a, b) => b.createdAt - a.createdAt)
    .filter((log) => {
      if (!log?.isInternal) return true;
      else if (
        hasAccess([systemPermissions.VIEW_INTERNAL_SQUAWK_NOTES]) &&
        log?.isInternal
      )
        return true;
    })
    .map((log) => ({
      id: log.id,
      color: log.isInternal ? "error" : "info",
      icon: log.isInternal ? "visibility_off" : "text_snippet",
      title: log?.by?.displayName,
      dateTime: format(log?.createdAt?.toDate(), "MM/dd/yyyy HH:mm a"),
      description: log?.description,
    }));

  const renderTimelineItems = activityLog.map(
    ({ color, icon, title, dateTime, description, badges, lastItem }) => (
      <TimelineItem
        key={title + color}
        color={color}
        icon={icon}
        title={title}
        dateTime={dateTime}
        description={description}
        badges={badges}
        lastItem={lastItem}
      />
    )
  );

  const resolveSquawk = async () => {
    await updateDoc(squawkRef, {
      resolved: true,
      resolvedAt: new Date(),
      resolvedBy: { id: userId, name: user.displayName },
      status: "resolved",
    });
    handleClose();
  };

  const addLogEntry = () => {
    setShowLogEntry(true);
  };

  if (showLogEntry) {
    return (
      <AddSquawkLogEntryModal
        handleClose={() => setShowLogEntry(false)}
        squawk={selectedSquawk}
      />
    );
  }

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftTypography variant="h4">Squawk Information</SoftTypography>
      <Grid container spacing={{ xs: 2 }} columns={{ xs: 12 }}>
        <Grid item xs={12}>
          <Divider sx={{ marginTop: 2, marginBottom: 0 }} />
        </Grid>

        <Grid item xs={3}>
          <SoftBox display="flex" flexDirection="column" alignItems="center">
            <SoftTypography variant="caption" fontWeight="bold" margin={0}>
              Date Created
            </SoftTypography>
            <SoftTypography
              bg="secondary"
              fontWeight="regular"
              fontSize="small"
            >
              {squawk?.date?.toDate().toDateString()}
            </SoftTypography>
          </SoftBox>
        </Grid>
        <Grid item xs={3}>
          <SoftBox display="flex" flexDirection="column" alignItems="center">
            <SoftTypography variant="caption" fontWeight="bold" margin={0}>
              Reported By
            </SoftTypography>
            <SoftTypography
              bg="secondary"
              fontWeight="regular"
              fontSize="small"
            >
              {squawk?.reporter?.displayName}
            </SoftTypography>
          </SoftBox>
        </Grid>
        <Grid item xs={3}>
          <SoftBox display="flex" flexDirection="column" alignItems="center">
            <SoftTypography variant="caption" fontWeight="bold" margin={0}>
              Status
            </SoftTypography>
            <SoftTypography
              bg="secondary"
              fontWeight="regular"
              fontSize="small"
              textTransform="capitalize"
            >
              {squawk?.status ?? "New"}
            </SoftTypography>
          </SoftBox>
        </Grid>
        <Grid item xs={3}>
          <SoftBox display="flex" flexDirection="column" alignItems="center">
            <SoftTypography variant="caption" fontWeight="bold" margin={0}>
              Grounded
            </SoftTypography>
            <SoftTypography
              bg="secondary"
              fontWeight="regular"
              fontSize="small"
            >
              {squawk?.grounded ? "Yes" : "No"}
            </SoftTypography>
          </SoftBox>
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ marginTop: 0, marginBottom: 0 }} />
        </Grid>

        <Grid item xs={12}>
          <SoftBox>
            <SoftTypography variant="caption" fontWeight="bold" margin={0}>
              Description
            </SoftTypography>
            <SoftTypography
              bg="secondary"
              fontWeight="regular"
              fontSize="small"
            >
              {squawk?.description}
            </SoftTypography>
          </SoftBox>
        </Grid>

        {activityLog.length > 0 && (
          <Grid item xs={12} bgcolor="white">
            <TimelineList maxHeight="38vh" title="Log Entries:">
              {renderTimelineItems}
            </TimelineList>
          </Grid>
        )}

        <Grid item xs={12}>
          <Divider sx={{ margin: 1 }} />
        </Grid>

        {/* <Grid item xs={12} marginY={0} display="flex" alignItems="center">
          <Search fontSize="medium" sx={{ marginRight: 1 }} />
          <SoftTypography sx={{ margin: 0 }} variant="h4">
            Inspection
          </SoftTypography>
        </Grid>
        <Grid item xs={4}>
          <InformationBite label="Name" value={selectedSquawk?.inspection?.name} />
        </Grid>
        <Grid item xs={4}>
          <InformationBite
            label="Date"
            value={selectedSquawk?.inspection?.date?.toDate().toDateString()}
          />
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ margin: 1 }} />
        </Grid>
        <Grid item xs={12} marginY={0} display="flex" alignItems="center">
          <Build fontSize="medium" sx={{ marginRight: 1 }} />
          <SoftTypography sx={{ margin: 0 }} variant="h4">
            Resolution
          </SoftTypography>
        </Grid>
        <Grid item xs={4}>
          <InformationBite label="Name" value={selectedSquawk?.resolution?.name} />
        </Grid>
        <Grid item xs={4}>
          <InformationBite
            label="Date"
            value={selectedSquawk?.resolution?.date?.toDate().toDateString()}
          />
        </Grid>
        <Grid item xs={12}>
          <InformationBite label="Description" value={selectedSquawk?.resolution?.description} />
        </Grid>

        <Grid item xs={12}>
          <Divider sx={{ margin: 1 }} />
        </Grid>
        <Grid item xs={12} marginY={0} display="flex" alignItems="center">
          <Note fontSize="medium" sx={{ marginRight: 1 }} />
          <SoftTypography sx={{ margin: 0 }} variant="h4">
            Notes
          </SoftTypography>
        </Grid>

        <Grid item xs={12}>
          {selectedSquawk?.notes?.map((note) => (
            <SoftBox>
              <Grid container spacing={{ xs: 2 }} columns={{ xs: 12 }}>
                <Grid item xs={4}>
                  <InformationBite label="Name" value={note.name} />
                </Grid>
                <Grid item xs={4}>
                  <InformationBite label="Date" value={note.date?.toDate().toDateString()} />
                </Grid>
                <Grid item xs={12}>
                  <InformationBite label="Note" value={note.message} />
                </Grid>
              </Grid>
            </SoftBox>
          ))}
        </Grid> */}
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <SoftBox mb={1}>
                <SoftButton
                  variant="outlined"
                  color="dark"
                  fullWidth
                  onClick={handleClose}
                >
                  Cancel
                </SoftButton>
              </SoftBox>
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={3}>
              {hasAccess([systemPermissions.ADD_MX_SQUAWK_LOG]) && (
                <SoftBox mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="info"
                    fullWidth
                    onClick={addLogEntry}
                  >
                    Log Entry
                  </SoftButton>
                </SoftBox>
              )}
            </Grid>
            <Grid item xs={3}>
              {hasAccess([systemPermissions.RESOLVE_MX_SQUAWKS]) && (
                <SoftBox mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    disabled={squawk?.status === "resolved"}
                    onClick={resolveSquawk}
                  >
                    Resolve
                  </SoftButton>
                </SoftBox>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalContainer>
  );
}

SquawkInfo.propTypes = {
  handleClose: PropTypes.func.isRequired,
  selectedSquawk: PropTypes.object,
};

export default SquawkInfo;
