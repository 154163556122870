// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Checkbox from "@mui/material/Checkbox";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Hidden from "@mui/material/Hidden";

function TailNumberCell({ image, name, checked }) {
  return (
    <SoftBox display="flex" alignItems="center">
      <Checkbox defaultChecked={checked} />
      <Hidden smDown>
        <SoftBox mx={2} width="5.75rem">
          <SoftBox
            component="img"
            src={image}
            alt={name}
            width="100%"
            height="100%"
          />
        </SoftBox>
      </Hidden>
      <SoftTypography variant="p" color="primary" fontWeight="bold">
        {name}
      </SoftTypography>
    </SoftBox>
  );
}

// Setting default value for the props of ProductCell
TailNumberCell.defaultProps = {
  checked: false,
};

// Typechecking props for the ProductCell
TailNumberCell.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
};

export default TailNumberCell;
