// react-quill components
import ReactQuill, { Quill } from "react-quill";

// react-quill styles
import "react-quill/dist/quill.snow.css";

// Custom styles for the SoftEditor
import SoftEditorRoot from "src/components/SoftEditor/SoftEditorRoot";

import ImageResize from "quill-image-resize-module-react";

Quill.register("modules/imageResize", ImageResize);

function SoftEditor(props) {
  return (
    <SoftEditorRoot>
      <ReactQuill theme="snow" {...props} />
    </SoftEditorRoot>
  );
}

export default SoftEditor;
