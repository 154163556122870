import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Billing page components
import BaseLayout from "src/containers/LayoutContainers/BaseLayout";
import Invoices from "./components/Invoices";
import Memberships from "./components/Memberships";
import Transactions from "./components/Transactions";
import UserInfo from "./components/UserInfo";
import AccountBalance from "./components/AccountBalance";
import PendingPayments from "./components/PendingPayments";

import { useClubs } from "src/features/club/ClubProvider";
import {
  getUserWithId,
  getUserPermissionsWithId,
} from "src/features/user/utils";
import PaymentMethod from "./components/PaymentMethod";

function AccountDetails() {
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get("accountId");
  const { userId } = useParams();
  const { selectedLocationId, clubAccounts } = useClubs();

  const [user, setUser] = useState(null);
  const [userPermissions, setUserPermissions] = useState(null);

  const getUser = async () => {
    const userDoc = await getUserWithId(userId);
    const userPermissionsDoc = await getUserPermissionsWithId(userId);
    setUser(userDoc.data());
    const perms = userPermissionsDoc?.docs?.filter(
      (doc) => doc.data().locationId === selectedLocationId
    );
    if (perms?.length > 0) {
      setUserPermissions(perms[0].data());
    }

    // const clubAccountBalancesDoc = await getClubAccountBalancesWithId(
    //   userId,
    //   selectedClubId
    // );
    // setClubAccountBalances(clubAccountBalancesDoc.data());
  };

  useEffect(() => {
    getUser();
  }, [userId, selectedLocationId]);

  if (!user || !userId) return null;

  return (
    <BaseLayout stickyNavbar pageTitle={user?.displayName}>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        pl={3}
        pr={3}
        pt={3}
      >
        <SoftBox lineHeight={1}>
          <SoftTypography variant="h5" fontWeight="medium">
            {accountId
              ? `${
                  clubAccounts.find((account) => account.id === accountId)?.name
                } Account`
              : "Standard Account"}
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox mt={4}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <SoftBox mb={3}>
                <Transactions userId={userId} accountId={accountId} />
              </SoftBox>
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid container spacing={3}>
                {/* <Grid item xs={12} md={6} xl={5}>
                  <DefaultInfoCard
                    icon="account_balance"
                    title="Upcoming"
                    description="Scheduled Income/Expenses"
                    value="+$2000"
                  />
                </Grid>
                <Grid item xs={12} md={6} xl={7}>
                  <ThinBarChart title="Monthly Income" height="9.8rem" chart={thinBarChartData} />
                </Grid> */}
                <Grid item xs={12}>
                  <UserInfo user={user} userPermissions={userPermissions} />
                </Grid>
                <Grid item xs={12}>
                  <AccountBalance userId={userId} accountId={accountId} />
                </Grid>
                <Grid item xs={12}>
                  <Memberships userId={userId} />
                </Grid>
                <Grid item xs={12}>
                  <PendingPayments userId={userId} />
                </Grid>
                <Grid item xs={12}>
                  <PaymentMethod user={user} />
                </Grid>
                <Grid item xs={12}>
                  <Invoices userId={userId} accountId={accountId} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </BaseLayout>
  );
}

export default AccountDetails;
