import { forwardRef } from "react";
import format from "date-fns/format";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// custom styles for the NotificationItem
import {
  menuItem,
  menuImage,
} from "src/containers/NotificationItems/BookingNotification/styles";

const BookingNotification = forwardRef(
  ({ color, data, date, read, ...rest }, ref) => (
    <MenuItem
      {...rest}
      ref={ref}
      className={read ? null : "unread"}
      sx={(theme) => menuItem(theme)}
    >
      {data.image ? (
        <SoftBox
          width="3rem"
          height="3rem"
          mt={0.25}
          mr={2}
          mb={0.25}
          borderRadius="lg"
          sx={(theme) =>
            menuImage(theme, {
              color,
            })
          }
        >
          {data.image}
        </SoftBox>
      ) : (
        <SoftBox
          width="3rem"
          height="3rem"
          mt={0.25}
          mr={2}
          mb={0.25}
          borderRadius="lg"
          color={read ? "primary" : "white"}
          sx={(theme) =>
            menuImage(theme, {
              color,
            })
          }
        >
          <Icon fontSize="30px" color={read ? "primary" : "white"}>
            {data.icon}
          </Icon>
        </SoftBox>
      )}
      <SoftBox sx={{ width: "100%" }}>
        <SoftBox>
          <SoftTypography
            variant="button"
            color={read ? "primary" : "white"}
            fontWeight="regular"
            sx={{ whiteSpace: "normal" }}
          >
            <strong>{data.title}</strong>
          </SoftTypography>
        </SoftBox>
        <SoftTypography
          variant="button"
          fontWeight="regular"
          sx={{ whiteSpace: "normal" }}
        >
          {data.description}
        </SoftTypography>
        {data.start && (
          <SoftBox display="inline-block" mt={1}>
            <SoftBox
              width="1.3rem"
              height="1.3rem"
              borderRadius="50%"
              position="relative"
              display="inline-block"
              top="2px"
              zIndex={2}
            >
              <Icon>flight_takeoff</Icon>
            </SoftBox>
            <SoftTypography
              variant="caption"
              fontWeight="regular"
              sx={{ whiteSpace: "normal" }}
            >
              {format(data.start.toDate(), "MM/dd/yyyy @ HH:mm")}
            </SoftTypography>
          </SoftBox>
        )}
        {data.end && (
          <SoftBox display="inline-block" ml={3} mt={1}>
            <SoftBox
              width="1.3rem"
              height="1.3rem"
              borderRadius="50%"
              position="relative"
              display="inline-block"
              top="2px"
              zIndex={2}
            >
              <Icon>flight_land</Icon>
            </SoftBox>
            <SoftTypography
              variant="caption"
              fontWeight="regular"
              sx={{ whiteSpace: "normal" }}
            >
              {format(data.end.toDate(), "MM/dd/yyyy @ HH:mm")}
            </SoftTypography>
          </SoftBox>
        )}
        <SoftTypography
          variant="caption"
          color="secondary"
          sx={{
            display: "flex",
            alignItems: "center",
            mt: 0.5,
            width: "100%",
          }}
        >
          <SoftTypography variant="button" color="secondary">
            <Icon
              sx={{
                lineHeight: 1.2,
                mr: 0.5,
              }}
            >
              watch_later
            </Icon>
          </SoftTypography>
          {date}
        </SoftTypography>
      </SoftBox>
    </MenuItem>
  )
);

// Setting default values for the props of NotificationItem
BookingNotification.defaultProps = {
  color: "dark",
  read: false,
};

// Typechecking props for the NotificationItem
BookingNotification.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  data: PropTypes.object.isRequired,
  read: PropTypes.bool,
  date: PropTypes.string.isRequired,
};

export default BookingNotification;
