// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftTypography from "src/components/SoftTypography";

import CurrencyFormat from "react-currency-format";

function CurrencyCell({ value, suffix }) {
  return (
    <SoftTypography
      variant="caption"
      textTransform="capitalize"
      fontWeight="medium"
      color="text"
    >
      <CurrencyFormat
        value={value}
        displayType="text"
        decimalScale={2}
        fixedDecimalScale
        thousandSeparator
        prefix="$"
      />
      {suffix && (
        <SoftTypography variant="caption" fontWeight="medium" color="secondary">
          &nbsp;&nbsp;{suffix}
        </SoftTypography>
      )}
    </SoftTypography>
  );
}

// Setting default values for the props of DefaultCell
CurrencyCell.defaultProps = {
  suffix: "",
};

// Typechecking props for the DefaultCell
CurrencyCell.propTypes = {
  value: PropTypes.number.isRequired,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default CurrencyCell;
