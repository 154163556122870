import { query, where } from 'firebase/firestore';
import { useEffect, useMemo, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';

function useRealtimeCollectionData(collectionRef, fetchDeleted) {
  const [isDataLoaded, setDataIsLoaded] = useState(false);
  const [data, setData] = useState([]);

  const memoizedQuery = useMemo(() => {
    let q = collectionRef;

    if (!fetchDeleted) {
      q = query(collectionRef, where('deleted', '==', false));
    }

    return q;
  }, [collectionRef]);

  const [snapshot] = useCollection(memoizedQuery, {
    snapshotListenOptions: {
      includeMetadataChanges: false,
    },
  });

  const getCollectionData = () => {
    if (!snapshot) return [];
    return snapshot?.docs.map((cDoc, ind) => ({
      id: cDoc.id,
      index: cDoc.index || ind,
      ...cDoc.data(),
    }));
  };

  const loadData = async () => {
    const newData = getCollectionData();
    setData(newData);
    setDataIsLoaded(true);
  };

  useEffect(() => {
    loadData();
  }, [JSON.stringify(getCollectionData())]);

  return {
    data,
    isDataLoaded,
    getCollectionData,
  };
}

export default useRealtimeCollectionData;
