import { useState } from "react";
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftAvatar from "src/components/SoftAvatar";
import CommentCard from "src/components/CommentCard";
import SoftInput from "src/components/SoftInput";
import SoftTypography from "src/components/SoftTypography";
import { useUser } from "src/features/user/UserProvider";
import SendIcon from "@mui/icons-material/Send";

function CommentSection({ comments, onNewComment }) {
  const { user } = useUser();
  const [newComment, setNewComment] = useState("");

  const submitComment = () => {
    setNewComment("");
    if (newComment?.trim()) onNewComment(newComment);
  };

  const handleKeyUp = (event) => {
    if (event.key === "Enter") {
      submitComment();
    }
  };

  const handleCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const newCommentSection = (
    <SoftBox display="flex" alignItems="center">
      <SoftBox display="flex" flexShrink={0} alignItems="flex-start">
        <SoftAvatar
          src={user?.photoURL}
          shadow="xs"
          bgColor="light"
          size="md"
        />
      </SoftBox>
      <SoftBox flexGrow={1} ml={2}>
        <SoftBox display="flex" alignItems="center" mb={1}>
          <SoftInput
            value={newComment}
            placeholder="Write your comment"
            multiline
            onKeyUp={handleKeyUp}
            onChange={handleCommentChange}
          />
          <SoftBox ml={2}>
            <SoftButton
              variant="outlined"
              color="info"
              iconOnly
              onClick={submitComment}
            >
              <SendIcon
                sx={{
                  fontWeight: "bold",
                }}
              />
            </SoftButton>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );

  if (!comments) {
    return (
      <>
        <SoftBox
          display="flex"
          alignItems="center"
          justifyContent="center"
          pb={2}
        >
          <SoftTypography variant="caption" color="dark">
            There are no comments.
          </SoftTypography>
        </SoftBox>
        {newCommentSection}
      </>
    );
  }

  return (
    <>
      <SoftBox pb={2}>
        {comments
          .sort((a, b) => a.date - b.date)
          .map((comment, index) => (
            <SoftBox
              key={comment}
              mt={index === 0 ? 0 : 2}
              borderRadius="md"
              px={2}
            >
              <CommentCard {...comment} />
            </SoftBox>
          ))}
      </SoftBox>
      {newCommentSection}
    </>
  );
}

export default CommentSection;
