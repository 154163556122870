import * as toastr from "toastr";
import { useState } from "react";
import { getFunctions, httpsCallable } from "firebase/functions";
import axios from "axios";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";

// Wizard page components
import KycWizard from "src/components/KycWizard";

import { useClubs } from "src/features/club/ClubProvider";
import { useNavigate } from "react-router-dom";

function ClubSetupWizard() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { selectedClubId, selectedClub } = useClubs();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const connectionData = await axios
      .get("https://geolocation-db.com/json/")
      .catch((error) => {
        console.error("Error getting connection data:", error);
        toastr.error(
          "There was an error submitting your IP data, please ensure you are not on a VPN.",
          "Error"
        );
        setIsSubmitting(false);
      });
    try {
      const functions = getFunctions();
      const addStripeAccountDetails = httpsCallable(
        functions,
        "addStripeAccountDetails"
      );
      addStripeAccountDetails({
        stripeAccountId: selectedClub.stripeAccountId,
        clubId: selectedClubId,
        ...data,
        owners_provided: true,
        connectionData: connectionData.data,
      })
        .then((addAccountDetailsResult) => {
          console.debug("Add Account Result:", addAccountDetailsResult);
          navigate("/dashboard");
          setIsSubmitting(false);
        })
        .catch((error) => {
          setIsSubmitting(false);
          console.error("Error adding account details:", error);
          toastr.error(
            error.toString().replace("Firebase", "") ||
              "There was an error submitting your data, please go back and check your information.",
            "Error"
          );
        });
    } catch (error) {
      setIsSubmitting(false);
      console.error("EEEERRRRROOORRR", error);
      toastr.error(
        "There was an error submitting your data, please go back and check your information.",
        "Error"
      );
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox pt={3} pb={8}>
        <Grid container justifyContent="center">
          <Grid item xs={12} md={10}>
            <SoftBox mt={6} mb={1} textAlign="center">
              <SoftBox mb={1}>
                <SoftTypography variant="h3" fontWeight="bold">
                  Finish setting up your club...
                </SoftTypography>
              </SoftBox>
              <SoftTypography
                variant="h5"
                fontWeight="regular"
                color="secondary"
              >
                This information is required in order to receive any funds paid
                to your club.
              </SoftTypography>
            </SoftBox>

            <KycWizard onSubmit={onSubmit} isSubmitting={isSubmitting} />
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ClubSetupWizard;
