import { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";

// @mui core components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { getClubMembershipsCollection } from "src/features/club/collections";

import { getTableColumns, TableActions } from "./tableColumns";
import DataTable from "src/components/Tables/DataTable";

import { AddCancellationReasonModal } from "src/modals/AddCancellationReasonModal/AddCancellationReasonModal";

function CancellationReasons() {
  const { deleteData } = entityCrudUtils();
  const {
    selectedClubId,
    selectedClub,
  }: { selectedClubId: string; selectedClub: any } = useClubs();
  const [selectedReason, setSelectedReason] = useState<any>(null);
  const [
    openAddNewCancellationReasonModal,
    setOpenAddNewCancellationReasonModal,
  ] = useState(false);

  const [reasons, setReasons] = useState([]);

  useEffect(() => {
    setReasons(selectedClub?.preferences?.cancellationReasons ?? []);
  }, [selectedClub]);

  const deleteReason = (reason: any) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button",
        confirmButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You will not be able to recover this",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        icon: "warning",
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteData({
            entity: getClubMembershipsCollection(selectedClubId),
            pathSegmentsArr: [reason.id],
          });
        }
      });
  };

  const handleTableActions = (action: any, reason: any) => {
    switch (action) {
      case TableActions.DELETE:
        deleteReason(reason);
        break;
      case TableActions.EDIT:
        setSelectedReason(reason);
        setOpenAddNewCancellationReasonModal(true);
        break;

      default:
        break;
    }
  };

  const handleAddReasonModalClose = () => {
    setOpenAddNewCancellationReasonModal(false);
    setSelectedReason(null);
  };

  const tableColumns = useMemo(() => getTableColumns(handleTableActions), []);

  return (
    <Card
      id="cancellation-reasons"
      sx={{
        overflow: "visible",
      }}
    >
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Cancellation Reasons</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Define the options pilots can see when cancelling reservations.
        </SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <SoftBox
          padding={1}
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          <DataTable
            selectedRowId={false}
            canDrag={false}
            showTotalEntries={false}
            isSorted={false}
            canSearch={false}
            entriesPerPage={false}
            table={{
              columns: tableColumns,
              rows: reasons,
            }}
          />
        </SoftBox>
        <Grid container spacing={3} mt={0}>
          <Grid item xs={12} md={12} justifyContent="flex-end" display="flex">
            <SoftButton
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              onClick={() => setOpenAddNewCancellationReasonModal(true)}
            >
              Add New Reason
            </SoftButton>
          </Grid>
        </Grid>
      </SoftBox>
      <Modal
        open={openAddNewCancellationReasonModal}
        onClose={handleAddReasonModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddCancellationReasonModal
            handleClose={handleAddReasonModalClose}
            reason={selectedReason}
          />
        </SoftBox>
      </Modal>
    </Card>
  );
}

export default CancellationReasons;
