import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for SoftBox
import SoftBoxRoot, {
  OwnerStateProps,
} from "src/components/SoftBox/SoftBoxRoot";
import { BoxTypeMap } from "@mui/material/Box";

type SoftBoxProps = BoxTypeMap["props"] &
  Partial<OwnerStateProps> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const SoftBox = forwardRef<BoxTypeMap["defaultComponent"], SoftBoxProps>(
  (
    {
      variant = "contained",
      bgColor = "transparent",
      color = "dark",
      opacity = 1,
      borderRadius = "none",
      shadow = "none",
      ...rest
    },
    ref
  ) => (
    <SoftBoxRoot
      {...rest}
      ref={ref}
      ownerState={{
        variant,
        bgColor,
        color,
        opacity,
        borderRadius,
        shadow,
      }}
    />
  )
);

// Setting default values for the props of SoftBox
SoftBox.defaultProps = {
  variant: "contained",
  bgColor: "transparent",
  color: "dark",
  opacity: 1,
  borderRadius: "none",
  shadow: "none",
};

const variantPropType = PropTypes.oneOf([
  "contained",
  "gradient",
]) as PropTypes.Validator<"contained" | "gradient">;
const bgColorPropType = PropTypes.string as PropTypes.Validator<string>;
const colorPropType = PropTypes.string as PropTypes.Validator<string>;
const opacityPropType = PropTypes.number as PropTypes.Validator<number>;
const borderRadiusPropType = PropTypes.string as PropTypes.Validator<string>;
const shadowPropType = PropTypes.string as PropTypes.Validator<string>;

// Typechecking props for the SoftBox
SoftBox.propTypes = {
  variant: variantPropType,
  bgColor: bgColorPropType,
  color: colorPropType,
  opacity: opacityPropType,
  borderRadius: borderRadiusPropType,
  shadow: shadowPropType,
};

SoftBox.displayName = "SoftBox";

export default SoftBox;
