import { Timestamp } from "firebase/firestore";

export interface IDocumentType {
  value: string;
  label: string;
}

export interface IDocument {
  id: string;
  createdAt: Timestamp;
  fileType: string;
  deleted: boolean;
  storagePath: string;
  documentType: string;
  url: string;
  clubId: string;
  fileName: string;
  documentTypeLabel: string;
}

export const documentTypeUploadModal: IDocumentType[] = [
  { label: "POH", value: "poh" },
  { label: "W&B", value: "weightAndBalance" },
  { label: "Other", value: "" },
];
