// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { Card, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function ModalContainer({
  handleClose,
  width = 800,
  children,
  overflow = "scroll",
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width,
    maxWidth: "100vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "15px",
    overflow: overflow,
    boxShadow: 24,
    maxHeight: "90vh",
    p: { xs: 1, sm: 4 },
    py: { xs: 3, sm: 4 },
  };

  return (
    <Card sx={style}>
      <IconButton
        aria-label="delete"
        className="modal-close-btn"
        onClick={handleClose}
        size="large"
      >
        <CloseIcon fontSize="inherit" />
      </IconButton>
      {children}
    </Card>
  );
}

// Setting default values for the props of BaseLayout
ModalContainer.defaultProps = {};

// Typechecking props for BaseLayout
ModalContainer.propTypes = {
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  overflow: PropTypes.string,
  width: PropTypes.number || PropTypes.string,
};

export default ModalContainer;
