import { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";

// @mui core components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";

import Swal from "sweetalert2";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

import { useClubs } from "src/features/club/ClubProvider";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { doc, updateDoc } from "firebase/firestore";

import {
  getTableColumns,
  getTableColumnsStandard,
  TableActions,
} from "./tableColumns";
import DataTable from "src/components/Tables/DataTable";

import { AddCustomRate } from "src/modals/AddCustomRate";
import { getClubCustomRatesCollection } from "src/features/club/collections";

function RentalRates({ qbPrefs, qbItems }) {
  const { selectedClubId } = useClubs();
  const [defaultRates, setDefaultRates] = useState([]);
  const [rentalRates, setRentalRates] = useState([]);
  const [openAddRentalRate, setOpenAddRentalRate] = useState(false);
  const [selectedRate, setSelectedRate] = useState();
  const [classes, setClasses] = useState({});
  const { data: clubRates } = useRealtimeCollectionData(
    getClubCustomRatesCollection(selectedClubId),
    false
  );

  useEffect(() => {
    if (clubRates) {
      setRentalRates(
        clubRates.filter(
          (rate) => rate.id !== "standard" && rate.id !== "owners"
        )
      );
      setDefaultRates(
        clubRates.filter(
          (rate) => rate.id === "standard" || rate.id === "owners"
        )
      );

      clubRates.forEach((rate) => {
        if (rate.qboClass) {
          setClasses({ ...classes, [rate.id]: rate.qboClass });
        }
      });
    }
  }, [clubRates]);

  const deleteRate = (rate) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button",
        confirmButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "All existing uses of this rate will default to the standard rate.",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        icon: "warning",
      })
      .then((result) => {
        if (result.isConfirmed) {
          updateDoc(
            doc(getClubCustomRatesCollection(selectedClubId), rate.id),
            {
              deletedAt: new Date(),
              deleted: true,
            }
          );
        }
      });
  };

  const updateRate = (rate, updates) => {
    updateDoc(
      doc(getClubCustomRatesCollection(selectedClubId), rate.id),
      updates
    );
  };

  const handleTableActions = (action, rate, updates) => {
    switch (action) {
      case TableActions.UPDATE:
        updateRate(rate, { ...updates, updatedAt: new Date() });
        break;

      case TableActions.DELETE:
        deleteRate(rate);
        break;

      case TableActions.EDIT:
        setSelectedRate(rate);
        setOpenAddRentalRate(true);
        break;

      default:
        break;
    }
  };

  const handleAddRentalRateModalClose = () => {
    setOpenAddRentalRate(false);
  };

  const tableColumns = useMemo(
    () =>
      getTableColumns(
        handleTableActions,
        qbItems,
        qbPrefs?.AccountingInfoPrefs?.ClassTrackingPerTxnLine ||
          qbPrefs?.AccountingInfoPrefs?.ClassTrackingPerTxn,
        classes
      ),
    [qbItems, qbPrefs]
  );
  const tableColumnsStandard = useMemo(
    () =>
      getTableColumnsStandard(
        handleTableActions,
        qbItems,
        qbPrefs?.AccountingInfoPrefs?.ClassTrackingPerTxnLine ||
          qbPrefs?.AccountingInfoPrefs?.ClassTrackingPerTxn
      ),
    [qbItems, qbPrefs]
  );

  return (
    <Card
      id="rental-rates"
      sx={{
        overflow: "visible",
      }}
    >
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Rental Rates</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Manage different rental rates that can be applied to
          bookings/aircraft.
          {qbItems?.length > 0 &&
            " Quickbooks items set here will override the default item set in the Quickbooks integration settings."}
        </SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <SoftBox
          xs={12}
          padding={1}
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          <DataTable
            entriesPerPage={false}
            maxWidth="100%"
            table={{
              columns: tableColumnsStandard,
              rows: defaultRates,
            }}
          />
        </SoftBox>
        <SoftBox
          xs={12}
          padding={1}
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          {rentalRates && rentalRates.length > 0 && (
            <DataTable
              entriesPerPage={false}
              maxWidth="100%"
              table={{
                columns: tableColumns,
                rows: rentalRates,
              }}
            />
          )}
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} justifyContent="flex-end" display="flex">
            <SoftButton
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              onClick={() => setOpenAddRentalRate(true)}
            >
              Add Custom Rate
            </SoftButton>
          </Grid>
        </Grid>
      </SoftBox>
      <Modal
        open={openAddRentalRate}
        onClose={handleAddRentalRateModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddCustomRate
            handleClose={handleAddRentalRateModalClose}
            rate={selectedRate}
          />
        </SoftBox>
      </Modal>
    </Card>
  );
}

RentalRates.defaultProps = {
  qbPrefs: {},
  qbItems: [],
};

RentalRates.propTypes = {
  qbPrefs: PropTypes.object,
  qbItems: PropTypes.array,
};

export default RentalRates;
