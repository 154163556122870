import { getDoc, getDocs, query, where } from "firebase/firestore";
// import { standardDocumentTypes } from "src/config/localDocuments";
import { useClubs } from "src/features/club/ClubProvider";
import {
  getUserDocumentsCollection,
  getUserPermissionsDocByLocation,
} from "src/features/user/collections";
import { IUseClub } from "src/interfaces";
import { IRole } from "src/interfaces/roles/role.interface";

// const localDocIds = standardDocumentTypes.map((d) => d.id);
const userDocsSet = new Set();

export const useUserRolesDocuments = () => {
  const { clubUserRoles, selectedLocationId } = useClubs() as IUseClub;

  const getUserRolesDocuments = async (userId: string) => {
    const [querySnapshot, permissions] = await Promise.all([
      getDocs(
        query(getUserDocumentsCollection(userId), where("deleted", "==", false))
      ),
      getDoc(getUserPermissionsDocByLocation(userId, selectedLocationId)),
    ]);
    const userDocs = new Set(
      querySnapshot.docs?.map((d) => {
        const docData = d.data();
        // This is the required doc id
        return docData.documentType;
      })
    );

    const userRoles = permissions.data()?.userRoles ?? [];
    userRoles.forEach((roleId: string) => {
      const role = clubUserRoles.find((r) => r.id === roleId);

      if ((role?.configuration?.requiredDocuments ?? []).length > 0)
        role?.configuration?.requiredDocuments?.forEach((d) => {
          return d && userDocsSet.add(d);
        });
    });

    const parsedUserRoles: IRole[] = [];
    userRoles.forEach((r: string) => {
      const role = clubUserRoles.find((cr) => cr.id === r);
      if (role) {
        parsedUserRoles.push(role);
      }
    });

    return {
      parsedUserRoles,
      userRoles,
      userDocs,
      hasAllDocs: Array.from(userDocsSet).every((d) => userDocs.has(d)),
      missingDocs: Array.from(userDocsSet).filter((d) => {
        return !userDocs.has(d);
      }),
    };
  };

  return {
    getUserRolesDocuments,
  };
};
