import { useEffect, useState } from "react";
import { Card } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import SoftInput from "src/components/SoftInput";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import SoftSelect from "src/components/SoftSelect";
import SoftDatePicker from "src/components/SoftDatePicker";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { useUser } from "src/features/user/UserProvider";
import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { add } from "date-fns";

import PersonIcon from "@mui/icons-material/Person";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";

import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { getAircraftCollection } from "src/features/aircraft/collections";
import { getUserCheckoutsCollection } from "src/features/user/collections";
import ModalContainer from "src/components/ModalContainer";

const schema = yup
  .object({
    aircraft: yup.object().required("Please select an aircraft"),
    date: yup.date().required("Please provide the date given"),
    doesExpire: yup.boolean(),
    expires: yup.lazy((value) =>
      typeof value === "number"
        ? yup.number().when("doesExpire", {
            is: true,
            then: (elementSchema) =>
              elementSchema
                .typeError("Amount must be a number")
                .required("An expiration is required."),
            otherwise: (elementSchema) => elementSchema.notRequired(),
          })
        : yup.string().when("doesExpire", {
            is: true,
            then: (elementSchema) =>
              elementSchema
                .typeError("Amount must be a number")
                .required("An expiration is required."),
            otherwise: (elementSchema) => elementSchema.notRequired(),
          })
    ),
    expiresType: yup.object().when("doesExpire", {
      is: true,
      then: (elementSchema) =>
        elementSchema.required("An expiration type is required."),
      otherwise: (elementSchema) => elementSchema.notRequired(),
    }),
  })
  .required();

const expiresTypeOptions = [
  { label: "Years", value: "years" },
  { label: "Months", value: "months" },
  { label: "Days", value: "days" },
  { label: "Hours", value: "hours" },
];

export function AddCheckoutModal({ handleClose, selectedMember }) {
  const { user, userId } = useUser();
  const { selectedClubId, selectedLocationId } = useClubs();
  const [aircraftOptions, setAircraftOptions] = useState([]);
  const { updateData } = entityCrudUtils();
  const [doesExpire, setDoesExpire] = useState(true);
  const handleSetDoesExpire = () => setDoesExpire(!doesExpire);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm({
    defaultValues: {
      doesExpire: true,
      expiresType: { label: "Days", value: "days" },
      date: new Date(),
    },
    resolver: yupResolver(schema),
  });

  const selectedAircraft = watch("aircraft");

  const clubAircraftCollectionRef = getAircraftCollection(selectedClubId);
  const { data: aircrafts } = useRealtimeCollectionData(
    clubAircraftCollectionRef,
    false
  );

  useEffect(() => {
    if (aircrafts) {
      const options = aircrafts.map((aircraft) => ({
        label: `${aircraft.tailNumber} - ${aircraft.displayName}`,
        value: aircraft,
      }));
      setAircraftOptions(options);
    }
  }, [aircrafts]);

  const onSubmit = async (data) => {
    if (data.expiresType) data.expiresType = data.expiresType.value;

    const newCheckout = {
      instructor: {
        id: userId,
        displayName: user.displayName,
      },
      pilot: {
        id: selectedMember.uid,
        displayName: selectedMember.displayName,
      },
      date: data.date,
      description: data.aircraft.value.displayName,
      tailNumber:
        data.aircraft.value.aircraft_description?.tail_number ??
        data.aircraft.value.tailNumber,
      aircraftId: data.aircraft.value.id,
      locationId: selectedLocationId,
      clubId: selectedClubId,
      deleted: false,
    };
    if (doesExpire) {
      newCheckout.expires = add(data.date, {
        [data.expiresType]: data.expires,
      });
    }

    await updateData(
      {
        entity: getUserCheckoutsCollection(selectedMember.uid),
        pathSegmentsArr: [data.aircraft.value.id],
      },
      newCheckout
    );
    handleClose();
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            Add Aircraft Checkout
          </SoftTypography>
          <SoftBox mt={3}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <SoftBox>
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <SoftBox
                      display="grid"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="lg"
                      mb={1}
                    >
                      <PersonIcon
                        sx={{
                          display: "grid",
                          placeItems: "center",
                        }}
                        color="dark"
                        fontSize="large"
                      />
                    </SoftBox>
                    <SoftBox display="flex" flexDirection="column">
                      <SoftTypography
                        variant="button"
                        color="dark"
                        fontWeight="medium"
                        textAlign="center"
                        gutterBottom
                      >
                        Member
                      </SoftTypography>
                      <SoftTypography
                        variant="caption"
                        textAlign="center"
                        color="text"
                      >
                        {selectedMember.displayName}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Grid>
              <Grid item xs={4}>
                <SoftBox>
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <SoftBox
                      display="grid"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="lg"
                      mb={1}
                    >
                      <SupervisorAccountIcon
                        sx={{
                          display: "grid",
                          placeItems: "center",
                        }}
                        color="dark"
                        fontSize="large"
                      />
                    </SoftBox>
                    <SoftBox display="flex" flexDirection="column">
                      <SoftTypography
                        variant="button"
                        color="dark"
                        fontWeight="medium"
                        textAlign="center"
                        gutterBottom
                      >
                        Instructor
                      </SoftTypography>
                      <SoftTypography
                        variant="caption"
                        textAlign="center"
                        color="text"
                      >
                        {user.displayName}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Grid>
              <Grid item xs={4}>
                <SoftBox>
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <SoftBox
                      display="grid"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="lg"
                      mb={1}
                    >
                      <AirplanemodeActiveIcon
                        sx={{
                          display: "grid",
                          placeItems: "center",
                        }}
                        color="dark"
                        fontSize="large"
                      />
                    </SoftBox>
                    <SoftBox display="flex" flexDirection="column">
                      <SoftTypography
                        variant="button"
                        color="dark"
                        fontWeight="medium"
                        textAlign="center"
                        gutterBottom
                      >
                        Aircraft
                      </SoftTypography>
                      <SoftTypography
                        variant="caption"
                        textAlign="center"
                        color="text"
                      >
                        {(selectedAircraft?.value?.aircraft_description
                          ?.tail_number ??
                          selectedAircraft?.value?.tailNumber) ||
                          "-"}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Aircraft
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="aircraft"
                  render={({ field: { onChange, value, ref } }) => (
                    <SoftSelect
                      options={aircraftOptions}
                      onChange={onChange}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
                {errors?.aircraft?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.aircraft.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={6}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Date Given
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="date"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <SoftDatePicker
                      dateFormat="P"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                    />
                  )}
                />
                {errors?.date?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.date.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={2}>
                <SoftBox ml={1} pt={4.5} display="inline-block">
                  <Checkbox
                    checked={doesExpire}
                    onClick={handleSetDoesExpire}
                    {...register("doesExpire", {
                      required: false,
                    })}
                  />
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    onClick={handleSetDoesExpire}
                    sx={{
                      cursor: "pointer",
                      userSelect: "none",
                    }}
                  >
                    Expires?
                  </SoftTypography>
                </SoftBox>
              </Grid>
              {doesExpire ? (
                <>
                  <Grid item xs={12} sm={2}>
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    >
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Expires After
                      </SoftTypography>
                    </SoftBox>
                    <SoftInput
                      type="number"
                      onWheel={(event) => event.target.blur()}
                      {...register("expires", {
                        required: true,
                      })}
                    />
                    {errors?.expires?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.expires.message}
                      </SoftTypography>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <SoftBox
                      mb={1}
                      ml={0.5}
                      lineHeight={0}
                      display="inline-block"
                    />
                    <Controller
                      control={control}
                      name="expiresType"
                      render={({ field: { onChange, value, ref } }) => (
                        <SoftSelect
                          options={expiresTypeOptions}
                          onChange={onChange}
                          inputRef={ref}
                          value={value}
                        />
                      )}
                    />
                    {errors?.expiresType?.message && (
                      <SoftTypography marginTop={1} fontSize={12} color="error">
                        {errors.expiresType.message}
                      </SoftTypography>
                    )}
                  </Grid>
                </>
              ) : null}
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    Add Checkout
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

AddCheckoutModal.propTypes = {
  handleClose: PropTypes.func,
  selectedMember: PropTypes.object.isRequired,
};
