import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftDateRangePicker from "src/components/SoftDateRangePicker";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

import DefaultCell from "src/pages/reports/components/DefaultTableCell";

import { useClubs } from "src/features/club/ClubProvider";
import { formatCurrency } from "src/features/utils";
import {
  format,
  startOfMonth,
  endOfMonth,
  subMonths,
  startOfDay,
  endOfDay,
} from "date-fns";
import { query, orderBy, where } from "firebase/firestore";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import {
  getAircraftCollection,
  getSimulatorsCollection,
} from "src/features/aircraft/collections";
import { getClubTransactionsCollection } from "src/features/transaction/collection";
import { useCollection } from "react-firebase-hooks/firestore";

const columns = [
  {
    Header: "Tail #",
    accessor: "tailNumber",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Aircraft",
    accessor: "displayName",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Hobbs",
    accessor: "hobbs",
    Cell: ({ value }) => (
      <DefaultCell textTransform="uppercase" value={value || "---"} />
    ),
  },
  {
    Header: "Tach",
    accessor: "tach1",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Total Hobbs Hours",
    accessor: "totalHobbsHours",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Total Tach Hours",
    accessor: "totalTachHours",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Oil Added",
    accessor: "oilAdded",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Total Billed",
    accessor: "totalRevenue",
    Cell: ({ value }) => <DefaultCell value={formatCurrency(value) || "---"} />,
  },
  {
    Header: "Last Flight",
    accessor: "lastFlight",
    Cell: ({ value }) => (
      <DefaultCell value={value ? format(value, "eee MMM do yyyy") : "---"} />
    ),
  },
];

function AircraftSummaryReport() {
  const { selectedClubId, selectedClub, selectedLocationId, selectedLocation } =
    useClubs();
  const [rows, setRows] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [dateRange, setDateRange] = useState([
    startOfMonth(subMonths(new Date(), 1)),
    endOfMonth(subMonths(new Date(), 1)),
  ]);

  const updateDateRange = (dates) => {
    dates[0] = startOfDay(dates[0]);
    dates[1] = endOfDay(dates[1]);

    setDateRange(dates);
  };

  const aircraftCollectionRef = query(
    getAircraftCollection(selectedClubId),
    where("locations", "array-contains", selectedLocationId)
  );
  const [clubAircraft, loadingAircraft] = useCollection(aircraftCollectionRef, {
    snapshotListenOptions: {
      includeMetadataChanges: false,
    },
  });

  const simulatorCollectionRef = query(
    getSimulatorsCollection(selectedClubId),
    where("locations", "array-contains", selectedLocationId)
  );
  const [clubSimulators, loadingSimulators] = useCollection(
    simulatorCollectionRef,
    {
      snapshotListenOptions: {
        includeMetadataChanges: false,
      },
    }
  );

  const clubsTransactionsCollectionRef = query(
    getClubTransactionsCollection(selectedClubId),
    where("createdAt", ">=", dateRange[0]),
    orderBy("createdAt", "desc")
  );
  const { data: allTransactions } = useRealtimeCollectionData(
    clubsTransactionsCollectionRef,
    true
  );

  const addAircraftTransactions = async (aircraftDocs) => {
    const rowData = await Promise.all(
      aircraftDocs.map(async (aircraftDoc) => {
        const aircraftId = aircraftDoc.id;

        const transactions = allTransactions.filter(
          (transaction) =>
            transaction.aircraft?.id === aircraftId &&
            transaction.createdAt?.toDate() <= dateRange[1]
        );

        const lastFlight =
          transactions.length > 0
            ? new Date(
                Math.max(
                  ...transactions.map(
                    (e) => e.booking?.completedAt?.toDate().getTime() ?? 0
                  ),
                  0
                )
              )
            : null;

        let totalHobbsHours = 0;
        let totalTachHours = 0;
        const totalRevenue = transactions.reduce((acc, transaction) => {
          if (transaction.bookingData?.totalHobbsHours > 0) {
            totalHobbsHours += transaction.bookingData.totalHobbsHours;
            if (transaction.bookingData?.totalTach1Hours > 0) {
              totalTachHours += transaction.bookingData.totalTach1Hours;
            }
            if (transaction.payments?.length === 0 || !transaction.payments) {
              return acc + 0;
            }
            if (transaction.payments?.length === 1) {
              return acc + transaction.payments[0].amount / 100;
            }
            const totalPayments = transaction.payments.reduce(
              (acc2, payment) => acc2 + payment.amount / 100,
              0
            );

            return acc + totalPayments;
          }
          return acc;
        }, 0);

        const fullAircraftData = {
          id: aircraftId,
          ...aircraftDoc.data(),
          transactions,
          lastFlight,
          tach1: aircraftDoc.data().tach1
            ? Math.round(aircraftDoc.data().tach1 * 100) / 100
            : "---",
          totalHobbsHours: Math.round(totalHobbsHours * 10) / 10,
          totalTachHours: Math.round(totalTachHours * 100) / 100,
          totalRevenue,
          oilAdded: 0,
        };

        return fullAircraftData;
      })
    );

    const csvDataFromRows = rowData.map((row) => ({
      "Tail #": row.tailNumber,
      Aircraft: row.displayName,
      Hobbs: row.hobbs,
      Tach: row.tach1,
      "Total Hobbs Hours": row.totalHobbsHours,
      "Total Tach Hours": row.totalTachHours,
      "Oil Added": row.oilAdded,
      "Total Billed": row.totalBilled,
      "Last Flight": row.lastFlight
        ? format(row.lastFlight, "eee MMM do yyyy")
        : "---",
    }));

    setCsvData(csvDataFromRows);

    setRows(rowData);
  };

  useEffect(() => {
    if (loadingAircraft || !clubAircraft || loadingSimulators) return;
    addAircraftTransactions([...clubAircraft.docs, ...clubSimulators.docs]);
  }, [
    clubAircraft,
    loadingAircraft,
    dateRange,
    allTransactions,
    clubSimulators,
    loadingSimulators,
  ]);

  const onRowSelected = (row) => {
    console.log("onRowSelected", row);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <SoftBox />
          <SoftBox display="flex">
            <SoftDateRangePicker
              value={dateRange}
              onChange={(newDates) => updateDateRange(newDates)}
            />
            <SoftBox mx={1}>
              <Divider orientation="vertical" />
            </SoftBox>
            <SoftBox>
              <CSVLink
                data={csvData}
                filename={`${selectedClub.name}-${
                  selectedLocation?.icao
                }-aircraft_summary-${format(new Date(), "MM/dd/yyyy")}.csv`}
                target="_blank"
              >
                <SoftButton variant="outlined" color="dark">
                  <Icon>description</Icon>
                  &nbsp;export csv
                </SoftButton>
              </CSVLink>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <Card>
          <DataTable
            onRowSelect={onRowSelected}
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 10, entries: [10, 20, 50, 100] }}
            canSearch
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AircraftSummaryReport;
