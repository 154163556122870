import { useMemo } from "react";
import { useUser } from "src/features/user/UserProvider";
import { useClubs } from "src/features/club/ClubProvider";
import { IUseClub } from "src/interfaces";
import {
  systemPermissions,
  systemPermissionsType,
} from "src/interfaces/roles/role.interface";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "src/features/firebase/auth/utils";

export const usePermissions = () => {
  const [savedUser] = useAuthState(auth) as any;
  const isPreFlightAdmin = useMemo(() => {
    let userCustomAttributes;
    if (savedUser && savedUser.reloadUserInfo?.customAttributes) {
      userCustomAttributes = JSON.parse(
        savedUser.reloadUserInfo.customAttributes
      );
    }
    if (userCustomAttributes && userCustomAttributes.superadmin) {
      return true;
    }
    return false;
  }, [savedUser]);

  const { currentUserPermission, clubUsersPermissions, clubUserRoles } =
    useClubs() as IUseClub;
  const { userId: currentUserId } = useUser();

  const currentRoles = useMemo(() => {
    const currentUserRoles = new Set(
      (currentUserPermission ?? []).map((r) => r.userRoles).flatMap((v) => v)
    );
    return clubUserRoles.filter((r) => currentUserRoles.has(r.id));
  }, [currentUserPermission, clubUserRoles]);

  const getUserRoles = (userId: string) => {
    const userPermissions = clubUsersPermissions?.get(userId);

    const userRoles = new Set(userPermissions?.userRoles);
    return clubUserRoles.filter((r) => userRoles?.has(r.id));
  };

  const isSuperAdmin = (userId: string) => {
    if (isPreFlightAdmin && userId === currentUserId) return true;

    const userPermissions = clubUsersPermissions?.get(userId);

    return userPermissions?.isSuperAdmin ?? false;
  };

  return {
    isPreFlightAdmin,
    isInstructor: (userId = currentUserId) => {
      const userRoles =
        userId === currentUserId ? currentRoles : getUserRoles(userId);

      if (userRoles) {
        return userRoles.some((r) => {
          return r.configuration?.roleTypes?.includes("Instructor");
        });
      }
      return false;
    },
    isStaff: (userId = currentUserId) => {
      if (isPreFlightAdmin && userId === currentUserId) return true;
      if (isSuperAdmin(userId)) return true;
      const userRoles =
        userId === currentUserId ? currentRoles : getUserRoles(userId);

      if (userRoles) {
        return userRoles.some((r) => {
          return r.configuration?.roleTypes?.includes("Staff");
        });
      }
      return false;
    },
    hasAccess: (
      permissions: systemPermissionsType[] | systemPermissionsType,
      userId = currentUserId
    ) => {
      const parsedPermissions = Array.isArray(permissions)
        ? permissions
        : [permissions];

      if (parsedPermissions.includes(systemPermissions.PREFLIGHT_ADMIN))
        return isPreFlightAdmin;
      else if (isSuperAdmin(userId)) return true;

      const userRoles =
        userId === currentUserId ? currentRoles : getUserRoles(userId);
      return (
        !!userRoles &&
        userRoles.some((r) => {
          return r.permissions.some((p: any) =>
            parsedPermissions?.some((p2) => p2 === p.key)
          );
        })
      );
    },
    getUserRoles,
    isSuperAdmin,
    userRoles: currentRoles ?? [],
    permissions: currentRoles.map((r) => r.permissions).flatMap((v) => v),
  };
};
