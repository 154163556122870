import { useEffect, useMemo, useState } from "react";
import { Radio } from "@mui/material";
import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "src/components/TableCell";

import { addDoc, doc, updateDoc } from "firebase/firestore";
import { getUserLessonGradingsCollection } from "src/features/user/collections";

const schema = yup.object({}).required();

export function GradeTaskModal({ handleClose, lesson, taskId }) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const task = useMemo(
    () => lesson.tasks?.find((t) => t.id === taskId),
    [taskId, lesson]
  );

  const { handleSubmit, watch, setValue } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const notes = watch("notes");

  const [lessonGrade, setLessonGrade] = useState("I");
  const [taskGrades, setTaskGrades] = useState({});
  const [taskGradeErrors, setTaskGradeErrors] = useState({});

  const handleChangeTaskGrade = (event) => {
    if (event.target.value === "5" && lessonGrade === "S") {
      setLessonGrade("I");
    }
    setTaskGrades({ ...taskGrades, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    const taskGradeObject = {};
    lesson.tasks.forEach((t) => (taskGradeObject[t.id] = undefined));

    setTaskGrades(lesson.grade.taskGrades || taskGradeObject);
    setLessonGrade(lesson.grade.lessonGrade || "I");
    setValue("notes", lesson.grade.notes || "");
  }, [lesson]);

  const getDataToSave = () => {
    const dataToSave = {
      lessonId: lesson?.id,
      courseId: lesson?.courseId,
      stageId: lesson?.stageId,
      notes,
      savedAt: new Date(),
      deleted: false,
      lessonGrade: lessonGrade,
    };
    if (taskGrades) {
      dataToSave.taskGrades = taskGrades;
    }
    return dataToSave;
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    if (
      taskGrades?.length >= 1 &&
      Object.keys(taskGrades).length !== lesson?.tasks?.length
    ) {
      lesson?.tasks?.forEach((t) => {
        if (!taskGrades[t.id]) {
          setTaskGradeErrors({ ...taskGradeErrors, [t.id]: true });
        }
      });
      setIsSubmitting(false);
      return;
    }

    let gradeId = lesson?.grade?.id;
    const dataToSave = getDataToSave();

    if (gradeId) {
      await updateDoc(
        doc(
          getUserLessonGradingsCollection(
            lesson.studentId,
            lesson.enrollmentId
          ),
          gradeId
        ),
        { ...dataToSave, completedAt: new Date(), submitted: true }
      );
    } else {
      const grade = await addDoc(
        getUserLessonGradingsCollection(lesson.studentId, lesson.enrollmentId),
        { ...dataToSave, completedAt: new Date(), submitted: false }
      );
      gradeId = grade.id;
    }

    setIsSubmitting(false);
    handleClose();
  };

  const controlProps = (id, value) => ({
    checked: taskGrades?.[id] === value,
    onChange: handleChangeTaskGrade,
    value,
    name: id,
    inputProps: { "aria-label": value },
  });

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h6" color="primary" fontWeight="bold">
            Grade Task
          </SoftTypography>
          <SoftTypography variant="h5" fontWeight="bold">
            {task?.title}
          </SoftTypography>
          <SoftBox mt={2}>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <SoftBox pb={2}>
                  <SoftBox
                    minWidth="auto"
                    sx={{
                      overflow: "scroll",
                    }}
                  >
                    <Table
                      sx={{
                        minWidth: "36rem",
                      }}
                    >
                      <SoftBox component="thead">
                        <TableRow>
                          <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                            1
                          </TableCell>
                          <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                            2
                          </TableCell>
                          <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                            3
                          </TableCell>
                          <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                            4
                          </TableCell>
                          <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                            5
                          </TableCell>
                          <TableCell align="center" padding={[1.5, 3, 1.5, 3]}>
                            I
                          </TableCell>
                        </TableRow>
                      </SoftBox>
                      <TableBody>
                        <TableRow key={task.id}>
                          <TableCell align="center" padding={[1, 1, 1, 1]}>
                            <Radio
                              {...controlProps(task.id, "1")}
                              color="success"
                            />
                          </TableCell>
                          <TableCell align="center" padding={[1, 1, 1, 1]}>
                            <Radio {...controlProps(task.id, "2")} />
                          </TableCell>
                          <TableCell align="center" padding={[1, 1, 1, 1]}>
                            <Radio {...controlProps(task.id, "3")} />
                          </TableCell>
                          <TableCell align="center" padding={[1, 1, 1, 1]}>
                            <Radio {...controlProps(task.id, "4")} />
                          </TableCell>
                          <TableCell align="center" padding={[1, 1, 1, 1]}>
                            <Radio
                              {...controlProps(task.id, "5")}
                              color="error"
                            />
                          </TableCell>
                          <TableCell align="center" padding={[1, 1, 1, 1]}>
                            <Radio
                              {...controlProps(task.id, "I")}
                              color="warning"
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />
              <Grid item xs={3}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    disabled={isSubmitting}
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

GradeTaskModal.propTypes = {
  lesson: PropTypes.object.isRequired,
  taskId: PropTypes.string,
};
