// @mui material components
import { Grid } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import Globe from "src/components/Globe";

import TodaysEarnings from "./components/TodaysEarnings";
import AircraftUtilization from "./components/AircraftUtilization";
import ClubAnalytics from "./components/ClubAnalytics";

// Soft UI Dashboard PRO React base styles
import breakpoints from "src/assets/theme/base/breakpoints";

import ClubAnnouncements from "./components/ClubAnnouncements";
import UpcomingEventsWidget from "src/components/UpcomingEvents/Widget";
import WeatherCard from "./components/WeatherCard";

import { useClubs } from "src/features/club/ClubProvider";

// Images
// import iconSunCloud from 'src/assets/images/small-logos/icon-sun-cloud.png';

import BookingsListCalendar from "src/components/Calendar/BookingsListCalendar";
import { format } from "date-fns";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { usePermissions } from "src/hooks/usePermissions";
import { useEffect, useState } from "react";

function Dashboard() {
  const { values } = breakpoints;
  const { selectedClub, selectedLocation } = useClubs();
  const { hasAccess } = usePermissions();
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobileSize = width < 568;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <Grid container>
          <Grid item xs={12} lg={7}>
            <SoftBox mb={3} p={1}>
              <SoftTypography
                variant={window.innerWidth < values.sm ? "h3" : "h2"}
                textTransform="capitalize"
                fontWeight="bold"
              >
                {selectedClub?.name}
              </SoftTypography>
              <SoftTypography
                variant="h6"
                textTransform="capitalize"
                fontWeight="bold"
              >
                {selectedLocation?.icao}: {selectedLocation?.name}
              </SoftTypography>
            </SoftBox>

            <Grid container>
              <Grid item xs={12}>
                <Globe
                  display={{
                    xs: "none",
                    md: "block",
                  }}
                  position="absolute"
                  top="10%"
                  right={0}
                  mt={{
                    xs: -12,
                    lg: 1,
                  }}
                  mr={{
                    xs: 0,
                    lg: 10,
                  }}
                  canvasStyle={{
                    marginTop: "3rem",
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Grid item xs={12} sm={12}>
                  <WeatherCard title="weather - METAR" />
                </Grid>
              </Grid>
              <WithPermissions
                permissions={[systemPermissions.VIEW_FINANCIAL_REPORTS]}
              >
                <Grid item xs={12} sm={4}>
                  <TodaysEarnings />
                  <AircraftUtilization />
                </Grid>
              </WithPermissions>
            </Grid>
          </Grid>
          <Grid item xs={12} md={10} lg={7}>
            <Grid item xs={12} lg={10}>
              <BookingsListCalendar
                title="upcoming bookings"
                date={format(new Date(), "MMM dd, yyyy")}
                types={["reservation", "checkout", "checkride", "introFlight"]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {!isMobileSize && (
              <WithPermissions
                permissions={[systemPermissions.VIEW_FINANCIAL_REPORTS]}
              >
                <Grid item xs={12} lg={4}>
                  <ClubAnalytics />
                </Grid>
              </WithPermissions>
            )}
            <Grid
              item
              xs={12}
              lg={hasAccess([systemPermissions.VIEW_FINANCIAL_REPORTS]) ? 4 : 6}
            >
              <ClubAnnouncements />
            </Grid>
            <Grid
              item
              xs={12}
              lg={hasAccess([systemPermissions.VIEW_FINANCIAL_REPORTS]) ? 4 : 6}
            >
              <UpcomingEventsWidget />
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
