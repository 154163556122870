import PropTypes from 'prop-types'
import { useState } from 'react';
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  InfoOutlined,
} from "@mui/icons-material";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";
import CourseInfoPopover from '../CourseInfoPopover';
import { Skeleton } from '@mui/material';

function CourseHeader({
  course,
  onBackClick,
  children
}) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleInfoClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  return (
    <SoftBox
      mb={3}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <SoftBox display="flex" alignItems="center">
        <IconButton
          onClick={onBackClick}
          aria-label="Back"
          sx={{ p: 0 }}
        >
          <ChevronLeftIcon fontSize="large" color="text" />
        </IconButton>
        <SoftTypography variant="h3" fontWeight="bold">
          {course?.title || <Skeleton />}
        </SoftTypography>
      </SoftBox>

      <SoftBox display="flex" alignItems="center" gap={3}>
        { children }
        <SoftButton onClick={handleInfoClick} color="primary">
          <InfoOutlined />
          <SoftTypography
            fontSize="small"
            ml="4px"
            fontWeight="medium"
            color="white"
          >
            Course Information
          </SoftTypography>
        </SoftButton>
        <CourseInfoPopover
          anchorEl={anchorEl}
          handleClosePopover={handleClosePopover}
          course={course}
        />
      </SoftBox>
    </SoftBox>
  );
}

CourseHeader.defaultProps = {
  onBackClick: () => {},
}

CourseHeader.propTypes = {
  course: PropTypes.object.isRequired,
  onBackClick: PropTypes.func,
  children: PropTypes.node
}

export default CourseHeader
