import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for SoftTypography
import SoftTypographyRoot from "src/components/SoftTypography/SoftTypographyRoot";
import { TypographyTypeMap } from "@mui/material";

type SoftTypographyType = TypographyTypeMap["props"] &
  Partial<{
    color: string;
    textTransform: string;
    verticalAlign: string;
    fontWeight: string;
    opacity: number;
    textGradient: boolean;
    component: string;
  }> & {[key: string]: any};

const SoftTypography = forwardRef<HTMLSpanElement, SoftTypographyType>(
  (
    {
      color = "dark",
      fontWeight = "regular",
      textTransform = "none",
      verticalAlign = "unset",
      textGradient = false,
      opacity = 1,
      children,
      ...rest
    },
    ref
  ) => (
    <SoftTypographyRoot
      {...rest}
      ref={ref}
      ownerState={{
        color,
        textTransform,
        verticalAlign,
        fontWeight,
        opacity,
        textGradient,
      }}
    >
      {children}
    </SoftTypographyRoot>
  )
);

// Setting default values for the props of SoftTypography
SoftTypography.defaultProps = {
  color: "dark",
  fontWeight: "regular",
  textTransform: "none",
  verticalAlign: "unset",
  textGradient: false,
  opacity: 1,
};

// Typechecking props for the SoftTypography
const opacityPropType = PropTypes.number as PropTypes.Validator<number>;
const textGradientPropType = PropTypes.bool as PropTypes.Validator<boolean>;
const colorPropType = PropTypes.oneOf([
  "inherit",
  "primary",
  "secondary",
  "info",
  "success",
  "warning",
  "error",
  "light",
  "dark",
  "text",
  "white",
]) as PropTypes.Validator<
  | "inherit"
  | "primary"
  | "secondary"
  | "info"
  | "success"
  | "warning"
  | "error"
  | "light"
  | "dark"
  | "text"
  | "white"
>;

const fontWeightPropType = PropTypes.oneOf([
  "light",
  "regular",
  "medium",
  "bold",
]) as PropTypes.Validator<"light" | "regular" | "medium" | "bold">;

const textTransformPropType = PropTypes.oneOf([
  "none",
  "capitalize",
  "uppercase",
  "lowercase",
]) as PropTypes.Validator<"none" | "capitalize" | "uppercase" | "lowercase">;
const verticalAlignPropType = PropTypes.oneOf([
  "unset",
  "baseline",
  "sub",
  "super",
  "text-top",
  "text-bottom",
  "middle",
  "top",
  "bottom",
]) as PropTypes.Validator<
  | "unset"
  | "baseline"
  | "sub"
  | "super"
  | "text-top"
  | "text-bottom"
  | "middle"
  | "top"
  | "bottom"
>;

SoftTypography.propTypes = {
  color: colorPropType,
  fontWeight: fontWeightPropType,
  textTransform: textTransformPropType,
  verticalAlign: verticalAlignPropType,
  textGradient: textGradientPropType,
  children: PropTypes.node.isRequired,
  opacity: opacityPropType,
};

export default SoftTypography;
