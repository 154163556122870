import { useMemo, useState, useEffect } from "react";
import PropTypes from "prop-types";

// @mui core components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";

import Swal from "sweetalert2";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

import { useClubs } from "src/features/club/ClubProvider";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { doc, updateDoc } from "firebase/firestore";

import { getTableColumns, TableActions } from "./tableColumns";
import DataTable from "src/components/Tables/DataTable";

import { AddAccountModal } from "src/modals/AddAccountModal";
import { getClubAccountsCollection } from "src/features/club/collections";

function Accounts({ qbPrefs, qbItems, qbAccounts }) {
  const { selectedClubId } = useClubs();
  const [accounts, setAccounts] = useState([]);
  const [openAddAccount, setOpenAddAccount] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState();
  const [classes, setClasses] = useState({});
  const { data: clubAccounts } = useRealtimeCollectionData(
    getClubAccountsCollection(selectedClubId),
    false
  );

  useEffect(() => {
    if (clubAccounts) {
      setAccounts(clubAccounts);

      clubAccounts.forEach((rate) => {
        if (rate.qboClass) {
          setClasses({ ...classes, [rate.id]: rate.qboClass });
        }
      });
    }
  }, [clubAccounts]);

  const deleteAccount = (account) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button",
        confirmButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "All existing uses of this rate will default to the standard rate.",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        icon: "warning",
      })
      .then((result) => {
        if (result.isConfirmed) {
          updateDoc(
            doc(getClubAccountsCollection(selectedClubId), account.id),
            {
              deletedAt: new Date(),
              deleted: true,
            }
          );
        }
      });
  };

  const updateRate = (rate, updates) => {
    updateDoc(doc(getClubAccountsCollection(selectedClubId), rate.id), updates);
  };

  const handleTableActions = (action, account, updates) => {
    setSelectedAccount(null);
    switch (action) {
      case TableActions.UPDATE:
        updateRate(account, { ...updates, updatedAt: new Date() });
        break;

      case TableActions.DELETE:
        deleteAccount(account);
        break;

      case TableActions.EDIT:
        setSelectedAccount(account);
        setOpenAddAccount(true);
        break;

      default:
        break;
    }
  };

  const handleAddAccountModalClose = () => {
    setOpenAddAccount(false);
  };

  const tableColumns = useMemo(
    () =>
      getTableColumns(
        handleTableActions,
        qbItems,
        qbPrefs?.AccountingInfoPrefs?.ClassTrackingPerTxnLine ||
          qbPrefs?.AccountingInfoPrefs?.ClassTrackingPerTxn,
        classes
      ),
    [qbItems, qbPrefs]
  );

  return (
    <Card
      id="rental-rates"
      sx={{
        overflow: "visible",
      }}
    >
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Accounts</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Manage different club specific accounts a user can have.
        </SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <SoftBox
          xs={12}
          padding={1}
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          {accounts && accounts.length > 0 && (
            <DataTable
              entriesPerPage={false}
              maxWidth="100%"
              table={{
                columns: tableColumns,
                rows: accounts,
              }}
            />
          )}
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} justifyContent="flex-end" display="flex">
            <SoftButton
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              onClick={() => {
                setSelectedAccount(null);
                setOpenAddAccount(true);
              }}
            >
              Add Account
            </SoftButton>
          </Grid>
        </Grid>
      </SoftBox>
      <Modal
        open={openAddAccount}
        onClose={handleAddAccountModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddAccountModal
            handleClose={handleAddAccountModalClose}
            account={selectedAccount}
            qbAccounts={qbAccounts}
          />
        </SoftBox>
      </Modal>
    </Card>
  );
}

Accounts.defaultProps = {
  qbPrefs: {},
  qbItems: [],
};

Accounts.propTypes = {
  qbPrefs: PropTypes.object,
  qbItems: PropTypes.array,
  qbAccounts: PropTypes.array,
};

export default Accounts;
