import Tab from "@mui/material/Tab";
import { PermissionTab } from "./PermissionTab";
import { Stack, Tabs } from "@mui/material";
import { useState } from "react";
import TabPanel from "src/components/TabPanel";
import {
  AddTask,
  CheckCircleOutline,
  Close,
  ManageAccounts,
} from "@mui/icons-material";
import SoftButton from "src/components/SoftButton";
import {
  IRole,
  systemPermissionsType,
} from "src/interfaces/roles/role.interface";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RequiredDocuments } from "./RequiredDocuments";
import { RoleTypes } from "./RoleTypes";

const schema = yup
  .object()
  .shape({
    rolename: yup.string().required("Role Name is required."),
  })
  .required();

interface IDrawerContent {
  isEdit: boolean;
  isLoading?: boolean;
  role?: IRole;
  rolename?: string;
  onClose: () => void;
  onDelete: (userRole: IRole) => void;
  onSubmit: ({
    rolename,
    permissions,
    userRole,
  }: {
    rolename: string;
    permissions: systemPermissionsType[];
    userRole?: IRole;
  }) => void;
}
export const DrawerContent: React.FC<IDrawerContent> = ({ ...props }) => {
  const { role, isLoading, isEdit, onClose, onDelete, onSubmit, rolename } =
    props;
  const [value, setValue] = useState(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [permissions, setPermissions] = useState<systemPermissionsType[]>(
    role ? role.permissions.map((p) => p.key) : []
  );

  const [requiredDocs, setRequiredDocs] = useState<Array<string>>(
    role?.configuration?.requiredDocuments ?? []
  );

  const [roleTypes, setRoleTypes] = useState<Array<string>>(
    role?.configuration?.roleTypes ?? []
  );

  const {
    handleSubmit,
    control,
    // formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      rolename: role?.label ?? rolename ?? "",
    },
  });

  return (
    <form
      onSubmit={handleSubmit((d) => {
        const configuration = role?.configuration ?? {};

        onSubmit({
          rolename: d.rolename,
          permissions,
          userRole: {
            ...role!,
            configuration: {
              ...configuration,
              requiredDocuments: requiredDocs,
              roleTypes,
            },
          },
        });
      })}
      style={{ height: "100%", display: "flex", flexDirection: "column" }}
    >
      <Stack direction="row" justifyContent="space-between" mt="12px" mb="16px">
        {isEdit ? `Edit ${role?.label} role` : "Add New Role"}
        <Close onClick={onClose} cursor="pointer" />
      </Stack>
      <Tabs
        orientation="horizontal"
        value={value}
        onChange={handleChange}
        sx={{
          backgroundColor: "transparent",
          minHeight: "40px",
        }}
      >
        <Tab label="Permissions" />
        <Tab label="Configuration" />
      </Tabs>
      <TabPanel
        value={value}
        index={0}
        sx={{ overflowY: "auto", marginTop: "4px", marginBottom: "8px" }}
      >
        <PermissionTab
          {...props}
          permissions={permissions}
          setPermissions={setPermissions}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <RequiredDocuments
          control={control}
          requiredDocs={requiredDocs}
          setRequiredDocs={setRequiredDocs}
        />
        <RoleTypes selectedRoles={roleTypes} setRoleTypes={setRoleTypes} />
      </TabPanel>
      <Stack
        direction="row"
        justifyContent="space-between"
        mt="auto"
        mb="10px"
        pt="8px"
      >
        {role?.canDelete && (
          <SoftButton
            color="error"
            variant="outlined"
            onClick={() => {
              onDelete(role);
            }}
          >
            <ManageAccounts sx={{ marginRight: "10px" }} /> Delete Role
          </SoftButton>
        )}
        <SoftButton color="primary" type="submit" disabled={isLoading}>
          {role ? (
            <>
              {" "}
              <CheckCircleOutline sx={{ marginRight: "10px" }} /> Save Changes
            </>
          ) : (
            <>
              <AddTask sx={{ marginRight: "10px" }} /> Add Role
            </>
          )}
        </SoftButton>
      </Stack>
    </form>
  );
};
