import toastr from "toastr";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";

// Settings page components
import BaseLayout from "src/containers/LayoutContainers/BaseLayout";
import Sidenav from "src/pages/user/account/settings/components/Sidenav";
import Header from "src/pages/user/account/settings/components/Header";
import BasicInfo from "src/pages/user/account/settings/components/BasicInfo";
import Documents from "src/pages/user/account/settings/components/Documents";
import Notifications from "src/pages/user/account/settings/components/Notifications";
import DeleteAccount from "src/pages/user/account/settings/components/DeleteAccount";
import ChangePassword from "src/pages/user/account/settings/components/ChangePassword";

import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { useClubs } from "src/features/club/ClubProvider";
import { getClubInstructorPreferencesCollection } from "src/features/club/collections";
import { useDocument } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { EditWorkHours } from "src/components/EditWorkHours";
import { useUser } from "src/features/user/UserProvider";

function Settings() {
  const { user } = useUser();
  const { selectedClubId, selectedLocationId } = useClubs();
  const { updateData } = entityCrudUtils();
  const [instructorPrefs] = useDocument(
    doc(
      getClubInstructorPreferencesCollection(
        selectedClubId,
        selectedLocationId
      ),
      user.uid || "noid"
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  const saveWorkHours = async (data) => {
    await updateData(
      {
        entity: getClubInstructorPreferencesCollection(
          selectedClubId,
          selectedLocationId
        ),
        pathSegmentsArr: [user.uid],
      },
      { workingHours: data }
    );
    toastr.success("Working hours saved successfully");
  };

  let workingHours = instructorPrefs?.data()?.workingHours;
  if (workingHours === undefined) {
    workingHours = {};
  }

  return (
    <BaseLayout stickyNavbar>
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Header />
                </Grid>
                <Grid item xs={12}>
                  <BasicInfo />
                </Grid>
                <Grid item xs={12}>
                  {instructorPrefs && (
                    <EditWorkHours
                      handleSave={saveWorkHours}
                      workingHours={workingHours}
                      showRequestOnlyOption
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Documents />
                </Grid>
                <Grid item xs={12}>
                  <ChangePassword />
                </Grid>
                {/* <Grid item xs={12}>
                  <Authentication />
                </Grid>
                <Grid item xs={12}>
                  <Accounts />
                </Grid> */}
                <Grid item xs={12}>
                  <Notifications />
                </Grid>
                {/* <Grid item xs={12}>
                  <Sessions />
                </Grid> */}
                <Grid item xs={12}>
                  <DeleteAccount />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

export default Settings;
