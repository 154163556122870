import Card from "@mui/material/Card";
import SoftBox from "../SoftBox";

export default function SliderCard({ width = "200px", sx, onClick, children }) {
  return (
    <SoftBox width={width} pr={4}>
      <Card onClick={onClick} sx={{ ...sx, boxShadow: "none", cursor: onClick ? "pointer" : "default" }}>
        {children}
      </Card>
    </SoftBox>
  );
}
