import { useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// uuid is a library for generating unique id
import { v4 as uuidv4 } from "uuid";

// @mui material components
import { Table as MuiTable, Icon } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React base styles
import colors from "src/assets/theme/base/colors";
import typography from "src/assets/theme/base/typography";
import borders from "src/assets/theme/base/borders";
import { formatCurrency } from "src/features/utils";

function Table({ columns, rows, onRowDelete, onRowEdit }) {
  const { light } = colors;
  const { size, fontWeightBold } = typography;
  const { borderWidth } = borders;

  const renderColumns = columns.map(({ name, align, width }, key) => {
    let pl;
    let pr;

    if (key === 0) {
      pl = 3;
      pr = 3;
    } else if (key === columns.length - 1) {
      pl = 3;
      pr = 3;
    } else {
      pl = 1;
      pr = 1;
    }

    return (
      <SoftBox
        key={name}
        component="th"
        width={width || "auto"}
        pt={1.5}
        pb={1.25}
        pl={align === "left" ? pl : 3}
        pr={align === "right" ? pr : 3}
        textAlign={align}
        fontSize={size.xxs}
        fontWeight={fontWeightBold}
        color="secondary"
        opacity={0.7}
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
      >
        {name.toUpperCase()}
      </SoftBox>
    );
  });

  const renderRows = rows.map((row, key) => {
    const rowKey = `row-${key}`;
    const tableRow = columns.map(({ name, align, isCurrency, isActions }) => {
      if (isActions) {
        return (
          <SoftBox
            key={uuidv4()}
            component="td"
            p={1}
            textAlign={align}
            borderBottom={
              key !== rows.length - 1
                ? `${borderWidth[1]} solid ${light.main}`
                : null
            }
            backgroundColor={key % 2 === 0 ? "transparent" : "secondary"}
            sx={{
              backgroundColor: key % 2 === 0 ? "transparent" : "secondary",
            }}
          >
            <SoftBox
              display="flex"
              alignItems="center"
              flexDirection="row-reverse"
              pt={0.5}
            >
              <SoftTypography variant="button" fontWeight="medium">
                {onRowDelete && (
                  <SoftBox
                    component="span"
                    onClick={() => onRowDelete(row)}
                    sx={{
                      color: "error.main",
                      cursor: "pointer",
                    }}
                  >
                    <Icon className="material-icons-round">delete</Icon>
                  </SoftBox>
                )}
                {onRowEdit && (
                  <SoftBox
                    component="span"
                    mr={1}
                    onClick={() => onRowEdit(row)}
                    sx={{
                      color: "info.main",
                      cursor: "pointer",
                    }}
                  >
                    <Icon className="material-icons-round">edit</Icon>
                  </SoftBox>
                )}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        );
      }

      let template;

      if (Array.isArray(row[name])) {
        template = (
          <SoftBox
            key={uuidv4()}
            component="td"
            p={1}
            borderBottom={
              key !== rows.length - 1
                ? `${borderWidth[1]} solid ${light.main}`
                : null
            }
            backgroundColor={key % 2 === 0 ? "transparent" : "secondary"}
            sx={{
              backgroundColor: key % 2 === 0 ? "transparent" : "secondary",
            }}
          >
            <SoftBox display="flex" alignItems="center" pt={0.5}>
              <SoftTypography variant="button" fontWeight="medium">
                {row[name][0]}
              </SoftTypography>
            </SoftBox>
            <SoftBox display="flex" alignItems="center" pb={0.5} px={1}>
              <SoftTypography
                variant="button"
                fontWeight="regular"
                color="secondary"
              >
                {row[name][1]}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        );
      } else {
        template = (
          <SoftBox
            key={uuidv4()}
            component="td"
            p={1}
            textAlign={align}
            borderBottom={
              key !== rows.length - 1
                ? `${borderWidth[1]} solid ${light.main}`
                : null
            }
            backgroundColor={key % 2 === 0 ? "transparent" : "secondary"}
            sx={{
              backgroundColor: key % 2 === 0 ? "transparent" : "secondary",
            }}
          >
            <SoftTypography
              variant="button"
              fontWeight="medium"
              color="text"
              sx={{
                display: "inline-block",
                width: "max-content",
              }}
            >
              {isCurrency ? formatCurrency(row[name]) : row[name]}
            </SoftTypography>
          </SoftBox>
        );
      }

      return template;
    });

    return <TableRow key={rowKey}>{tableRow}</TableRow>;
  });

  return useMemo(
    () => (
      <TableContainer>
        <MuiTable>
          <SoftBox component="thead">
            <TableRow>{renderColumns}</TableRow>
          </SoftBox>
          <TableBody>{renderRows}</TableBody>
        </MuiTable>
      </TableContainer>
    ),
    [columns, rows]
  );
}

// Setting default values for the props of Table
Table.defaultProps = {
  columns: [],
  rows: [{}],
};

// Typechecking props for the Table
Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
};

export default Table;
