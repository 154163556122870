import Slider from "react-slick";
import SoftBox from "../SoftBox";

export default function SoftSlider({ children, sx }) {
  const settings = {
    dots: false,
    infinite: false,
    variableWidth: true,
    swipeToSlide: true,
    arrows: false,
    adaptiveHeight: true,
  };

  return (
    <SoftBox sx={sx}>
        <Slider {...settings}>
          {children}
        </Slider>
    </SoftBox>
  );
}