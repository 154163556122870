import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import { Switch, CircularProgress } from "@mui/material";
import ClassroomCourseCard from "./components/ClassroomCourseCard";
import { EnrollNewStudentModal } from "src/modals/EnrollNewStudentModal";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftInput from "src/components/SoftInput";
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import Stack from "@mui/material/Stack";
import SoftButton from "src/components/SoftButton";

import { useLms } from "src/features/lms/LmsProvider";
import { useUser } from "src/features/user/UserProvider";
import { enrolledCoursesOnly, getCourseURL } from "src/pages/lms/utils";
import { usePermissions } from "src/hooks/usePermissions";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";

function Classroom() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isInstructor } = usePermissions();
  const { userId } = useUser();
  const { getStudentEnrollmentsData, getClubEnrollmentsData } = useLms();
  const [allStudents, setAllStudents] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [enrolledCourses, setEnrolledCourses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);
  const [openAddNewStudentModal, setOpenAddNewStudentModal] = useState(false);

  const handleOpenAddNewStudentModal = () => {
    setOpenAddNewStudentModal(true);
  };

  const handleCloseAddNewStudentModal = (refresh) => {
    setOpenAddNewStudentModal(false);

    if (refresh) {
      getEnrollments();
    }
  };

  const getEnrollments = async () => {
    setIsLoading(true);
    let enrollments;
    if (isInstructor()) {
      enrollments = allStudents
        ? await getClubEnrollmentsData()
        : await getClubEnrollmentsData(null, userId);
    } else {
      enrollments = await getStudentEnrollmentsData(userId);
      if (
        enrollments.length === 1 &&
        enrollments[0].enrolledCourses.length === 1
      ) {
        const enrolledCourse = enrollments[0].enrolledCourses[0];
        navigate(
          getCourseURL(
            pathname,
            enrolledCourse.member?.value,
            "courses",
            enrolledCourse.enrollmentId,
            enrolledCourse.id
          )
        );
      }
    }
    setEnrolledCourses(enrolledCoursesOnly(enrollments));
    setIsLoading(false);
  };

  const handleCheckAllStudents = (e) => {
    setAllStudents(e.target.checked);
  };

  const handleCourseSelection = (studentId, enrollmentId, enrolledCourseId) => {
    navigate(
      getCourseURL(
        pathname,
        studentId,
        "courses",
        enrollmentId,
        enrolledCourseId
      )
    );
  };

  useEffect(() => {
    getEnrollments();
  }, [allStudents]);

  useEffect(() => {
    setFilteredCourses(
      enrolledCourses
        .filter((course) => {
          const titleInSearch = course.title
            .toLowerCase()
            .includes(searchValue.toLowerCase());
          const studentInSearch = course.member.label
            .toLowerCase()
            .includes(searchValue.toLowerCase());
          return isInstructor()
            ? titleInSearch || studentInSearch
            : titleInSearch;
        })
        .sort((a, b) => a.member.value < b.member.value)
    );
  }, [enrolledCourses, searchValue]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  mb={3}
                  pt={2}
                >
                  <SoftBox lineHeight={1}>
                    <SoftTypography variant="h5" fontWeight="medium">
                      {isInstructor() ? "Students" : "Classroom"}
                    </SoftTypography>
                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      {isInstructor()
                        ? `All of ${
                            allStudents ? "the" : "your"
                          } students enrolled in courses.`
                        : "All of the courses you are enrolled in."}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox display="flex" gap="1rem" alignItems="center">
                    <WithPermissions
                      permissions={systemPermissions.VIEW_ALL_LMS_STUDENTS}
                    >
                      <SoftBox display="flex" alignItems="center" gap={1}>
                        <SoftTypography
                          variant="button"
                          sx={{ minWidth: "max-content" }}
                        >
                          Show all students
                        </SoftTypography>
                        <Switch
                          value={allStudents}
                          onChange={handleCheckAllStudents}
                        />
                      </SoftBox>
                    </WithPermissions>
                    <WithPermissions
                      permissions={systemPermissions.ENROLL_STUDENTS}
                    >
                      <Stack spacing={1} direction="row" width={285}>
                        <SoftButton
                          variant="gradient"
                          color="info"
                          // size="small"
                          onClick={() => handleOpenAddNewStudentModal()}
                        >
                          + enroll student
                        </SoftButton>
                      </Stack>
                    </WithPermissions>
                    <SoftInput
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      placeholder="Search..."
                      sx={{ maxWidth: "300px" }}
                    />
                  </SoftBox>
                </SoftBox>
                {isLoading ? (
                  <SoftBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="8rem"
                    width="100%"
                  >
                    <CircularProgress />
                  </SoftBox>
                ) : (
                  <Grid container spacing={2} mb={3}>
                    {filteredCourses.map((enrolledCourse) => (
                      <ClassroomCourseCard
                        onCardClick={handleCourseSelection}
                        enrolledCourse={enrolledCourse}
                        showStudent={isInstructor()}
                        key={enrolledCourse.id}
                      />
                    ))}
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
      <Modal
        open={openAddNewStudentModal}
        onClose={handleCloseAddNewStudentModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <EnrollNewStudentModal handleClose={handleCloseAddNewStudentModal} />
        </SoftBox>
      </Modal>
    </DashboardLayout>
  );
}

export default Classroom;
