import { useClubs } from "src/features/club/ClubProvider";
import { IUseClub } from "src/interfaces";
import { Checkbox, Stack } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import { getClubDocumentTypesCollection } from "src/features/club/collections";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { query, where } from "firebase/firestore";
import SoftLoader from "src/components/SoftLoader/SoftLoader";
import { standardDocumentTypes } from "src/config/localDocuments";
import { Control, Controller } from "react-hook-form";
import SoftInput from "src/components/SoftInput";

interface RequiredDocumentsProps {
  requiredDocs: Array<string>;
  setRequiredDocs: React.Dispatch<React.SetStateAction<string[]>>;
  control: Control<
    {
      rolename: string;
    },
    any
  >;
}

export const RequiredDocuments = ({
  requiredDocs,
  control,
  setRequiredDocs,
}: RequiredDocumentsProps) => {
  const isRequiredDocSelected = (docId: string) => {
    return requiredDocs.includes(docId);
  };
  const { selectedClubId } = useClubs() as IUseClub;
  const documentTypesRef = getClubDocumentTypesCollection(selectedClubId);
  const { data: documentTypes, isDataLoaded: documentTypesLoaded } =
    useRealtimeCollectionData(
      query(documentTypesRef, where("deleted", "==", false))
    );

  return (
    <SoftLoader isLoading={!documentTypesLoaded}>
      <Controller
        control={control}
        name="rolename"
        render={({ field: { onChange, value, ref: inputRef } }) => (
          <SoftInput
            onChange={onChange}
            inputRef={inputRef}
            value={value}
            autoComplete="disabled"
          />
        )}
      />
      <SoftTypography
        variant="h6"
        color="primary"
        my="16px"
        autoComplete="disabled"
      >
        Required Documents
      </SoftTypography>
      <Stack direction="column" gap="5px">
        {standardDocumentTypes.concat(documentTypes ?? []).map((doc: any) => (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{ padding: "5px 0" }}
            ml={3}
            key={doc.id}
          >
            <Checkbox
              value={doc.id}
              checked={isRequiredDocSelected(doc.id)}
              onChange={(e) => {
                setRequiredDocs((prev) => {
                  if (e.target.checked) return prev.concat(doc.id);
                  return prev.filter((d) => d !== doc.id);
                });
              }}
            />

            <SoftTypography variant="button">{doc.name}</SoftTypography>
          </SoftBox>
        ))}
      </Stack>
    </SoftLoader>
  );
};
