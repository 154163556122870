// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

function HoursRemainingCard({
  bgColor,
  title,
  soloRemaining,
  dualRemaining,
  lessonsRemaining,
  balance,
  showBalance,
  showBalanceVertical,
  percentage,
  icon,
  direction,
}) {
  return (
    <Card>
      <SoftBox bgColor={bgColor} variant="gradient">
        <SoftBox p={2}>
          <Grid container alignItems="center">
            {direction === "left" ? (
              <Grid item>
                <SoftBox
                  variant="gradient"
                  bgColor={bgColor === "white" ? icon.color : "white"}
                  color={bgColor === "white" ? "white" : "dark"}
                  width="3rem"
                  height="3rem"
                  borderRadius="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md"
                >
                  <Icon fontSize="small" color="inherit">
                    {icon.component}
                  </Icon>
                </SoftBox>
              </Grid>
            ) : null}
            {showBalanceVertical ? (
              <>
                <Grid item xs={9}>
                  <Grid container alignItems="center">
                    {showBalance && (
                      <Grid item xs={12}>
                        <SoftBox
                          ml={direction === "left" ? 2 : 0}
                          lineHeight={1}
                        >
                          <SoftBox>
                            <SoftTypography
                              variant="button"
                              color={bgColor === "white" ? "text" : "white"}
                              opacity={bgColor === "white" ? 1 : 0.7}
                              textTransform="capitalize"
                              fontWeight={title.fontWeight}
                            >
                              Account Balance
                            </SoftTypography>
                            <SoftTypography
                              variant="h5"
                              fontWeight="bold"
                              color={bgColor === "white" ? "dark" : "white"}
                            >
                              {balance}{" "}
                              <SoftTypography
                                variant="button"
                                color={percentage.color}
                                fontWeight="bold"
                              >
                                {percentage.text}
                              </SoftTypography>
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                      </Grid>
                    )}
                    <Grid item xs={12} pt={showBalance ? 2 : 0}>
                      <SoftBox ml={direction === "left" ? 2 : 0} lineHeight={1}>
                        <SoftTypography
                          variant="button"
                          color={bgColor === "white" ? "text" : "white"}
                          opacity={bgColor === "white" ? 1 : 0.7}
                          textTransform="capitalize"
                          fontWeight={title.fontWeight}
                        >
                          {title.text}
                        </SoftTypography>
                        {(dualRemaining >= 0 || dualRemaining < 0) && (
                          <SoftBox>
                            <SoftTypography
                              variant="button"
                              fontWeight="bold"
                              color={bgColor === "white" ? "dark" : "white"}
                            >
                              Dual:{" "}
                              <SoftTypography
                                variant="body"
                                fontSize="1.4rem"
                                color={dualRemaining >= 0 ? "info" : "error"}
                                fontWeight="bold"
                              >
                                {Math.round(dualRemaining * 10) / 10}
                                <SoftTypography
                                  variant="caption"
                                  color={bgColor === "white" ? "dark" : "white"}
                                  fontWeight="regular"
                                >
                                  /hrs
                                </SoftTypography>
                              </SoftTypography>
                            </SoftTypography>
                          </SoftBox>
                        )}
                        {(soloRemaining >= 0 || soloRemaining < 0) && (
                          <SoftBox>
                            <SoftTypography
                              variant="button"
                              fontWeight="bold"
                              color={bgColor === "white" ? "dark" : "white"}
                            >
                              Solo:{" "}
                              <SoftTypography
                                variant="body"
                                fontSize="1.4rem"
                                color={soloRemaining >= 0 ? "info" : "error"}
                                fontWeight="bold"
                              >
                                {Math.round(soloRemaining * 10) / 10}
                                <SoftTypography
                                  variant="caption"
                                  color={bgColor === "white" ? "dark" : "white"}
                                  fontWeight="regular"
                                >
                                  /hrs
                                </SoftTypography>
                              </SoftTypography>
                            </SoftTypography>
                          </SoftBox>
                        )}
                        {(lessonsRemaining >= 0 || lessonsRemaining < 0) && (
                          <SoftBox>
                            <SoftTypography
                              variant="button"
                              fontWeight="bold"
                              color={bgColor === "white" ? "dark" : "white"}
                            >
                              <SoftTypography
                                variant="body"
                                fontSize="1.4rem"
                                color={lessonsRemaining >= 0 ? "info" : "error"}
                                fontWeight="bold"
                              >
                                {Math.round(lessonsRemaining * 10) / 10}
                                <SoftTypography
                                  variant="caption"
                                  color={bgColor === "white" ? "dark" : "white"}
                                  fontWeight="regular"
                                >
                                  /lessons
                                </SoftTypography>
                              </SoftTypography>
                            </SoftTypography>
                          </SoftBox>
                        )}
                      </SoftBox>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={showBalance ? 5 : 9}>
                  <SoftBox ml={direction === "left" ? 2 : 0} lineHeight={1}>
                    <SoftTypography
                      variant="button"
                      color={bgColor === "white" ? "text" : "white"}
                      opacity={bgColor === "white" ? 1 : 0.7}
                      textTransform="capitalize"
                      fontWeight={title.fontWeight}
                    >
                      {title.text}
                    </SoftTypography>
                    {(dualRemaining >= 0 || dualRemaining < 0) && (
                      <SoftBox>
                        <SoftTypography
                          variant="button"
                          fontWeight="bold"
                          color={bgColor === "white" ? "dark" : "white"}
                        >
                          Dual:{" "}
                          <SoftTypography
                            variant="body"
                            fontSize="1.4rem"
                            color={dualRemaining >= 0 ? "info" : "error"}
                            fontWeight="bold"
                          >
                            {Math.round(dualRemaining * 10) / 10}
                            <SoftTypography
                              variant="caption"
                              color={bgColor === "white" ? "dark" : "white"}
                              fontWeight="regular"
                            >
                              /hrs
                            </SoftTypography>
                          </SoftTypography>
                        </SoftTypography>
                      </SoftBox>
                    )}
                    {(soloRemaining >= 0 || soloRemaining < 0) && (
                      <SoftBox>
                        <SoftTypography
                          variant="button"
                          fontWeight="bold"
                          color={bgColor === "white" ? "dark" : "white"}
                        >
                          Solo:{" "}
                          <SoftTypography
                            variant="body"
                            fontSize="1.4rem"
                            color={soloRemaining >= 0 ? "info" : "error"}
                            fontWeight="bold"
                          >
                            {Math.round(soloRemaining * 10) / 10}
                            <SoftTypography
                              variant="caption"
                              color={bgColor === "white" ? "dark" : "white"}
                              fontWeight="regular"
                            >
                              /hrs
                            </SoftTypography>
                          </SoftTypography>
                        </SoftTypography>
                      </SoftBox>
                    )}
                    {(lessonsRemaining >= 0 || lessonsRemaining < 0) && (
                      <SoftBox>
                        <SoftTypography
                          variant="button"
                          fontWeight="bold"
                          color={bgColor === "white" ? "dark" : "white"}
                        >
                          <SoftTypography
                            variant="body"
                            fontSize="1.4rem"
                            color={lessonsRemaining >= 0 ? "info" : "error"}
                            fontWeight="bold"
                          >
                            {Math.round(lessonsRemaining * 10) / 10}
                            <SoftTypography
                              variant="caption"
                              color={bgColor === "white" ? "dark" : "white"}
                              fontWeight="regular"
                            >
                              /lessons
                            </SoftTypography>
                          </SoftTypography>
                        </SoftTypography>
                      </SoftBox>
                    )}
                  </SoftBox>
                </Grid>
                {showBalance && (
                  <Grid item xs={5}>
                    <SoftBox ml={direction === "left" ? 2 : 0} lineHeight={1}>
                      <SoftBox>
                        <SoftTypography
                          variant="button"
                          color={bgColor === "white" ? "text" : "white"}
                          opacity={bgColor === "white" ? 1 : 0.7}
                          textTransform="capitalize"
                          fontWeight={title.fontWeight}
                        >
                          Account Balance
                        </SoftTypography>
                        <SoftTypography
                          variant="h5"
                          fontWeight="bold"
                          color={bgColor === "white" ? "dark" : "white"}
                        >
                          {balance}{" "}
                          <SoftTypography
                            variant="button"
                            color={percentage.color}
                            fontWeight="bold"
                          >
                            {percentage.text}
                          </SoftTypography>
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                )}
              </>
            )}
            {direction === "right" ? (
              <Grid item xs={2}>
                <SoftBox
                  variant="gradient"
                  bgColor={bgColor === "white" ? icon.color : "white"}
                  color={bgColor === "white" ? "white" : "dark"}
                  width="3rem"
                  height="3rem"
                  marginLeft="auto"
                  borderRadius="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  shadow="md"
                >
                  <Icon fontSize="small" color="inherit">
                    {icon.component}
                  </Icon>
                </SoftBox>
              </Grid>
            ) : null}
          </Grid>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of MiniStatisticsCard
HoursRemainingCard.defaultProps = {
  bgColor: "white",
  title: {
    fontWeight: "medium",
    text: "",
  },
  percentage: {
    color: "success",
    text: "",
  },
  direction: "right",
};

// Typechecking props for the MiniStatisticsCard
HoursRemainingCard.propTypes = {
  bgColor: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.PropTypes.shape({
    fontWeight: PropTypes.oneOf(["light", "regular", "medium", "bold"]),
    text: PropTypes.string,
  }),
  soloRemaining: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dualRemaining: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lessonsRemaining: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  balance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showBalance: PropTypes.bool,
  showBalanceVertical: PropTypes.bool,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
    ]),
    component: PropTypes.node.isRequired,
  }).isRequired,
  direction: PropTypes.oneOf(["right", "left"]),
};

export default HoursRemainingCard;
