import { useEffect, useState } from "react";
import { useStripe, Elements } from "@stripe/react-stripe-js";
import { getFunctions, httpsCallable } from "firebase/functions";
import InvoicePaymentDetails from "./InvoicePaymentDetails";
import { useLocation } from "react-router-dom";

export default function InvoicePaymentDetailsWrapper({ ...rest }) {
  const stripe = useStripe();
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [paymentIntentSecret, setPaymentIntentSecret] = useState(null);
  const location = useLocation();
  const { clubTransaction, fees, paymentMethod } = location.state;

  useEffect(() => {
    if (clubTransaction?.totalCost) {
      const functions = getFunctions();

      const createPaymentIntent = httpsCallable(
        functions,
        "createPaymentIntent"
      );
      createPaymentIntent({
        clubId: clubTransaction.club.id,
        locationId: clubTransaction.location.id,
        transactionId: clubTransaction.id,
        description: `Payment Request - ${clubTransaction.club.name}`,
        userId: clubTransaction.pilot.uid,
        amount: clubTransaction.totalCost,
        email: clubTransaction.pilot.email,
        paymentType: paymentMethod === "ach" ? "us_bank_account" : "card",
      }).then((result) => {
        if (result?.data) {
          setPaymentIntentSecret(result.data.secret);
          setPaymentIntent(result.data);
        } else {
          console.error("Unable to create Stripe payment intent", result);
        }
      });
    }
  }, [clubTransaction, fees]);

  const loader = "auto";

  return paymentIntent && paymentIntentSecret && clubTransaction && stripe ? (
    <Elements
      stripe={stripe}
      options={{ clientSecret: paymentIntentSecret, loader }}
    >
      <InvoicePaymentDetails
        clubTransaction={clubTransaction}
        paymentIntent={paymentIntent}
        paymentMethod={paymentMethod}
        {...rest}
      />
    </Elements>
  ) : null;
}
