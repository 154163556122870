// @mui material components
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";

export default styled(Box)(({ theme }) => {
  const { palette, typography, functions, boxShadows } = theme;

  const {
    white,
    dark,
    success,
    grey,
    gradients,
    primary,
    pilotRefresher,
    intro,
    secondary,
    warning,
    maintenance,
    error,
  } = palette;
  const { size, fontWeightMedium, fontWeightBold } = typography;
  const { linearGradient, pxToRem } = functions;
  const { buttonBoxShadow } = boxShadows;

  return {
    height: "100%",

    ".fc thead": {
      position: "sticky",
      top: "80px",
      zIndex: 2,
    },

    ".btn": {
      "--bs-btn-font-size": "0.8rem",
      "--bs-btn-font-weight": 400,
      "--bs-btn-line-height": 1.5,
      "--bs-btn-color": white.main,
      "--bs-btn-bg": primary.main,
      "--bs-btn-border-color": "transparent",
      "--bs-btn-hover-border-color": "transparent",
      "--bs-btn-disabled-opacity": 0.65,
      textTransform: "capitalize",
    },

    ".react-datepicker__navigation": {
      top: "14px",
    },

    ".btn-primary": {
      "--bs-btn-bg": secondary.main,
      "--bs-btn-color": white.main,
      "--bs-btn-border-color": secondary.main,
      "--bs-btn-hover-color": white.main,
      "--bs-btn-hover-bg": secondary.light,
      "--bs-btn-hover-border-color": secondary.light,
      "--bs-btn-focus-shadow-rgb": "49, 132, 253",
      "--bs-btn-active-color": white.main,
      "--bs-btn-active-bg": secondary.dark,
      "--bs-btn-active-border-color": secondary.main,
      "--bs-btn-active-shadow": "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
      "--bs-btn-disabled-color": white.main,
      "--bs-btn-disabled-bg": secondary.main,
      "--bs-btn-disabled-border-color": secondary.main,
    },

    ".fc-toolbar-chunk>div .fc-toolbar-title": {
      alignSelf: "center",
    },

    ".fc .fc-timeline-slot-minor": {
      border: `1px dashed ${grey[200]}`,
    },

    ".fc-theme-bootstrap5 .fc-scrollgrid": {
      border: `none`,
    },
    ".fc-theme-bootstrap5 td": {
      border: `1px solid ${grey[300]}`,
    },
    ".fc-theme-bootstrap5 th": {
      borderLeft: `1px solid ${grey[300]}`,
    },

    ".fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky th:first-of-type":
      {
        borderLeft: `none`,
      },

    ".fc .fc-resource-timeline-divider": {
      border: `0px solid ${grey[300]}`,
      width: "2px",
      backgroundColor: grey[300],
    },

    ".fc .fc-scrollgrid-section-footer": {
      display: "none",
    },

    "& .fc": {
      textAlign: "left",
    },

    "& .fc table": {
      fontSize: size.sm,
    },

    "& .fc-toolbar-chunk": {
      margin: "0 20px",
    },

    "& .fc-toolbar-chunk > div": {
      display: "inline-flex",

      ".fc-toolbar-title": {
        margin: "0 30px",
      },
    },

    "& .fc-media-screen": {
      height: "100%",
      color: dark.main,
    },

    "& .fc-theme-standard .fc-scrollgrid": {
      border: "none",
    },

    "& .fc-theme-standard thead tr th": {
      borderLeft: "none",
      borderRight: "none",
    },

    "& .fc-theme-standard td, .fc-theme-standard th": {
      borderColor: grey[300],
    },

    "& .fc-theme-standard td.fc-timeline-slot-minor": {
      borderStyle: "dashed",
      borderColor: grey[200],
    },

    "& .fc th": {
      textAlign: "left",
    },

    "& .fc .fc-col-header-cell-cushion": {
      fontSize: size.xs,
      fontWeight: fontWeightMedium,
      color: grey[500],
    },

    "& .fc .fc-daygrid-day-number": {
      color: grey[700],
      fontSize: size.xs,
      fontWeight: fontWeightMedium,
      width: "100%",
      textAlign: "center",
    },

    "& .fc-scrollgrid-section.fc-scrollgrid-section-header > td": {
      border: "none",
    },

    "& .fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky > *": {
      borderRight: "none",
    },
    "& .fc-scrollgrid-section.fc-scrollgrid-section-body > td:first-of-type": {
      borderRadius: "0px 0 0 1rem",
      overflow: "hidden",
      // borderLeft: "none",
    },
    "& .fc-scrollgrid-section.fc-scrollgrid-section-body > td:last-of-type": {
      borderRadius: "0px 0 1rem 0px",
      overflow: "hidden",
      // borderRight: "none",
    },

    "& .fc-scrollgrid-section.fc-scrollgrid-section-body.fc-scrollgrid-section-liquid > td":
      {
        border: "none",
      },

    "& .fc-scrollgrid-sync-table": {
      height: "auto !important",
    },

    "& .fc .fc-view-harness-active > .fc-view": {
      position: "static",
      height: "100%",
    },

    "& .fc .fc-scroller-liquid-absolute": {
      position: "static",
    },

    "& .fc-daygrid-event": {
      margin: `${pxToRem(0.5)} ${pxToRem(2)}`,
      border: "none",
      borderRadius: pxToRem(5.6),
      fontSize: size.xs,
      fontWeight: fontWeightMedium,
    },

    "& .fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events": {
      minHeight: pxToRem(32),
    },

    "& .fc-event-title": {
      padding: `${pxToRem(3)} ${pxToRem(4.8)} ${pxToRem(2.5)} !important`,
    },

    "& .fc-button, .fc-today-button, .fc-button:disabled": {
      backgroundColor: `${secondary.main} !important`,
      borderColor: `${secondary.main} !important`,
      fontSize: `${size.sm} !important`,
      boxShadow: `${buttonBoxShadow.main} !important`,
      opacity: "1 !important",
      transition: `all 150ms ease-in`,

      "&:hover, &:focus, &:active": {
        transform: "scale(1.02)",
        boxShadow: `${buttonBoxShadow.stateOf} !important`,
        backgroundColor: `${secondary.main} !important`,
        borderColor: `${secondary.main} !important`,
      },
    },

    "& .fc .fc-button .fc-icon": {
      fontSize: size.sm,
    },

    "& .fc-toolbar-title": {
      fontSize: `${size.lg} !important`,
      fontWeight: `${fontWeightBold} !important`,
    },

    "& .fc .fc-resource-timeline .fc-resource-group:not([rowspan])": {
      background: grey[200],
      borderColor: grey[400],
    },

    "& .fc .fc-non-business": {
      background: `rgba(168, 168, 168, 0.4)`,
    },

    "& .fc tr:nth-of-type(even)": {
      backgroundColor: "#3a3a3c07",
    },

    "& .fc-event": {
      cursor: "pointer",
      borderRadius: "3px",
      borderColor: "transparent",
    },

    "& .event-maintenance": {
      backgroundColor: warning.main,
      "& *": {
        color: dark.main,
      },
    },

    "& .event-maintenance-dispatched": {
      background: `repeating-linear-gradient(
        -45deg,
        ${maintenance.main},
        ${maintenance.main} 6px,
        ${maintenance.light1} 6px,
        ${maintenance.light1} 13px
      )`,
      "& *": {
        color: dark.main,
      },
    },

    "& .event-maintenance-dispatched-other": {
      background: `repeating-linear-gradient(
        -45deg,
        ${maintenance.main},
        ${maintenance.main} 6px,
        ${maintenance.light1} 6px,
        ${maintenance.light1} 13px
      )`,
      "& *": {
        color: dark.main,
      },
    },

    "& .event-maintenance-completed": {
      backgroundColor: "#bb9c41",
      "& *": {
        color: white.main,
      },
    },
    "& .event-maintenance-completed-other": {
      backgroundColor: "#dcca95",
      "& *": {
        color: white.main,
      },
    },

    "& .event-checkride": {
      backgroundColor: error.light,
      "& *": {
        color: white.main,
      },
    },

    "& .event-pilotRefresher": {
      background: `repeating-linear-gradient(
        -45deg,
        ${pilotRefresher.main},
        ${pilotRefresher.main} 6px,
        ${pilotRefresher.light} 6px,
        ${pilotRefresher.light} 13px
      )`,
      "& *": {
        color: white.main,
      },
    },
    "& .event-pilotRefresher-other": {
      background: `repeating-linear-gradient(
        -45deg,
        ${pilotRefresher.shadow},
        ${pilotRefresher.shadow} 6px,
        ${pilotRefresher.shadow2} 6px,
        ${pilotRefresher.shadow2} 13px
      )`,
      "& *": {
        color: primary.dark,
      },
    },

    "& .event-checkout": {
      background: `repeating-linear-gradient(
        -45deg,
        ${primary.main},
        ${primary.main} 6px,
        ${primary.light} 6px,
        ${primary.light} 13px
      )`,
      "& *": {
        color: white.main,
      },
    },
    "& .event-checkout-other": {
      background: `repeating-linear-gradient(
        -45deg,
        ${primary.shadow},
        ${primary.shadow} 6px,
        ${primary.shadow2} 6px,
        ${primary.shadow2} 13px
      )`,
      "& *": {
        color: primary.dark,
      },
    },

    "& .event-unavailable": {
      backgroundColor: `rgba(168, 168, 168, 0.4)`,
      border: "none",
      opacity: "1",
      borderRadius: "0px",
      "& *": {
        color: white.main,
      },
    },

    "& .event-requestOnly": {
      background: `repeating-linear-gradient(
        -45deg,
        ${grey[200]},
        ${grey[200]} 6px,
        rgba(0, 0, 0, 0) 6px,
        rgba(0, 0, 0, 0) 13px
      )`,
      opacity: "0.6",
      "& *": {
        color: dark.main,
      },
    },

    "& .event-unconfirmed": {
      // background: '#329baa35',
      background: `repeating-linear-gradient(
        -45deg,
        #329baa15,
        #329baa15 6px,
        #329baa25 6px,
        #329baa25 13px
        )`,
      border: `2px dashed ${primary.main}`,
      "& *": {
        color: dark.main,
      },
    },

    "& .event-dispatched": {
      background: `repeating-linear-gradient(
        -45deg,
        ${success.main},
        ${success.main} 6px,
        ${success.light} 6px,
        ${success.light} 13px
      )`,
      "& *": {
        color: white.main,
      },
    },
    "& .event-dispatched-other": {
      background: `repeating-linear-gradient(
        -45deg,
        ${success.shadow},
        ${success.shadow} 6px,
        ${success.shadow2} 6px,
        ${success.shadow2} 13px
        )`,
      // border: `1px solid ${success.main}`,
      "& *": {
        color: success.dark,
      },
    },

    "& .event-reservation": {
      cursor: "grab",
      backgroundColor: primary.main,
      "& *": {
        color: white.main,
      },
    },
    "& .event-reservation-other": {
      backgroundColor: primary.shadow,
      // border: `1px solid ${primary.main}`,
      "& *": {
        color: primary.dark,
      },
    },

    "& .event-introFlight": {
      cursor: "grab",
      backgroundColor: intro.main,
      "& *": {
        color: white.main,
      },
    },
    "& .event-introFlight-other": {
      backgroundColor: intro.shadow,
      // border: `1px solid ${primary.main}`,
      "& *": {
        color: intro.dark,
      },
    },

    "& .event-completed": {
      backgroundColor: grey[600],
      "& *": {
        color: white.main,
      },
    },
    "& .event-completed-other": {
      backgroundColor: "#6c757d90",
      border: `1px solid '#6c757d'`,
      "& *": {
        color: white.main,
      },
    },

    "& .event-error": {
      backgroundImage: linearGradient(
        gradients.error.main,
        gradients.error.state
      ),
      "& *": {
        color: white.main,
      },
    },

    "& .event-light": {
      backgroundImage: linearGradient(
        gradients.light.main,
        gradients.light.state
      ),

      "& *": {
        color: dark.main,
      },
    },

    "& .event-dark": {
      backgroundImage: linearGradient(
        gradients.dark.main,
        gradients.dark.state
      ),
      "& *": {
        color: white.main,
      },
    },
  };
});
