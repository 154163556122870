import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

// Soft UI Dashboard PRO React base styles
import borders from "src/assets/theme/base/borders";
import colors from "src/assets/theme/base/colors";

// Images
import logo from "src/assets/images/logo.png";

import { format } from "date-fns";
import { formatCurrency } from "src/features/utils";

function Invoice({ handleClose, invoice }) {
  const { borderWidth } = borders;
  const { light } = colors;
  const borderBottom = `${borderWidth[1]} solid ${light.main}`;

  const paidAmount =
    invoice?.payments?.reduce(
      (acc, payment) =>
        payment?.status === "succeeded"
          ? acc + (payment?.amount ?? 0) / 100
          : acc,
      0
    ) || 0;

  return (
    <SoftBox
      mt={{
        xs: 4,
        md: 10,
      }}
      mb={{
        xs: 4,
        md: 8,
      }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={10} md={8}>
          <Card>
            {/* Invoice header */}
            <SoftBox p={3}>
              <Grid container justifyContent="space-between">
                <Grid item xs={12} md={4}>
                  <SoftBox
                    component="img"
                    src={logo}
                    width="65%"
                    p={1}
                    mb={1}
                  />
                  <SoftTypography variant="h6" fontWeight="medium">
                    8300 Grey Abbey Pl, Raleigh, NC, 27615
                  </SoftTypography>
                  <SoftBox mt={1} mb={2}>
                    <SoftTypography
                      display="block"
                      variant="body2"
                      color="secondary"
                    >
                      tel: +1 (984) 207-6115
                    </SoftTypography>
                  </SoftBox>
                </Grid>
                <Grid item xs={12} md={7} lg={3}>
                  <SoftBox
                    width="100%"
                    textAlign={{
                      xs: "left",
                      md: "right",
                    }}
                    mt={6}
                  >
                    <SoftBox mt={1}>
                      <SoftTypography variant="h6" fontWeight="medium">
                        Billed to: {invoice.pilot?.displayName}
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox mb={1}>
                      <SoftTypography variant="body2" color="secondary">
                        {invoice.pilot?.address?.addressLine1}
                        <br />
                        {invoice.pilot?.address?.addressLine2 && (
                          <span>
                            {invoice.pilot.address.addressLine2}
                            <br />
                          </span>
                        )}
                        {invoice.pilot?.address?.city}{" "}
                        {invoice.pilot?.address?.state}
                        <br />
                        {invoice.pilot?.address?.zip}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </Grid>
              </Grid>
              <SoftBox
                mt={{
                  xs: 5,
                  md: 10,
                }}
              >
                <Grid container justifyContent="space-between">
                  <Grid item xs={12} md={4}>
                    <SoftTypography
                      variant="h6"
                      color="secondary"
                      fontWeight="medium"
                    >
                      Invoice no
                    </SoftTypography>
                    <SoftTypography variant="h5" fontWeight="medium">
                      {invoice.id}
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={12} md={7} lg={5}>
                    <SoftBox
                      width="100%"
                      display="flex"
                      flexDirection={{
                        xs: "column",
                        md: "row",
                      }}
                      alignItems={{
                        xs: "flex-start",
                        md: "center",
                      }}
                      textAlign={{
                        xs: "left",
                        md: "right",
                      }}
                      mt={{
                        xs: 3,
                        md: 0,
                      }}
                    >
                      <SoftBox width="50%">
                        <SoftTypography
                          variant="h6"
                          color="secondary"
                          fontWeight="medium"
                        >
                          Invoice date:
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox width="50%">
                        <SoftTypography variant="h6" fontWeight="medium">
                          {format(invoice.createdAt.toDate(), "MM/dd/yyyy")}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      width="100%"
                      display="flex"
                      flexDirection={{
                        xs: "column",
                        md: "row",
                      }}
                      alignItems={{
                        xs: "flex-start",
                        md: "center",
                      }}
                      textAlign={{
                        xs: "left",
                        md: "right",
                      }}
                    >
                      <SoftBox width="50%">
                        <SoftTypography
                          variant="h6"
                          color="secondary"
                          fontWeight="medium"
                        >
                          Due date:
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox width="50%">
                        <SoftTypography variant="h6" fontWeight="medium">
                          {format(invoice.createdAt.toDate(), "MM/dd/yyyy")}
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>

            {/* Invoice table */}
            <SoftBox p={3}>
              <SoftBox width="100%" overflow="auto">
                <Table
                  sx={{
                    minWidth: "32rem",
                  }}
                >
                  <SoftBox component="thead">
                    <TableRow>
                      <SoftBox
                        component="th"
                        width={{
                          xs: "45%",
                          md: "50%",
                        }}
                        py={1.5}
                        px={1}
                        textAlign="left"
                        borderBottom={borderBottom}
                      >
                        <SoftTypography
                          variant="h6"
                          color="text"
                          fontWeight="medium"
                        >
                          Item
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox
                        component="th"
                        py={1.5}
                        pl={3}
                        pr={1}
                        textAlign="left"
                        borderBottom={borderBottom}
                      >
                        <SoftTypography
                          variant="h6"
                          color="text"
                          fontWeight="medium"
                        >
                          Qty
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox
                        component="th"
                        py={1.5}
                        pl={3}
                        pr={1}
                        textAlign="left"
                        borderBottom={borderBottom}
                      >
                        <SoftTypography
                          variant="h6"
                          color="text"
                          fontWeight="medium"
                        >
                          Rate
                        </SoftTypography>
                      </SoftBox>
                      <SoftBox
                        component="th"
                        py={1.5}
                        pl={3}
                        pr={1}
                        textAlign="left"
                        borderBottom={borderBottom}
                      >
                        <SoftTypography
                          variant="h6"
                          color="text"
                          fontWeight="medium"
                        >
                          Amount
                        </SoftTypography>
                      </SoftBox>
                    </TableRow>
                  </SoftBox>
                  <TableBody>
                    {invoice.booking?.extendedProps?.type?.value ===
                    "introFlight" ? (
                      <>
                        <TableRow>
                          <SoftBox
                            component="td"
                            textAlign="left"
                            p={1}
                            borderBottom={borderBottom}
                          >
                            <SoftTypography variant="body2" color="text">
                              {invoice?.booking?.extendedProps?.type?.label}
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox
                            component="td"
                            textAlign="left"
                            py={1}
                            pr={1}
                            pl={3}
                            borderBottom={borderBottom}
                          >
                            <SoftTypography variant="body2" color="text">
                              1
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox
                            component="td"
                            textAlign="left"
                            py={1}
                            pr={1}
                            pl={3}
                            borderBottom={borderBottom}
                          >
                            <SoftTypography variant="body2" color="text">
                              {formatCurrency(invoice?.flightCost ?? 0)}
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox
                            component="td"
                            textAlign="left"
                            py={1}
                            pr={1}
                            pl={3}
                            borderBottom={borderBottom}
                          >
                            <SoftTypography variant="body2" color="text">
                              {formatCurrency(invoice?.flightCost ?? 0)}
                            </SoftTypography>
                          </SoftBox>
                        </TableRow>
                        {invoice?.totalCost - invoice?.flightCost > 0 && (
                          <TableRow>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              p={1}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text">
                                Service Fee
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text">
                                1
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text">
                                {formatCurrency(
                                  (invoice?.totalCost ?? 0) -
                                    (invoice?.flightCost ?? 0)
                                )}
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text">
                                {formatCurrency(
                                  (invoice?.totalCost ?? 0) -
                                    (invoice?.flightCost ?? 0)
                                )}
                              </SoftTypography>
                            </SoftBox>
                          </TableRow>
                        )}
                      </>
                    ) : (
                      <>
                        {invoice.aircraft ? (
                          <TableRow>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              p={1}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text">
                                {invoice?.aircraft?.tailNumber} Rental Charge
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text">
                                {Math.round(
                                  (invoice?.bookingData?.totalHobbsHours ?? 0) *
                                    10
                                ) / 10}
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text">
                                {formatCurrency(
                                  invoice?.selectedRate?.hourlyRate ?? 0
                                )}
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text">
                                {formatCurrency(invoice?.flightCost ?? 0)}
                              </SoftTypography>
                            </SoftBox>
                          </TableRow>
                        ) : null}
                        {invoice.instructor &&
                        invoice?.bookingData?.instructionHours ? (
                          <TableRow>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              p={1}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text">
                                Flight Instruction Charge (
                                {invoice?.instructor?.displayName})
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text">
                                {Math.round(
                                  (invoice?.bookingData?.instructionHours ??
                                    0) * 10
                                ) / 10}
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text">
                                {formatCurrency(
                                  invoice?.instructor?.hourlyRate ?? 0
                                )}
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text">
                                {formatCurrency(invoice?.instructorCost ?? 0)}
                              </SoftTypography>
                            </SoftBox>
                          </TableRow>
                        ) : null}
                        {invoice.instructor &&
                        invoice?.bookingData?.instructionHoursGround ? (
                          <TableRow>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              p={1}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text">
                                Ground Instruction Charge (
                                {invoice?.instructor?.displayName})
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text">
                                {Math.round(
                                  (invoice?.bookingData
                                    ?.instructionHoursGround ?? 0) * 10
                                ) / 10}
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text">
                                {formatCurrency(
                                  invoice?.instructor?.hourlyRateGround ?? 0
                                )}
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text">
                                {formatCurrency(
                                  invoice?.instructorCostGround ?? 0
                                )}
                              </SoftTypography>
                            </SoftBox>
                          </TableRow>
                        ) : null}
                      </>
                    )}
                    {invoice.type === "accountFunding" ? (
                      <TableRow>
                        <SoftBox
                          component="td"
                          textAlign="left"
                          p={1}
                          borderBottom={borderBottom}
                        >
                          <SoftTypography variant="body2" color="text">
                            Account Funding
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          borderBottom={borderBottom}
                        >
                          <SoftTypography variant="body2" color="text">
                            1
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          borderBottom={borderBottom}
                        >
                          <SoftTypography variant="body2" color="text">
                            {formatCurrency(invoice?.fees?.totalCharge ?? 0)}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          borderBottom={borderBottom}
                        >
                          <SoftTypography variant="body2" color="text">
                            {formatCurrency(invoice?.fees?.totalCharge ?? 0)}
                          </SoftTypography>
                        </SoftBox>
                      </TableRow>
                    ) : null}
                    {invoice.membership ? (
                      <TableRow>
                        <SoftBox
                          component="td"
                          textAlign="left"
                          p={1}
                          borderBottom={borderBottom}
                        >
                          <SoftTypography variant="body2" color="text">
                            {invoice?.membership?.label} Membership Fee
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          borderBottom={borderBottom}
                        >
                          <SoftTypography variant="body2" color="text">
                            1
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          borderBottom={borderBottom}
                        >
                          <SoftTypography variant="body2" color="text">
                            {formatCurrency(
                              invoice?.membership?.cost?.amount ?? 0
                            )}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          borderBottom={borderBottom}
                        >
                          <SoftTypography variant="body2" color="text">
                            {formatCurrency(
                              invoice?.membership?.cost?.amount ?? 0
                            )}
                          </SoftTypography>
                        </SoftBox>
                      </TableRow>
                    ) : null}
                    {invoice.lineItems?.map((item) => (
                      <TableRow key={item.id}>
                        <SoftBox
                          component="td"
                          textAlign="left"
                          p={1}
                          borderBottom={borderBottom}
                        >
                          <SoftTypography variant="body2" color="text">
                            {item?.description || item?.item}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          borderBottom={borderBottom}
                        >
                          <SoftTypography variant="body2" color="text">
                            {item?.quantity || item?.qty}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          borderBottom={borderBottom}
                        >
                          <SoftTypography variant="body2" color="text">
                            {formatCurrency(item?.rate || item?.price || 0)}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          borderBottom={borderBottom}
                        >
                          <SoftTypography variant="body2" color="text">
                            {formatCurrency(item?.amount || item?.total || 0)}
                          </SoftTypography>
                        </SoftBox>
                      </TableRow>
                    ))}
                    {invoice?.fees?.costToPilot !== invoice?.totalCost ? (
                      <TableRow>
                        <SoftBox component="td" textAlign="left" p={1} />
                        <SoftBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                        />
                        <SoftBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          borderBottom={borderBottom}
                        >
                          <SoftTypography variant="body2">
                            Service Fee
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          borderBottom={borderBottom}
                        >
                          <SoftTypography variant="body2">
                            {formatCurrency(invoice?.fees?.applicationFee ?? 0)}
                          </SoftTypography>
                        </SoftBox>
                      </TableRow>
                    ) : null}
                    <TableRow>
                      <SoftBox component="td" textAlign="left" p={1} />
                      <SoftBox
                        component="td"
                        textAlign="left"
                        py={1}
                        pr={1}
                        pl={3}
                      />
                      <SoftBox
                        component="td"
                        textAlign="left"
                        py={1}
                        pr={1}
                        pl={3}
                        borderBottom={borderBottom}
                      >
                        <SoftTypography variant="h5">Total</SoftTypography>
                      </SoftBox>
                      <SoftBox
                        component="td"
                        textAlign="left"
                        py={1}
                        pr={1}
                        pl={3}
                        borderBottom={borderBottom}
                      >
                        <SoftTypography variant="h5">
                          {formatCurrency(invoice?.totalCost ?? 0)}
                        </SoftTypography>
                      </SoftBox>
                    </TableRow>
                    {paidAmount > 0 && (
                      <TableRow>
                        <SoftBox component="td" textAlign="left" p={1} />
                        <SoftBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                        />
                        <SoftBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          borderBottom={borderBottom}
                        >
                          <SoftTypography variant="h6">Paid</SoftTypography>
                        </SoftBox>
                        <SoftBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          borderBottom={borderBottom}
                        >
                          <SoftTypography variant="h6">
                            {formatCurrency(paidAmount)}
                          </SoftTypography>
                        </SoftBox>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </SoftBox>
            </SoftBox>

            {/* Invoice footer */}
            <SoftBox p={3} mt={7}>
              <Grid container>
                <Grid item xs={12} lg={8}>
                  <SoftTypography variant="h5" fontWeight="medium">
                    Thank you!
                  </SoftTypography>
                  <SoftBox mt={1} mb={2} lineHeight={0}>
                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      color="secondary"
                    >
                      If you encounter any issues related to the invoice you can
                      contact us at:
                    </SoftTypography>
                  </SoftBox>
                  <SoftTypography
                    component="span"
                    variant="h6"
                    fontWeight="medium"
                    color="secondary"
                  >
                    email:{" "}
                    <SoftTypography
                      component="span"
                      variant="h6"
                      fontWeight="medium"
                    >
                      support@preflightfbo.com
                    </SoftTypography>
                  </SoftTypography>
                </Grid>
                <Grid item xs={12} lg={4}>
                  <SoftBox
                    width="100%"
                    height={{
                      xs: "auto",
                      md: "100%",
                    }}
                    display="flex"
                    justifyContent={{
                      xs: "flex-start",
                      md: "flex-end",
                    }}
                    alignItems="flex-end"
                    mt={{
                      xs: 2,
                      md: 0,
                    }}
                  >
                    <SoftBox mr={3}>
                      <SoftButton
                        variant="gradient"
                        color="info"
                        onClick={() => window.print(this)}
                      >
                        print
                      </SoftButton>
                    </SoftBox>

                    <SoftButton
                      variant="outlined"
                      color="dark"
                      onClick={handleClose}
                    >
                      Close
                    </SoftButton>
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

export default Invoice;

Invoice.propTypes = {
  handleClose: PropTypes.func,
  invoice: PropTypes.object,
};
