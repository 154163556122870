import { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";

// @mui core components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import {
  getClubDocumentTypesCollection,
  getClubUserRoles,
} from "src/features/club/collections";

import {
  getTableColumns,
  getTableStandardColumns,
  TableActions,
} from "./tableColumns";
import DataTable from "src/components/Tables/DataTable";

import { AddDocumentTypeModal } from "src/modals/AddDocumentTypeModal";
import { query, where, updateDoc, getDocs } from "firebase/firestore";

import { standardDocumentTypes } from "src/config/localDocuments";

function Documents() {
  const { deleteData } = entityCrudUtils();
  const { selectedClubId } = useClubs();
  const [selectedDocumentType, setSelectedDocumentType] = useState(null);
  const [openAddDocumentType, setOpenAddDocumentType] = useState(false);

  const documentTypesRef = getClubDocumentTypesCollection(selectedClubId);
  const { data: documentTypes, isDataLoaded: documentTypesLoaded } =
    useRealtimeCollectionData(
      query(documentTypesRef, where("deleted", "==", false))
    );

  useEffect(() => {
    console.log("Loaded Document Types", documentTypes);
  }, [documentTypes]);

  const removeDocFromUserRole = async (docId) => {
    try {
      const querySnapshot = await getDocs(
        query(
          getClubUserRoles(selectedClubId),
          where("configuration.requiredDocuments", "array-contains", docId)
        )
      );

      querySnapshot.forEach(async (doc) => {
        const currentRequiredDocs =
          doc.data().configuration?.requiredDocuments ?? [];

        const updatedRequiredDocs = currentRequiredDocs.filter(
          (id) => id !== docId
        );
        await updateDoc(doc.ref, {
          configuration: { requiredDocuments: updatedRequiredDocs },
        });
      });
    } catch (error) {
      console.error("Error updating documents: ", error);
    }
  };

  const deleteDocumentType = (documentType) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button",
        confirmButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You will not be able to recover this document type! Existing users will remain on this membership plan until they are assigned a new one.",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        icon: "warning",
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteData({
            entity: getClubDocumentTypesCollection(selectedClubId),
            pathSegmentsArr: [documentType.id],
          });
          removeDocFromUserRole(documentType.id);
        }
      });
  };

  const handleTableActions = async (action, documentType) => {
    switch (action) {
      case TableActions.DELETE:
        deleteDocumentType(documentType);
        break;
      case TableActions.EDIT:
        setSelectedDocumentType(documentType);
        setOpenAddDocumentType(true);
        break;

      default:
        break;
    }
  };

  const handleAddDocumentTypeClose = () => {
    setOpenAddDocumentType(false);
    setSelectedDocumentType(null);
  };

  const tableColumns = useMemo(() => getTableColumns(handleTableActions), []);
  const tableColumnsStandard = useMemo(
    () => getTableStandardColumns(handleTableActions),
    []
  );

  return (
    <Card
      id="documents"
      sx={{
        overflow: "visible",
      }}
    >
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Documents</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Manage custom document types. Requirements for booking can be set in
          the user roles.
        </SoftTypography>
        <br />
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Custom documents are private and are not available to other
          clubs/schools a user might be a member of.
        </SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <SoftBox
          xs={12}
          padding={1}
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          <DataTable
            entriesPerPage={false}
            maxWidth="100%"
            table={{
              columns: tableColumnsStandard,
              rows: standardDocumentTypes,
            }}
          />
        </SoftBox>
        <SoftBox
          xs={12}
          padding={1}
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          {documentTypesLoaded && (
            <DataTable
              entriesPerPage={false}
              maxWidth="100%"
              table={{
                columns: tableColumns,
                rows: documentTypes,
              }}
            />
          )}
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} justifyContent="flex-end" display="flex">
            <SoftButton
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              onClick={() => setOpenAddDocumentType(true)}
            >
              Add Document Type
            </SoftButton>
          </Grid>
        </Grid>
      </SoftBox>
      <Modal
        open={openAddDocumentType}
        onClose={handleAddDocumentTypeClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddDocumentTypeModal
            handleClose={handleAddDocumentTypeClose}
            selectedDocumentType={selectedDocumentType}
          />
        </SoftBox>
      </Modal>
    </Card>
  );
}

export default Documents;
