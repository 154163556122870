import { Checkbox, Stack } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

interface RoleTypesProps {
  selectedRoles: string[];
  setRoleTypes: React.Dispatch<React.SetStateAction<string[]>>;
}

export const RoleTypes = ({ selectedRoles, setRoleTypes }: RoleTypesProps) => {
  // const { selectedClubId } = useClubs() as IUseClub;
  const roleTypes = ["Staff", "Instructor", "Mechanic"];

  return (
    <>
      <SoftTypography variant="h6" color="primary" my="16px">
        Role Types
      </SoftTypography>
      <Stack direction="column" gap="5px">
        {roleTypes.map((doc: any) => (
          <SoftBox
            display="flex"
            alignItems="center"
            sx={{ padding: "5px 0" }}
            ml={3}
            key={doc.id}
          >
            <Checkbox
              value={doc}
              checked={selectedRoles?.includes(doc)}
              onChange={(e) => {
                setRoleTypes((prev) => {
                  if (e.target.checked) return prev.concat(doc);
                  return prev.filter((d) => d !== doc);
                });
              }}
            />

            <SoftTypography variant="button">{doc}</SoftTypography>
          </SoftBox>
        ))}
      </Stack>
    </>
  );
};
