import { collection, collectionGroup, doc } from "firebase/firestore";
import { db } from "src/features/firebase/firestore/utils";

const getAircraftCollection = (clubId) =>
  collection(db, `clubs/${clubId}/aircraft`);

const getSimulatorsCollection = (clubId) =>
  collection(db, `clubs/${clubId}/simulators`);

const getAircraftSquawksCollection = (clubId, aircraftId) =>
  collection(db, `clubs/${clubId}/aircraft/${aircraftId}/squawks`);

const getAircraftSquawkLogsCollection = (clubId, aircraftId, squawkId) =>
  collection(
    db,
    `clubs/${clubId}/aircraft/${aircraftId}/squawks/${squawkId}/logs`
  );

const getAircraftSquawksCollectionGroup = () => collectionGroup(db, `squawks`);

const getAircraftRemindersCollection = (clubId, aircraftId) =>
  collection(db, `clubs/${clubId}/aircraft/${aircraftId}/maintenanceReminders`);

const getAircraftReminder = (clubId, aircraftId, reminderId) =>
  doc(
    db,
    `clubs/${clubId}/aircraft/${aircraftId}/maintenanceReminders/${reminderId}`
  );

const getAircraftRemindersCollectionGroup = () =>
  collectionGroup(db, `maintenanceReminders`);

const getAircraftReminderTemplatesCollection = (clubId) =>
  collection(db, `clubs/${clubId}/reminderTemplates`);

const getAircraftDocumentsCollection = (clubId, aircraftId) =>
  collection(db, `clubs/${clubId}/aircraft/${aircraftId}/documents`);

const getAircraftCheckoutsCollection = () => collectionGroup(db, `checkouts`);

const getAircraftById = (clubId, aircraftId) =>
  doc(db, `clubs/${clubId}/aircraft`, aircraftId);

export {
  getAircraftCollection,
  getSimulatorsCollection,
  getAircraftSquawksCollection,
  getAircraftSquawksCollectionGroup,
  getAircraftSquawkLogsCollection,
  getAircraftRemindersCollection,
  getAircraftRemindersCollectionGroup,
  getAircraftReminderTemplatesCollection,
  getAircraftDocumentsCollection,
  getAircraftCheckoutsCollection,
  getAircraftReminder,
  getAircraftById,
};
