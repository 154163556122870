import { useState } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftSelect from "src/components/SoftSelect";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";

import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

function ManageClub() {
  const { clubId } = useParams();
  const [importFrom, setImportFrom] = useState();
  const [importType, setImportType] = useState();
  const [importDocument, setImportDocument] = useState();
  const [documentData, setDocumentData] = useState(null);

  const onFileAdded = (e) => {
    if (e.target.files[0]) {
      setImportDocument(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setDocumentData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const importData = async () => {
    console.log(importFrom, importType, importDocument, documentData);
    const data = {
      clubId,
      importFrom,
      importType,
    };
    const storage = getStorage();
    if (importDocument) {
      const fileName = uuidv4() + importDocument.name;
      const storageRef = ref(storage, fileName);

      const fi = await uploadBytes(storageRef, importDocument);
      data.bucket = fi.ref.bucket;
      data.fileName = fileName;
      data.documentUrl = await getDownloadURL(ref(storage, fi.ref.name));
    }
    console.debug("Data:", data);

    try {
      const functions = getFunctions();
      const importDataFunction = httpsCallable(functions, "importdata-default");
      importDataFunction(data).then((result) => {
        console.log(result);
      });
    } catch (error) {
      console.error("IMPORT ERROR", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox pt={6} pb={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox p={3} lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Import Club Data
              </SoftTypography>
            </SoftBox>
            <SoftBox px={3} lineHeight={1}>
              <SoftBox
                className="SelectLabel"
                mb={1}
                ml={0.5}
                lineHeight={0}
                display="inline-block"
              >
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Import From
                </SoftTypography>
              </SoftBox>
              <SoftSelect
                label="Import From"
                onChange={(e) => setImportFrom(e.value)}
                options={[{ label: "Flight Schedule Pro", value: "fsp" }]}
              />
            </SoftBox>
            <SoftBox px={3} lineHeight={1}>
              <SoftBox
                className="SelectLabel"
                mb={1}
                ml={0.5}
                lineHeight={0}
                display="inline-block"
              >
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Data Type
                </SoftTypography>
              </SoftBox>
              <SoftSelect
                label="Data Type"
                onChange={(e) => setImportType(e.value)}
                options={[
                  { label: "Aircraft", value: "aircraft" },
                  { label: "Users", value: "users" },
                  { label: "Users Phone Numbers", value: "usersPhoneNumber" },
                  { label: "Squawks", value: "squawks" },
                  { label: "Maintenance Reminders", value: "reminders" },
                  { label: "Bookings", value: "bookings" },
                  { label: "Account Balance", value: "balances" },
                  { label: "Check Ins", value: "checkins" },
                ]}
              />
            </SoftBox>
            <SoftBox px={3} lineHeight={1}>
              <SoftBox
                className="SelectLabel"
                mb={1}
                ml={0.5}
                lineHeight={0}
                display="inline-block"
              >
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Document
                </SoftTypography>
              </SoftBox>
              <SoftBox mb={2} lineHeight={1.25}>
                <SoftInput placeholder="0" type="file" onChange={onFileAdded} />
              </SoftBox>
            </SoftBox>
            <SoftBox mt={4} mb={1}>
              <SoftButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={importData}
              >
                Import
              </SoftButton>
            </SoftBox>
          </Card>
        </SoftBox>
        <SoftBox mb={3}>
          <Card>
            <SoftBox p={3} lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                {clubId}
              </SoftTypography>
              <SoftTypography
                variant="button"
                fontWeight="regular"
                color="text"
              >
                A lightweight, extendable, dependency-free javascript HTML table
                plugin.
              </SoftTypography>
            </SoftBox>
            {/* <DataTable table={[]} /> */}
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ManageClub;
