// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftTypography from "src/components/SoftTypography";

function DefaultCell({ value, suffix, textTransform }) {
  return (
    <SoftTypography
      variant="caption"
      fontWeight="medium"
      textTransform={textTransform}
      color="text"
    >
      {value}
      {suffix && (
        <SoftTypography variant="caption" fontWeight="medium" color="secondary">
          &nbsp;&nbsp;{suffix}
        </SoftTypography>
      )}
    </SoftTypography>
  );
}

// Setting default values for the props of DefaultCell
DefaultCell.defaultProps = {
  suffix: "",
};

// Typechecking props for the DefaultCell
DefaultCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  textTransform: PropTypes.string,
};

export default DefaultCell;
