import { useEffect, useState } from "react";
import ModalContainer from "src/components/ModalContainer";
import PropTypes from "prop-types";
import { getUserWithId } from "src/features/user/utils";

import MemberInfo from "src/pages/people/manage-members/MemberInfo";

export function UserInfoModal({ handleClose, userId }) {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const getUserData = async () => {
      const userDoc = await getUserWithId(userId);
      setUser(userDoc.data());
    };
    getUserData();
  }, [userId]);

  if (!user) return null;

  return (
    <ModalContainer handleClose={handleClose}>
      <MemberInfo member={user} />
    </ModalContainer>
  );
}

UserInfoModal.propTypes = {
  handleClose: PropTypes.func,
  userId: PropTypes.string.isRequired,
};
