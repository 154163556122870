import { Drawer, Stack } from "@mui/material";
import BaseLayout from "src/components/BaseLayout";
import SoftTypography from "src/components/SoftTypography";
import { useEffect, useMemo, useState } from "react";

import { useClubs } from "src/features/club/ClubProvider";
import { IUseClub } from "src/interfaces";
import { useClickOutside } from "src/hooks/useClickOutside";
import SoftButton from "src/components/SoftButton";
import { DrawerContent } from "./components/DrawerContent";
import { useUser } from "src/features/user/UserProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { getClubUserRoles } from "src/features/club/collections";
import { usePromiseLoading } from "src/hooks/usePromiseLoading";
import SoftBox from "src/components/SoftBox";
import DataTable from "src/components/Tables/DataTable";
import ActionsCell from "src/components/ActionsCell";
import {
  IRole,
  systemPermissions,
  systemPermissionsType,
} from "src/interfaces/roles/role.interface";
import WithPermissionWrapper from "src/components/WithPermissions/WithPermissionWrapper";
import { openModal$ } from "src/modals/modalConfiguration";

const roleColumns = [
  {
    Header: "Role Name",
    accessor: "label",
  },
  {
    Header: "# Users Assigned",
    width: "25%",
    Cell: ({ row: { original } }: { row: any }) => (
      <div>
        {original.summary}{" "}
        <SoftButton
          onClick={(e) => {
            e.stopPropagation();
            openModal$.next({
              modalName: "ROLE_MANAGER",
              modalProps: { roleId: original.id },
            });
          }}
          variant="text"
          color="primary"
          sx={{ height: "20px", marginLeft: "10px" }}
        >
          Manage
        </SoftButton>
      </div>
    ),
  },
  {
    Header: "Date",
    width: "15%",
    Cell: ({ row: { original } }: { row: any }) => {
      const { createdAt } = original;
      return createdAt.toDate().toDateString();
    },
  },
  {
    Header: "Actions",
    accessor: "actions",
    width: "10%",
  },
];

function UserRoles() {
  const [activeTab, setActiveTab] = useState("");
  const { addData, updateData } = entityCrudUtils();
  const [rows, seRows] = useState<any[]>([]);
  const [selectedRole, setSelectedRole] = useState<IRole | null>(null);
  const [drawerState, setDrawerState] = useState({
    isOpen: false,
    intent: "",
  });
  const { clubUserRoles, selectedClubId, clubUserRolesUserSummary } =
    useClubs() as IUseClub;
  const { userId } = useUser();
  const toggleModal =
    (state: boolean, intent = "") =>
    () =>
      setDrawerState({ isOpen: state, intent });
  const closeModal = () => {
    toggleModal(false)();
  };
  const openModal = (intent: string) => toggleModal(true, intent)();
  const modalRef = useClickOutside<HTMLDivElement>(closeModal);

  const tabs = useMemo(() => {
    return clubUserRoles.map((ur) => ur.label);
  }, [clubUserRoles]);

  useEffect(() => {
    if (!activeTab) setActiveTab(tabs[0]);
  }, [activeTab, tabs]);

  useEffect(() => {
    const rowData: any[] = [];
    clubUserRoles.forEach((role) => {
      rowData.push({
        ...role,
        summary: clubUserRolesUserSummary[role.id]?.count ?? 0,
        actions: (
          <ActionsCell
            onEdit={() => fnEdit(role)}
            onDelete={() => fnDelete(role)}
          />
        ),
      });
    });
    seRows(rowData.sort((a, b) => a.label.localeCompare(b.label)));
  }, [clubUserRoles, clubUserRolesUserSummary]);

  const handleSubmit = async ({
    rolename,
    permissions,
    userRole,
  }: {
    rolename: string;
    permissions: systemPermissionsType[];
    userRole?: IRole;
  }) => {
    const now = new Date();
    delete (userRole as any)?.["actions"];
    const firebasePermission = {
      ...(userRole ?? {}),
      label: rolename,
      canDelete:
        userRole && Object.keys(userRole).includes("canDelete")
          ? userRole.canDelete
          : true,
      createdAt: userRole?.createdAt ?? now,
      permissions: permissions.map((p) => {
        const existingPermission = userRole?.permissions?.find(
          (fbP) => fbP.key === p
        );
        return {
          grantedAt: existingPermission?.grantedAt ?? now,
          grantedBy: existingPermission?.grantedBy ?? userId,
          key: p,
        };
      }),
    };
    try {
      if (userRole?.id) {
        await updateData(
          {
            entity: getClubUserRoles(selectedClubId),
            pathSegmentsArr: [userRole.id],
          },
          firebasePermission
        );
      } else {
        await addData(getClubUserRoles(selectedClubId), firebasePermission);
      }
    } catch (error) {
      // TODO: Handle error
      console.error("error", error);
    } finally {
      closeModal();
    }
  };

  const { caller: fnSubmit, isLoading } = usePromiseLoading({
    fn: handleSubmit,
  });

  const handleEditRole = (row: any) => {
    setSelectedRole(row);
    openModal("edit");
  };

  const handleDelete = async (userRole: IRole) => {
    // TODO: What to do with users with that role?
    await updateData(
      {
        entity: getClubUserRoles(selectedClubId),
        pathSegmentsArr: [userRole.id],
      },
      { deleted: true }
    );
  };

  const { caller: fnEdit, isLoading: isEditingLoading } = usePromiseLoading({
    fn: handleEditRole,
  });

  const { caller: fnDelete, isLoading: isDeleteLoading } = usePromiseLoading({
    fn: handleDelete,
  });

  return (
    <BaseLayout stickyNavbar>
      <Stack
        direction="column"
        sx={{
          backgroundColor: "white !important",
          padding: "10px 20px",
          borderRadius: "10px",
        }}
      >
        <Stack direction="row" justifyContent="space-between">
          <SoftTypography>Role Manager</SoftTypography>
          <Stack direction="row" gap="20px">
            <SoftButton onClick={() => openModal("create")} color="dark">
              +Add New Role
            </SoftButton>
          </Stack>
        </Stack>

        <SoftBox
          // xs={12}
          padding={1}
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          {clubUserRoles.length < 1 ? (
            <SoftBox p={2}>
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <SoftTypography variant="button" fontWeight="light">
                  {clubUserRoles ? (
                    <i>Loading Roles...</i>
                  ) : (
                    "No User Roles Found"
                  )}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          ) : (
            <DataTable
              entriesPerPage={{ defaultValue: 20, entries: [10, 20, 50] }}
              canSearch
              table={{
                columns: roleColumns,
                rows,
              }}
              onRowSelect={handleEditRole}
              selectedRowId={selectedRole?.id}
            />
          )}
        </SoftBox>

        <Drawer
          anchor="right"
          hideBackdrop
          variant="temporary"
          ModalProps={{
            keepMounted: false,
          }}
          ref={modalRef}
          open={drawerState.isOpen}
          onClose={closeModal}
          PaperProps={{ sx: { minWidth: "500px", padding: "10px 15px" } }}
        >
          <DrawerContent
            onClose={closeModal}
            onSubmit={fnSubmit}
            isEdit={drawerState.intent === "edit"}
            role={
              drawerState.intent === "edit" && selectedRole
                ? selectedRole
                : undefined
            }
            rolename={selectedRole?.label}
            isLoading={isLoading || isDeleteLoading || isEditingLoading}
            onDelete={fnDelete}
          />
        </Drawer>
      </Stack>
    </BaseLayout>
  );
}
export default WithPermissionWrapper(
  systemPermissions.VIEW_ROLES_SETTINGS,
  UserRoles
);
