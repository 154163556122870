import { collection, doc } from "firebase/firestore";
import { db } from "src/features/firebase/firestore/utils";

const getClubBookingsCollection = (clubId, locationId) =>
  collection(db, `clubs/${clubId}/locations/${locationId}/bookings`);

const getClubIntroFlightsCollection = (clubId, locationId) =>
  collection(db, `clubs/${clubId}/locations/${locationId}/introFlights`);

const getClubMeetingRoomsCollection = (clubId, locationId) =>
  collection(db, `clubs/${clubId}/locations/${locationId}/meetingRooms`);

const getClubBookingAuditTrailCollection = (clubId, locationId, bookingId) =>
  collection(
    db,
    `clubs/${clubId}/locations/${locationId}/bookings/${bookingId}/auditTrail`
  );

const getClubInstructorPreferencesCollection = (clubId, locationId) => {
  return collection(db, `clubs/${clubId}/locations/${locationId}/instructors`);
};

const getClubsCollection = () => collection(db, "clubs");

const getClubLocationsCollection = (clubId) =>
  collection(db, `clubs/${clubId}/locations`);

const getClubMembershipsCollection = (clubId) =>
  collection(db, `clubs/${clubId}/membershipPlans`);

const getClubDocumentTypesCollection = (clubId) =>
  collection(db, `clubs/${clubId}/documentTypes`);

const getClubEventsCollection = (clubId) =>
  collection(db, `clubs/${clubId}/events`);

const getClubCustomRatesCollection = (clubId) =>
  collection(db, `clubs/${clubId}/rentalRates`);

const getClubAccountsCollection = (clubId) =>
  collection(db, `clubs/${clubId}/accounts`);

const getClubWorkOrderCollection = (clubId) =>
  collection(db, `clubs/${clubId}/workOrders`);

const getClubWorkOrderColumnCollection = (clubId) =>
  collection(db, `clubs/${clubId}/workOrderColumns`);

const getClubInvoices = (clubId) => collection(db, `clubs/${clubId}/invoices`);

const getClubInvoiceById = (clubId, invoiceId) =>
  doc(db, `clubs/${clubId}/invoices`, invoiceId);

const getClubUserRoles = (clubId) =>
  collection(db, `clubs/${clubId}/userRoles`);

export {
  getClubLocationsCollection,
  getClubsCollection,
  getClubInstructorPreferencesCollection,
  getClubIntroFlightsCollection,
  getClubBookingsCollection,
  getClubBookingAuditTrailCollection,
  getClubMembershipsCollection,
  getClubDocumentTypesCollection,
  getClubMeetingRoomsCollection,
  getClubEventsCollection,
  getClubCustomRatesCollection,
  getClubAccountsCollection,
  getClubWorkOrderCollection,
  getClubWorkOrderColumnCollection,
  getClubInvoices,
  getClubInvoiceById,
  getClubUserRoles,
};
