import SoftBox from "src/components/SoftBox";
import { Card, CardActionArea, Divider } from "@mui/material";
import SoftTypography from "src/components/SoftTypography";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { format } from "date-fns";
import PersonIcon from "@mui/icons-material/Person";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { groupBy } from "lodash-es";

export function BookingSelector({ bookings, onSelectBooking }) {
  if (!bookings) return null;
  const groups = groupBy(bookings, (booking) =>
    format(booking.start.toDate(), "eee, MMM do yyyy")
  );
  return (
    <SoftBox width="100%">
      <SoftTypography variant="h4" fontWeight="bold" textAlign="center" mb={2}>
        Select a booking
      </SoftTypography>
      <Divider />
      {Object.keys(groups).map((date) => (
        <>
          <SoftBox display="flex" width="100%" alignItems="center">
            <SoftTypography variant="h5" verticalAlign="middle" my={2}>
              {date}
            </SoftTypography>
            <SoftTypography variant="p" verticalAlign="middle" my={2} mx={1}>
              bookings
            </SoftTypography>
            <ArrowDropDownIcon fontSize="medium" />
          </SoftBox>

          {groups[date].map((booking) => (
            <SoftBox my={1} border="1px solid #00000033" borderRadius="15px">
              <Card cursor="pointer" onClick={() => onSelectBooking(booking)}>
                <CardActionArea>
                  <SoftBox
                    p={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {booking?.extendedProps?.aircraft?.label && (
                      <SoftBox
                        m={2}
                        display="flex"
                        alignItems="center"
                        width="25%"
                      >
                        <AirplanemodeActiveIcon
                          color="primary"
                          fontSize="small"
                        />
                        <SoftTypography
                          variant="h6"
                          fontWeight="bold"
                          verticalAlign="middle"
                          mx={1}
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                        >
                          {booking?.extendedProps?.aircraft?.label || "Ground"}
                        </SoftTypography>
                      </SoftBox>
                    )}
                    {!booking?.extendedProps?.aircraft?.label &&
                      booking?.extendedProps?.instructor?.label && (
                        <SoftBox
                          m={2}
                          display="flex"
                          alignItems="center"
                          width="25%"
                        >
                          <AssignmentIndIcon color="primary" fontSize="small" />
                          <SoftTypography
                            variant="h6"
                            fontWeight="bold"
                            verticalAlign="middle"
                            mx={1}
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                          >
                            {booking?.extendedProps?.instructor?.label ||
                              "Solo"}
                          </SoftTypography>
                        </SoftBox>
                      )}

                    <SoftBox display="flex" alignItems="center" width="25%">
                      <PersonIcon color="primary" fontSize="small" />
                      <SoftTypography
                        variant="h6"
                        fontWeight="bold"
                        verticalAlign="middle"
                        mx={1}
                        overflow="hidden"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                      >
                        {booking?.extendedProps?.pilot?.label}
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox display="flex" alignItems="center">
                      <SoftTypography variant="h6" fontWeight="bold" mx={1}>
                        {format(booking.start.toDate(), "hh:mm a")}
                      </SoftTypography>
                      <SoftTypography variant="p" fontWeight="bold" m={0}>
                        <DoubleArrowIcon
                          color="primary"
                          fontSize="small"
                          m={0}
                          lineHeight={0}
                        />
                      </SoftTypography>
                      <SoftTypography variant="h6" fontWeight="bold" mx={1}>
                        {format(booking.end.toDate(), "hh:mm a")}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </CardActionArea>
              </Card>
            </SoftBox>
          ))}
        </>
      ))}
    </SoftBox>
  );
}
