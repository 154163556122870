// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Timeline context
import { TimelineProvider } from "src/components/Timeline/context";

function TimelineList({ title, dark, children, maxHeight }) {
  return (
    <TimelineProvider value={dark}>
      <SoftBox
        bgColor={dark ? "dark" : "white"}
        variant="gradient"
        sx={{ maxHeight: maxHeight ? maxHeight : "none", overflow: "scroll" }}
      >
        {title && (
          <SoftBox
            pt={2}
            pb={0.5}
            bgColor={dark ? "dark" : "white"}
            sx={{ zIndex: 3, position: "sticky", top: 0 }}
          >
            <SoftTypography
              variant="h6"
              fontWeight="medium"
              color={dark ? "white" : "dark"}
            >
              {title}
            </SoftTypography>
          </SoftBox>
        )}
        <SoftBox pt={1} pb={2}>
          {children}
        </SoftBox>
      </SoftBox>
    </TimelineProvider>
  );
}

// Setting default values for the props of TimelineList
TimelineList.defaultProps = {
  dark: false,
};

// Typechecking props for the TimelineList
TimelineList.propTypes = {
  title: PropTypes.string,
  dark: PropTypes.bool,
  children: PropTypes.node.isRequired,
  maxHeight: PropTypes.string,
};

export default TimelineList;
