import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

const CourseCard = ({ onClick, children, courseId }) => {
  return (
    <Grid item xs={12} sm={6} lg={4} xl={3} key={courseId}>
      <Card
        onClick={onClick}
        sx={{
          border: 1,
          borderColor: "rgba(0, 0, 0, 0.025)",
          cursor: "pointer",
        }}
      >
        {children}
      </Card>
    </Grid>
  );
};

// Setting default values for the props of CourseCard
CourseCard.defaultProps = {
  onCardClick: () => {},
};

// Typechecking props for the CourseCard
CourseCard.propTypes = {
  children: PropTypes.node.isRequired,
  onCardClick: PropTypes.func,
  courseId: PropTypes.string.isRequired,
};

export default CourseCard;
