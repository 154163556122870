// @mui material components
import { styled } from "@mui/material/styles";

export default styled("div")(({ theme }) => {
  const { palette, borders, typography } = theme;

  const { borderRadius } = borders;
  const { size } = typography;
  const { text } = palette;

  return {
    height: "100%",

    ".quill": {
      height: "90%",
    },

    "& .ql-toolbar": {
      borderRadius: `${borderRadius.md} ${borderRadius.md} 0 0`,
    },

    "& .ql-container": {
      borderRadius: `0 0 ${borderRadius.md} ${borderRadius.md}`,
    },

    "& .ql-editor.ql-editor": {
      "& p": {
        fontSize: size.md,
        color: text.main,
      },

      "& ul li": {
        color: text.main,
      },

      "& a, a:link": {
        textDecoration: "underline !important",
        color: "#06c !important",
      },
    },
  };
});
