import PropTypes from "prop-types";

// Soft UI Dashboard PRO React base styles
import colors from "src/assets/theme/base/colors";
import borders from "src/assets/theme/base/borders";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import MaintenanceRemainingCell from "src/components/MaintenanceRemainingCell";

function MaintenanceReminders({ reminders }) {
  const { light } = colors;
  const { borderWidth } = borders;

  const renderReminders = () => {
    if (reminders.length === 0)
      return (
        <SoftBox component="li" pt={1} pr={2}>
          <SoftBox display="flex" justifyContent="center" alignItems="center">
            <SoftTypography variant="button" fontWeight="light">
              No Maintenance Reminders
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      );

    return reminders.map((reminder) => (
      <SoftBox
        key={reminder.id}
        component="li"
        pb={1}
        pr={2}
        sx={{ borderBottom: "1px dashed #e4e4e4" }}
      >
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <SoftBox display="flex" alignItems="center">
            <SoftBox display="flex" flexDirection="column">
              <SoftTypography variant="button" fontWeight="medium" gutterBottom>
                {reminder.name}
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox sx={{ width: "50%" }}>
            <MaintenanceRemainingCell reminder={reminder} />
          </SoftBox>
        </SoftBox>
      </SoftBox>
    ));
  };

  return (
    <SoftBox border={`${borderWidth[1]} solid ${light.main}`}>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={1}
        px={2}
        bgColor="light"
      >
        <SoftTypography
          variant="h6"
          fontWeight="medium"
          textTransform="capitalize"
        >
          Maintenance Reminders
        </SoftTypography>
      </SoftBox>
      <SoftBox p={2}>
        <SoftBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{
            listStyle: "none",
          }}
        >
          {renderReminders()}
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

// Typechecking props for the Calendar
MaintenanceReminders.propTypes = {
  booking: PropTypes.object.isRequired,
  aircraft: PropTypes.object.isRequired,
};

export default MaintenanceReminders;
