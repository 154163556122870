import { useEffect, useState } from "react";
import { useStripe, Elements } from "@stripe/react-stripe-js";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import IntroFlightsPayment from "./IntroFlightsPayment";

export default function IntroFlightsPaymentWrapper({ ...rest }) {
  const { clubId, locationId } = useParams();
  const stripe = useStripe();
  const [paymentIntent, setPaymentIntent] = useState(null);
  const [paymentIntentSecret, setPaymentIntentSecret] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const [bookingData, setBookingData] = useState();

  useEffect(() => {
    if (!location.state) {
      const url = window.location.pathname;
      navigate(url.replace("/payment", ""));
    } else {
      setBookingData(location.state);
    }
  }, [location]);

  useEffect(() => {
    console.log("bookingData", bookingData);
    if (!bookingData) return;
    const { introFlight, passengerData } = bookingData;
    if (introFlight?.totalPrice && passengerData?.email) {
      const functions = getFunctions();
      const createIntroFlightPaymentIntent = httpsCallable(
        functions,
        "createIntroFlightPaymentIntent"
      );
      createIntroFlightPaymentIntent({
        clubId,
        locationId,
        email: bookingData.passengerData.email,
        introFlightId: introFlight.id,
      }).then((result) => {
        if (result?.data) {
          setPaymentIntentSecret(result.data.secret);
          setPaymentIntent(result.data.id);
        } else {
          console.error("Unable to create Stripe payment intent", result);
        }
      });
    }
  }, [bookingData]);

  return paymentIntent && bookingData && stripe ? (
    <Elements stripe={stripe} options={{ clientSecret: paymentIntentSecret }}>
      <IntroFlightsPayment
        bookingData={bookingData}
        paymentIntentId={paymentIntent}
        {...rest}
      />
    </Elements>
  ) : null;
}
