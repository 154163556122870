import { useEffect, useState } from "react";
import { AuthLoadingPage } from "src/components/AuthLoadingPage";

import {
  Grid,
  Table,
  TableRow,
  TableBody,
  Divider,
  Card,
  AppBar,
  Tab,
  Tabs,
} from "@mui/material";
import TabPanel from "src/components/TabPanel";

import SoftButton from "src/components/SoftButton";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import borders from "src/assets/theme/base/borders";
import colors from "src/assets/theme/base/colors";

import Header from "./components/Header";
import Footer from "src/components/Footer";

import { useNavigate, useParams } from "react-router-dom";
import useRealtimeDocumentData from "src/features/firebase/firestore/useRealtimeDocumentData";
import { doc } from "firebase/firestore";
import { getPaymentRequestsCollection } from "src/features/billing/collections";
import { formatCurrency } from "src/features/utils";
import CurrencyFormat from "react-currency-format";
import { calculateFees, calculateAchFees } from "src/features/utils";

import Cube from "src/components/Icons/Cube";
import Document from "src/components/Icons/Document";
import logo from "src/assets/images/logo-inline.svg";

// Soft UI Dashboard PRO React base styles
import breakpoints from "src/assets/theme/base/breakpoints";
import { getClubsCollection } from "src/features/club/collections";

import PageLayout from "src/containers/LayoutContainers/PageLayout";
import typography from "src/assets/theme/base/typography";
import { getClubTransactionsCollection } from "src/features/transaction/collection";
import { getFunctions, httpsCallable } from "firebase/functions";

export default function PaymentRequest() {
  const { paymentRequestId, clubId } = useParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [clubPaysCCFees, setClubPaysCCFees] = useState(false);
  const [clubPaysACHFees, setClubPaysACHFees] = useState(false);
  const [fees, setFees] = useState({ stripeFees: 0, totalAmount: 0 });
  const { borderWidth } = borders;
  const { light } = colors;
  const borderBottom = `${borderWidth[1]} solid ${light.main}`;

  const { data: paymentRequest, isDataLoaded: paymentRequestLoaded } =
    useRealtimeDocumentData(
      doc(getPaymentRequestsCollection(), paymentRequestId)
    );

  const { data: selectedClub } = useRealtimeDocumentData(
    doc(getClubsCollection(), clubId || paymentRequest?.club?.id || "0")
  );

  const { data: clubTransaction, isDataLoaded: clubTransactionLoaded } =
    useRealtimeDocumentData(
      doc(
        getClubTransactionsCollection(clubId || paymentRequest?.club?.id),
        paymentRequestId
      )
    );

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (selectedClub) {
      setClubPaysCCFees(selectedClub?.preferences?.clubPaysFees);
      setClubPaysACHFees(selectedClub?.preferences?.clubPaysAchFees);
      console.log("Selected Club:", selectedClub);
    }
  }, [selectedClub]);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  useEffect(() => {
    if (paymentRequest?.amount) {
      const paymentAmount = paymentRequest?.amount;
      if (tabValue === 0) {
        setFees(
          calculateAchFees(
            paymentAmount,
            clubPaysACHFees,
            clubTransaction?.type === "booking" ? false : true
          )
        );
      } else {
        setFees(
          calculateFees(
            paymentAmount,
            undefined,
            undefined,
            clubPaysCCFees,
            clubTransaction?.type === "booking" ? false : true,
            "pm_card"
          )
        );
      }
    }
  }, [paymentRequest?.amount, tabValue]);

  useEffect(() => {
    if (clubTransaction?.totalCost) {
      const paymentAmount = clubTransaction?.totalCost;
      if (tabValue === 0) {
        setFees(calculateAchFees(paymentAmount, clubPaysACHFees));
      } else {
        setFees(
          calculateFees(
            paymentAmount,
            undefined,
            undefined,
            clubPaysCCFees,
            true,
            "pm_card"
          )
        );
      }
    }
  }, [clubTransaction?.totalCost, tabValue]);

  useEffect(() => {
    if (
      !clubTransaction ||
      clubTransaction?.type !== "booking" ||
      (clubTransaction?.type === "booking" && !!clubTransaction?.lineItems)
    )
      return;

    const lineItems = [];
    if (
      clubTransaction.booking?.extendedProps?.aircraft?.value &&
      clubTransaction.booking?.extendedProps?.aircraft?.value !== ""
    ) {
      const flightHours =
        clubTransaction?.aircraft?.billFrom === "tach"
          ? clubTransaction?.bookingData?.totalTach1Hours
          : clubTransaction?.bookingData?.totalHobbsHours;
      lineItems.push({
        discount: 0,
        item: `Aircraft Rental - ${clubTransaction.booking?.extendedProps?.aircraft?.label}`,
        price: clubTransaction.selectedRate?.hourlyRate,
        qty: flightHours,
        total:
          clubTransaction.flightCost ||
          Math.round(
            clubTransaction.selectedRate?.hourlyRate * flightHours * 100
          ) / 100,
        type: "debit",
      });
    }

    if (
      clubTransaction.booking?.extendedProps?.instructor?.value &&
      clubTransaction.booking?.extendedProps?.instructor?.value !== ""
    ) {
      const flightInstructionHours =
        clubTransaction?.bookingData?.instructionHours || 0;
      const groundInstructionHours =
        clubTransaction?.bookingData?.instructionHoursGround || 0;

      if (flightInstructionHours > 0) {
        lineItems.push({
          discount: 0,
          item: `Flight Instruction - ${clubTransaction.booking?.extendedProps?.instructor?.label}`,
          price: clubTransaction.instructor?.hourlyRate,
          qty: flightInstructionHours,
          total:
            clubTransaction.instructorCost ||
            Math.round(
              clubTransaction.instructor?.hourlyRate *
                flightInstructionHours *
                100
            ) / 100,
          type: "debit",
        });
      }

      if (groundInstructionHours > 0) {
        lineItems.push({
          discount: 0,
          item: `Ground Instruction - ${clubTransaction.booking?.extendedProps?.instructor?.label}`,
          price: clubTransaction.instructor?.hourlyRateGround,
          qty: groundInstructionHours,
          total:
            clubTransaction.instructorCostGround ||
            Math.round(
              clubTransaction.instructor?.hourlyRateGround *
                groundInstructionHours *
                100
            ) / 100,
          type: "debit",
        });
      }
    }

    clubTransaction.lineItems = lineItems;
  }, [clubTransaction]);

  const onSubmit = () => {
    setIsSubmitting(true);
    let paymentMethod;
    if (paymentRequest?.paymentMethods?.includes("ach")) {
      paymentMethod = tabValue === 0 ? "ach" : "card";
    } else if (clubTransaction?.paymentMethods?.includes("ach")) {
      paymentMethod = tabValue === 0 ? "ach" : "card";
    } else if (!clubTransaction.paymentMethods) {
      paymentMethod = tabValue === 0 ? "ach" : "card";
    } else {
      paymentMethod = "card";
    }
    console.debug("selected payment method", paymentMethod);
    console.debug("On Submit", clubTransaction, paymentRequest);

    if (!clubTransaction && paymentRequest) {
      const functions = getFunctions();
      const convertPaymentRequestToTransaction = httpsCallable(
        functions,
        "convertPaymentRequestToTransaction"
      );
      convertPaymentRequestToTransaction({
        paymentRequestId: paymentRequest.id,
        paymentMethod,
      }).then((result) => {
        navigate("payment", {
          state: {
            clubTransaction: result.data,
            fees,
            paymentMethod,
          },
        });
        setIsSubmitting(false);
      });
    } else {
      navigate("payment", {
        state: {
          clubTransaction: clubTransaction,
          fees,
          paymentMethod,
        },
      });
      setIsSubmitting(false);
    }
  };

  if (
    clubTransaction?.payments &&
    clubTransaction.payments.length > 0 &&
    clubTransaction.payments.some((payment) => payment.status === "processing")
  ) {
    const { d1, d3, d4, d5 } = typography;
    const handleGoToHome = () => {
      window.location.href = "/";
    };

    return (
      <PageLayout white>
        <SoftBox my={24} height="calc(100vh - 24rem)">
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
            }}
          >
            <Grid item xs={11} sm={9} container alignItems="center">
              <Grid item xs={12} lg={6}>
                <SoftBox
                  fontSize={{
                    xs: d5.fontSize,
                    sm: d4.fontSize,
                    md: d3.fontSize,
                    lg: d1.fontSize,
                  }}
                  lineHeight={1.2}
                >
                  <SoftTypography
                    variant="inherit"
                    color="primary"
                    fontWeight="bold"
                  >
                    Hold up...
                  </SoftTypography>
                </SoftBox>
                <SoftTypography
                  variant="h2"
                  color="dark"
                  textGradient
                  fontWeight="bold"
                >
                  Payment in progress
                </SoftTypography>
                <SoftBox mt={1} mb={2}>
                  <SoftTypography variant="body1" color="text">
                    You already have a payment in progress. Please wait for the
                    payment to complete before attempting to make another
                    payment.
                  </SoftTypography>
                </SoftBox>
                <SoftBox mt={4} mb={2}>
                  <SoftButton
                    variant="outlined"
                    color="primary"
                    onClick={handleGoToHome}
                  >
                    go to homepage
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </PageLayout>
    );
  }

  if (
    clubTransaction?.payments &&
    clubTransaction.payments.length > 0 &&
    clubTransaction.payments.reduce(
      (acc, payment) =>
        payment.status === "succeeded" ? acc + payment.amount : acc,
      0
    ) >= clubTransaction.totalCost
  ) {
    const { d1, d3, d4, d5 } = typography;
    const handleGoToHome = () => {
      window.location.href = "/";
    };

    return (
      <PageLayout white>
        <SoftBox my={24} height="calc(100vh - 24rem)">
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
            }}
          >
            <Grid item xs={11} sm={9} container alignItems="center">
              <Grid item xs={12} lg={6}>
                <SoftBox
                  fontSize={{
                    xs: d5.fontSize,
                    sm: d4.fontSize,
                    md: d3.fontSize,
                    lg: d1.fontSize,
                  }}
                  lineHeight={1.2}
                >
                  <SoftTypography
                    variant="inherit"
                    color="primary"
                    fontWeight="bold"
                  >
                    Already Paid!
                  </SoftTypography>
                </SoftBox>
                <SoftTypography
                  variant="h2"
                  color="dark"
                  textGradient
                  fontWeight="bold"
                >
                  Nothing more to see here...
                </SoftTypography>
                <SoftBox mt={1} mb={2}>
                  <SoftTypography variant="body1" color="text">
                    You have already paid this payment request. Please wait for
                    the transaction to complete and you will see this in your
                    PreFlight transactions.
                  </SoftTypography>
                </SoftBox>
                <SoftBox mt={4} mb={2}>
                  <SoftButton
                    variant="outlined"
                    color="primary"
                    onClick={handleGoToHome}
                  >
                    go to homepage
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </PageLayout>
    );
  }

  if (
    !paymentRequest &&
    paymentRequestLoaded &&
    !clubTransaction &&
    clubTransactionLoaded
  ) {
    const { d1, d3, d4, d5 } = typography;
    const handleGoToHome = () => {
      window.location.href = "/";
    };
    return (
      <>
        <PageLayout white>
          <SoftBox my={24} height="calc(100vh - 24rem)">
            <Grid
              container
              spacing={3}
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "100%",
              }}
            >
              <Grid item xs={11} sm={9} container alignItems="center">
                <Grid item xs={12} lg={6}>
                  <SoftBox
                    fontSize={{
                      xs: d5.fontSize,
                      sm: d4.fontSize,
                      md: d3.fontSize,
                      lg: d1.fontSize,
                    }}
                    lineHeight={1.2}
                  >
                    <SoftTypography
                      variant="inherit"
                      color="primary"
                      fontWeight="bold"
                    >
                      Error 404
                    </SoftTypography>
                  </SoftBox>
                  <SoftTypography
                    variant="h2"
                    color="dark"
                    textGradient
                    fontWeight="bold"
                  >
                    Payment request not found!
                  </SoftTypography>
                  <SoftBox mt={1} mb={2}>
                    <SoftTypography variant="body1" color="text">
                      It may have been deleted or cancelled by your club. Please
                      reach out to them for more information.
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox mt={4} mb={2}>
                    <SoftButton
                      variant="outlined"
                      color="primary"
                      onClick={handleGoToHome}
                    >
                      go to homepage
                    </SoftButton>
                  </SoftBox>
                </Grid>
              </Grid>
            </Grid>
          </SoftBox>
          <Footer />
        </PageLayout>
      </>
    );
  }

  if (
    (!paymentRequest && !paymentRequestLoaded) ||
    (!clubTransaction && !clubTransactionLoaded)
  ) {
    return AuthLoadingPage;
  }

  return (
    <>
      <SoftBox my={7}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={9} mb={1}>
            <SoftBox display="flex" flexDirection="column" alignItems="center">
              <SoftBox component="img" src={logo} width={250} />
            </SoftBox>
          </Grid>
          <Grid item xs={12} lg={9}>
            <Card>
              <SoftBox pt={2} px={2}>
                <Header paymentRequest={paymentRequest} />
              </SoftBox>
              <Divider />
              <SoftBox pt={1} pb={3} px={2}>
                <SoftBox mb={3}>
                  <SoftBox p={3}>
                    <SoftBox width="100%" overflow="auto">
                      <Table
                        sx={{
                          minWidth: "32rem",
                        }}
                      >
                        <SoftBox component="thead">
                          <TableRow>
                            <SoftBox
                              component="th"
                              width={{
                                xs: "45%",
                                md: "50%",
                              }}
                              py={1.5}
                              px={1}
                              textAlign="left"
                              borderBottom={borderBottom}
                            >
                              <SoftTypography
                                variant="h6"
                                color="text"
                                fontWeight="medium"
                              >
                                Item
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="th"
                              py={1.5}
                              pl={3}
                              pr={1}
                              textAlign="left"
                              borderBottom={borderBottom}
                            >
                              <SoftTypography
                                variant="h6"
                                color="text"
                                fontWeight="medium"
                              >
                                Qty
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="th"
                              py={1.5}
                              pl={3}
                              pr={1}
                              textAlign="left"
                              borderBottom={borderBottom}
                            >
                              <SoftTypography
                                variant="h6"
                                color="text"
                                fontWeight="medium"
                              >
                                Rate
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="th"
                              py={1.5}
                              pl={3}
                              pr={1}
                              textAlign="left"
                              borderBottom={borderBottom}
                            >
                              <SoftTypography
                                variant="h6"
                                color="text"
                                fontWeight="medium"
                              >
                                Discount
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="th"
                              py={1.5}
                              pl={3}
                              pr={1}
                              textAlign="left"
                              borderBottom={borderBottom}
                            >
                              <SoftTypography
                                variant="h6"
                                color="text"
                                fontWeight="medium"
                              >
                                Amount
                              </SoftTypography>
                            </SoftBox>
                          </TableRow>
                        </SoftBox>
                        <TableBody>
                          {paymentRequest?.lineItems?.map((item) => (
                            <TableRow key={item.id}>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                p={1}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text">
                                  {item.item}
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                py={1}
                                pr={1}
                                pl={3}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text">
                                  {item.qty}
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                py={1}
                                pr={1}
                                pl={3}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text">
                                  {formatCurrency(
                                    item.type === "credit" ||
                                      item.type === "accountBalance"
                                      ? item.price * -1
                                      : item.price
                                  )}
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                py={1}
                                pr={1}
                                pl={3}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text">
                                  {item.discount > 0 &&
                                    formatCurrency(item.discount)}
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                py={1}
                                pr={1}
                                pl={3}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text">
                                  {formatCurrency(
                                    item.type === "credit" ||
                                      item.type === "accountBalance"
                                      ? item.total * -1
                                      : item.total
                                  )}
                                </SoftTypography>
                              </SoftBox>
                            </TableRow>
                          ))}
                          {clubTransaction?.lineItems?.map((item) => (
                            <TableRow key={item.id}>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                p={1}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text">
                                  {item.item}
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                py={1}
                                pr={1}
                                pl={3}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text">
                                  {item.qty}
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                py={1}
                                pr={1}
                                pl={3}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text">
                                  {formatCurrency(
                                    item.type === "credit" ||
                                      item.type === "accountBalance"
                                      ? item.price * -1
                                      : item.price
                                  )}
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                py={1}
                                pr={1}
                                pl={3}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text">
                                  {item.discount > 0 &&
                                    formatCurrency(item.discount)}
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                py={1}
                                pr={1}
                                pl={3}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text">
                                  {formatCurrency(
                                    item.type === "credit" ||
                                      item.type === "accountBalance"
                                      ? item.total * -1
                                      : item.total
                                  )}
                                </SoftTypography>
                              </SoftBox>
                            </TableRow>
                          ))}
                          <TableRow key={"Total"}>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              p={1}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text" />
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text" />
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text" />
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text">
                                <b>Sub Total:</b>
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text">
                                {formatCurrency(
                                  clubTransaction
                                    ? clubTransaction?.lineItems?.reduce(
                                        (acc, item) =>
                                          item.type === "credit" ||
                                          item.type === "accountBalance"
                                            ? acc - item.total
                                            : acc + item.total,
                                        0
                                      )
                                    : paymentRequest?.lineItems?.reduce(
                                        (acc, item) =>
                                          item.type === "credit" ||
                                          item.type === "accountBalance"
                                            ? acc - item.total
                                            : acc + item.total,
                                        0
                                      )
                                )}
                              </SoftTypography>
                            </SoftBox>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
                <Divider />
                <SoftBox mt={3}>
                  <Grid container spacing={3}>
                    <Grid
                      item
                      xs={12}
                      lg={6}
                      sx={{
                        ml: "auto",
                      }}
                    >
                      {/* <TransactionSummary transaction={transactionData} /> */}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      {paymentRequest && (
                        <>
                          <SoftBox mt={2} display="flex">
                            <AppBar position="static">
                              <Tabs
                                orientation={tabsOrientation}
                                value={tabValue}
                                onChange={handleSetTabValue}
                                sx={{
                                  backgroundColor: "transparent",
                                }}
                              >
                                {(paymentRequest.paymentMethods?.includes(
                                  "ach"
                                ) ||
                                  !paymentRequest.paymentMethods) && (
                                  <Tab label="ACH" icon={<Cube />} />
                                )}
                                {(paymentRequest.paymentMethods?.includes(
                                  "cc"
                                ) ||
                                  !paymentRequest.paymentMethods) && (
                                  <Tab
                                    label="Credit Card"
                                    icon={<Document />}
                                  />
                                )}
                              </Tabs>
                            </AppBar>
                          </SoftBox>
                          <SoftBox mt={2}>
                            {(paymentRequest?.paymentMethods?.includes("ach") ||
                              !paymentRequest.paymentMethods) && (
                              <TabPanel value={tabValue} index={0}>
                                {!clubPaysACHFees && (
                                  <SoftBox
                                    display="flex"
                                    justifyContent="space-between"
                                    mb={0.5}
                                  >
                                    <SoftBox>
                                      <SoftTypography
                                        variant="button"
                                        fontWeight="regular"
                                        color="text"
                                      >
                                        Payment Fees:
                                      </SoftTypography>
                                    </SoftBox>
                                    <SoftBox ml={1}>
                                      <SoftTypography
                                        variant="body2"
                                        fontWeight="medium"
                                      >
                                        <CurrencyFormat
                                          value={
                                            (fees?.costToPilot ?? 0) -
                                            (fees?.totalCharge ?? 0)
                                          }
                                          displayType="text"
                                          decimalScale={2}
                                          fixedDecimalScale
                                          thousandSeparator
                                          prefix="$"
                                        />
                                      </SoftTypography>
                                    </SoftBox>
                                  </SoftBox>
                                )}
                                <SoftBox
                                  display="flex"
                                  justifyContent="space-between"
                                  mt={3}
                                >
                                  <SoftBox>
                                    <SoftTypography
                                      variant="body1"
                                      fontWeight="light"
                                      color="text"
                                    >
                                      Total:
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox ml={1}>
                                    <SoftTypography
                                      variant="body1"
                                      fontWeight="medium"
                                    >
                                      <CurrencyFormat
                                        value={fees?.costToPilot ?? 0}
                                        displayType="text"
                                        decimalScale={2}
                                        fixedDecimalScale
                                        thousandSeparator
                                        prefix="$"
                                      />
                                    </SoftTypography>
                                  </SoftBox>
                                </SoftBox>
                                <SoftBox
                                  display="flex"
                                  justifyContent="flex-end"
                                  mt={3}
                                >
                                  <SoftTypography variant="caption">
                                    <i>
                                      <b>Note:</b> ACH payments may take up to 4
                                      working days.
                                    </i>
                                  </SoftTypography>
                                </SoftBox>
                              </TabPanel>
                            )}
                            {(paymentRequest?.paymentMethods?.includes("cc") ||
                              !paymentRequest.paymentMethods) && (
                              <TabPanel
                                value={tabValue}
                                index={
                                  paymentRequest?.paymentMethods?.includes(
                                    "ach"
                                  ) || !paymentRequest.paymentMethods
                                    ? 1
                                    : 0
                                }
                              >
                                {!clubPaysCCFees && (
                                  <SoftBox
                                    display="flex"
                                    justifyContent="space-between"
                                    mb={0.5}
                                  >
                                    <SoftBox>
                                      <SoftTypography
                                        variant="button"
                                        fontWeight="regular"
                                        color="text"
                                      >
                                        Payment Fees:
                                      </SoftTypography>
                                    </SoftBox>
                                    <SoftBox ml={1}>
                                      <SoftTypography
                                        variant="body2"
                                        fontWeight="medium"
                                      >
                                        <CurrencyFormat
                                          value={fees?.totalStripeFees || 0}
                                          displayType="text"
                                          decimalScale={2}
                                          fixedDecimalScale
                                          thousandSeparator
                                          prefix="$"
                                        />
                                      </SoftTypography>
                                    </SoftBox>
                                  </SoftBox>
                                )}
                                <SoftBox
                                  display="flex"
                                  justifyContent="space-between"
                                  mt={3}
                                >
                                  <SoftBox>
                                    <SoftTypography
                                      variant="body1"
                                      fontWeight="light"
                                      color="text"
                                    >
                                      Total:
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox ml={1}>
                                    <SoftTypography
                                      variant="body1"
                                      fontWeight="medium"
                                    >
                                      <CurrencyFormat
                                        value={fees?.chargeToPilot || 0}
                                        displayType="text"
                                        decimalScale={2}
                                        fixedDecimalScale
                                        thousandSeparator
                                        prefix="$"
                                      />
                                    </SoftTypography>
                                  </SoftBox>
                                </SoftBox>
                              </TabPanel>
                            )}
                          </SoftBox>
                          <SoftBox
                            mt={3}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            <SoftBox />
                            <SoftButton
                              disabled={isSubmitting}
                              type="submit"
                              color="primary"
                              onClick={onSubmit}
                            >
                              Next
                            </SoftButton>
                          </SoftBox>
                        </>
                      )}
                      {clubTransaction && (
                        <>
                          <SoftBox mt={2} display="flex">
                            <AppBar position="static">
                              <Tabs
                                orientation={tabsOrientation}
                                value={tabValue}
                                onChange={handleSetTabValue}
                                sx={{
                                  backgroundColor: "transparent",
                                }}
                              >
                                {(clubTransaction.paymentMethods?.includes(
                                  "ach"
                                ) ||
                                  !clubTransaction.paymentMethods) && (
                                  <Tab label="ACH" icon={<Cube />} />
                                )}
                                {(clubTransaction.paymentMethods?.includes(
                                  "cc"
                                ) ||
                                  !clubTransaction.paymentMethods) && (
                                  <Tab
                                    label="Credit Card"
                                    icon={<Document />}
                                  />
                                )}
                              </Tabs>
                            </AppBar>
                          </SoftBox>
                          <SoftBox mt={2}>
                            {(clubTransaction?.paymentMethods?.includes(
                              "ach"
                            ) ||
                              !clubTransaction.paymentMethods) && (
                              <TabPanel value={tabValue} index={0}>
                                {!clubPaysACHFees && (
                                  <SoftBox
                                    display="flex"
                                    justifyContent="space-between"
                                    mb={0.5}
                                  >
                                    <SoftBox>
                                      <SoftTypography
                                        variant="button"
                                        fontWeight="regular"
                                        color="text"
                                      >
                                        Payment Fees:
                                      </SoftTypography>
                                    </SoftBox>
                                    <SoftBox ml={1}>
                                      <SoftTypography
                                        variant="body2"
                                        fontWeight="medium"
                                      >
                                        <CurrencyFormat
                                          value={
                                            (fees?.costToPilot ?? 0) -
                                            (fees?.totalCharge ?? 0)
                                          }
                                          displayType="text"
                                          decimalScale={2}
                                          fixedDecimalScale
                                          thousandSeparator
                                          prefix="$"
                                        />
                                      </SoftTypography>
                                    </SoftBox>
                                  </SoftBox>
                                )}
                                <SoftBox
                                  display="flex"
                                  justifyContent="space-between"
                                  mt={3}
                                >
                                  <SoftBox>
                                    <SoftTypography
                                      variant="body1"
                                      fontWeight="light"
                                      color="text"
                                    >
                                      Total:
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox ml={1}>
                                    <SoftTypography
                                      variant="body1"
                                      fontWeight="medium"
                                    >
                                      <CurrencyFormat
                                        value={fees?.costToPilot ?? 0}
                                        displayType="text"
                                        decimalScale={2}
                                        fixedDecimalScale
                                        thousandSeparator
                                        prefix="$"
                                      />
                                    </SoftTypography>
                                  </SoftBox>
                                </SoftBox>
                                <SoftBox
                                  display="flex"
                                  justifyContent="flex-end"
                                  mt={3}
                                >
                                  <SoftTypography variant="caption">
                                    <i>
                                      <b>Note:</b> ACH payments may take up to 4
                                      working days.
                                    </i>
                                  </SoftTypography>
                                </SoftBox>
                              </TabPanel>
                            )}
                            {(clubTransaction?.paymentMethods?.includes("cc") ||
                              !clubTransaction.paymentMethods) && (
                              <TabPanel
                                value={tabValue}
                                index={
                                  clubTransaction?.paymentMethods?.includes(
                                    "ach"
                                  ) || !clubTransaction.paymentMethods
                                    ? 1
                                    : 0
                                }
                              >
                                {!clubPaysCCFees && (
                                  <SoftBox
                                    display="flex"
                                    justifyContent="space-between"
                                    mb={0.5}
                                  >
                                    <SoftBox>
                                      <SoftTypography
                                        variant="button"
                                        fontWeight="regular"
                                        color="text"
                                      >
                                        Payment Fees:
                                      </SoftTypography>
                                    </SoftBox>
                                    <SoftBox ml={1}>
                                      <SoftTypography
                                        variant="body2"
                                        fontWeight="medium"
                                      >
                                        <CurrencyFormat
                                          value={fees?.totalStripeFees || 0}
                                          displayType="text"
                                          decimalScale={2}
                                          fixedDecimalScale
                                          thousandSeparator
                                          prefix="$"
                                        />
                                      </SoftTypography>
                                    </SoftBox>
                                  </SoftBox>
                                )}
                                <SoftBox
                                  display="flex"
                                  justifyContent="space-between"
                                  mt={3}
                                >
                                  <SoftBox>
                                    <SoftTypography
                                      variant="body1"
                                      fontWeight="light"
                                      color="text"
                                    >
                                      Total:
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox ml={1}>
                                    <SoftTypography
                                      variant="body1"
                                      fontWeight="medium"
                                    >
                                      <CurrencyFormat
                                        value={fees?.chargeToPilot || 0}
                                        displayType="text"
                                        decimalScale={2}
                                        fixedDecimalScale
                                        thousandSeparator
                                        prefix="$"
                                      />
                                    </SoftTypography>
                                  </SoftBox>
                                </SoftBox>
                              </TabPanel>
                            )}
                          </SoftBox>
                          <SoftBox
                            mt={3}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            <SoftBox />
                            <SoftButton
                              disabled={isSubmitting}
                              type="submit"
                              color="primary"
                              onClick={onSubmit}
                            >
                              Next
                            </SoftButton>
                          </SoftBox>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </SoftBox>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </>
  );
}
