import * as toastr from "toastr";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftPhoneNumberInput from "src/components/SoftPhoneNumberInput";
import SoftSelect from "src/components/SoftSelect";

// Settings page components
import FormField from "src/pages/user/account/components/FormField";

// Data
import selectData from "src/pages/user/account/settings/components/BasicInfo/data/selectData";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import SoftButton from "src/components/SoftButton";
import { doc, setDoc } from "firebase/firestore";
import { useUser } from "src/features/user/UserProvider";
import { getUsersCollection } from "src/features/user/collections";
import { getYearsSelectData } from "src/features/utils";
import { getFunctions, httpsCallable } from "firebase/functions";

const schema = yup
  .object({
    firstName: yup.string().required("A first name is required."),
    lastName: yup.string().required("A last name is required."),
    gender: yup.object().required("Gender is required."),
    birthMonth: yup.object().required("Birth date is required."),
    birthDate: yup.object().required("Birth date is required."),
    birthYear: yup.object().required("Birth date is required."),
    location: yup.string(),
    phoneNumber: yup.string(),
    languages: yup.string(),
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Email is required."),
    addressLine1: yup.string(),
    addressLine2: yup.string(),
    city: yup.string(),
    state: yup.string(),
    zip: yup.string(),
  })
  .required();

function BasicInfo() {
  const { user, userId } = useUser();

  const years = getYearsSelectData();

  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      gender: selectData.gender.find((c) => c.value === user.gender) ?? "",
      birthMonth:
        selectData.birthDate.find((c) => c.value === user.birthMonth) ||
        selectData.birthDate[0],
      birthDate:
        selectData.days.find((c) => c.value === user.birthDate) ||
        selectData.days[0],
      birthYear: years.find((c) => c.value === user.birthYear) || years[0],
      location: user.location,
      phoneNumber: user.phoneNumber,
      languages: user.languages,
      addressLine1: user.address?.addressLine1,
      addressLine2: user.address?.addressLine2,
      city: user.address?.city,
      state: user.address?.state,
      zip: user.address?.zip,
    },
  });

  const onSubmit = async (data) => {
    try {
      await setDoc(
        doc(getUsersCollection(), userId),
        {
          displayName: `${data.firstName} ${data.lastName}`,
          firstName: data.firstName,
          lastName: data.lastName,
          gender: data.gender.value,
          birthMonth: data.birthMonth.value,
          birthDate: data.birthDate.value,
          birthYear: data.birthYear.value,
          location: data.location || "",
          phoneNumber: data.phoneNumber || "",
          languages: data.languages || "",
          address: {
            addressLine1: data.addressLine1 || "",
            addressLine2: data.addressLine2 || "",
            city: data.city || "",
            state: data.state || "",
            zip: data.zip || "",
          },
        },
        { merge: true }
      );
      if (user.email !== data.email) {
        console.log("Email changed");
        const functions = getFunctions();
        const updateUsersEmail = httpsCallable(functions, "updateUsersEmail");
        updateUsersEmail({
          userId,
          newEmail: data.email,
        })
          .then((response) => {
            console.log("Response", response);
            if (response.data?.errorInfo?.message) {
              // toastr.error(response.data.errorInfo.message, "Error");
              throw new Error(response.data.errorInfo.message);
            }
            toastr.success("Your profile has been updated.", "Success");
          })
          .catch((e) => {
            console.error("Error updating email", e);
            toastr.error(e, "Error");
          });
      } else {
        toastr.success("Your profile has been updated.", "Success");
      }
    } catch (e) {
      toastr.error("There was an error updating your profile.", "Error");
    }
  };

  const currentYear = new Date().getFullYear();

  return (
    <Card
      id="basic-info"
      sx={{
        overflow: "visible",
      }}
    >
      <SoftBox p={3}>
        <SoftTypography variant="h5">Basic Information</SoftTypography>
      </SoftBox>
      <SoftBox component="form" pb={3} px={3} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="firstName"
              render={({ field: { onChange, value, ref, ...field } }) => (
                <FormField
                  {...field}
                  label="first name"
                  placeholder="John"
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={!!errors.firstName}
                />
              )}
            />
            {errors?.firstName?.message && (
              <SoftTypography marginTop={1} fontSize={12} color="error">
                {errors.firstName.message}
              </SoftTypography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="lastName"
              render={({ field: { onChange, value, ref } }) => (
                <FormField
                  label="last name"
                  placeholder="Doe"
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                  error={!!errors.lastName}
                />
              )}
            />
            {errors?.lastName?.message && (
              <SoftTypography marginTop={1} fontSize={12} color="error">
                {errors.lastName.message}
              </SoftTypography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-end"
                  height="100%"
                >
                  <SoftBox
                    mb={1}
                    ml={0.5}
                    lineHeight={0}
                    display="inline-block"
                  >
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      I&apos;m
                    </SoftTypography>
                  </SoftBox>
                  <Controller
                    control={control}
                    name="gender"
                    render={({ field: { onChange, value, ref } }) => (
                      <SoftSelect
                        placeholder=""
                        options={selectData.gender}
                        onChange={onChange}
                        inputRef={ref}
                        value={value}
                        error={!!errors.gender}
                      />
                    )}
                  />
                  {errors?.gender?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.gender.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={5}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <SoftBox
                        mb={1}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          birth date
                        </SoftTypography>
                      </SoftBox>
                      <Controller
                        control={control}
                        name="birthMonth"
                        render={({ field: { onChange, value, ref } }) => (
                          <SoftSelect
                            placeholder="February"
                            options={selectData.birthDate}
                            onChange={onChange}
                            inputRef={ref}
                            value={value}
                            error={!!errors.birthMonth}
                          />
                        )}
                      />
                      {errors?.birthMonth?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.birthMonth.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <Controller
                        control={control}
                        name="birthDate"
                        render={({ field: { onChange, value, ref } }) => (
                          <SoftSelect
                            placeholder={1}
                            options={selectData.days}
                            onChange={onChange}
                            inputRef={ref}
                            value={value}
                            error={!!errors.birthDate}
                          />
                        )}
                      />
                      {errors?.birthDate?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.birthDate.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <SoftBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="100%"
                    >
                      <Controller
                        control={control}
                        name="birthYear"
                        render={({ field: { onChange, value, ref } }) => (
                          <SoftSelect
                            placeholder={currentYear}
                            options={years}
                            onChange={onChange}
                            inputRef={ref}
                            value={value}
                            error={!!errors.birthYear}
                          />
                        )}
                      />
                      {errors?.birthYear?.message && (
                        <SoftTypography
                          marginTop={1}
                          fontSize={12}
                          color="error"
                        >
                          {errors.birthYear.message}
                        </SoftTypography>
                      )}
                    </SoftBox>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="email"
              render={({ field: { onChange, value, ref } }) => (
                <FormField
                  label="email"
                  onChange={onChange}
                  inputRef={ref}
                  placeholder="example@email.com"
                  inputProps={{
                    type: "email",
                  }}
                  value={value}
                />
              )}
            />
            {errors?.email?.message && (
              <SoftTypography marginTop={1} fontSize={12} color="error">
                {errors.email.message}
              </SoftTypography>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name="location"
              render={({ field: { onChange, value, ref } }) => (
                <FormField
                  label="location"
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                />
              )}
            />
            {errors?.location?.message && (
              <SoftTypography marginTop={1} fontSize={12} color="error">
                {errors.location.message}
              </SoftTypography>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <SoftBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
            >
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                >
                  Phone Number
                </SoftTypography>
              </SoftBox>
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field: { onChange, value, ref: inputRef } }) => (
                  <SoftPhoneNumberInput
                    value={value}
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry="US"
                    onChange={onChange}
                    inputRef={inputRef}
                  />
                )}
              />
              {errors?.phoneNumber?.message && (
                <SoftTypography marginTop={1} fontSize={12} color="error">
                  {errors.phoneNumber.message}
                </SoftTypography>
              )}
            </SoftBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              name="languages"
              render={({ field: { onChange, value, ref } }) => (
                <FormField
                  label="language"
                  placeholder="English, Spanish"
                  onChange={onChange}
                  inputRef={ref}
                  value={value}
                />
              )}
            />
            {errors?.languages?.message && (
              <SoftTypography marginTop={1} fontSize={12} color="error">
                {errors.languages.message}
              </SoftTypography>
            )}
          </Grid>
          <Grid item xs={12} mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="addressLine1"
                  render={({ field: { onChange, value, ref } }) => (
                    <FormField
                      label="address line 1"
                      onChange={onChange}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
                {errors?.addressLine1?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.addressLine1.message}
                  </SoftTypography>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="addressLine2"
                  render={({ field: { onChange, value, ref } }) => (
                    <FormField
                      label="address line 2"
                      onChange={onChange}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
                {errors?.addressLine2?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.addressLine2.message}
                  </SoftTypography>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  control={control}
                  name="city"
                  render={({ field: { onChange, value, ref } }) => (
                    <FormField
                      label="city"
                      onChange={onChange}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
                {errors?.city?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.city.message}
                  </SoftTypography>
                )}
              </Grid>

              <Grid item xs={12} sm={2}>
                <Controller
                  control={control}
                  name="state"
                  render={({ field: { onChange, value, ref } }) => (
                    <FormField
                      label="state"
                      onChange={onChange}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
                {errors?.state?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.state.message}
                  </SoftTypography>
                )}
              </Grid>

              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name="zip"
                  render={({ field: { onChange, value, ref } }) => (
                    <FormField
                      label="zip"
                      onChange={onChange}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
                {errors?.zip?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.zip.message}
                  </SoftTypography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} justifyContent="flex-end" display="flex">
            <SoftButton
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              disabled={!isDirty}
            >
              Save
            </SoftButton>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default BasicInfo;
